 
import SmartyStreetsSDK from 'smartystreets-javascript-sdk'

const SmartyStreetsCore = SmartyStreetsSDK.core
const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup

const credentials = new SmartyStreetsCore.SharedCredentials(process.env.REACT_APP_SMARTY_AUTH_ID)
const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud'])
const client = clientBuilder.buildUsAutocompleteProClient()

export const smartyStreetApi = async (value) => {
  const lookup = new Lookup(value)
  lookup.maxResults = 7

  return await handleRequest(lookup)

  async function handleRequest (lookup) {
    try {
      const response = await client.send(lookup)
      return response.result
    } catch (err) {
      console.log(err)
      throw { message: err }
    }
  }
}
