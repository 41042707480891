import React, { useEffect, useRef, useState, useCallback, ChangeEventHandler, ChangeEvent, useMemo } from 'react'
import {
  Autocomplete,
  Button,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography,
  Box,
  IconButton,
  drawerClasses,
  ListItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Alert,
  CircularProgress,
  useMediaQuery
} from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled, useTheme } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import { useNavigate, Link } from 'react-router-dom'
import { RegisterKitDataI, RegisterKitNavigation, sitePaymentMethod, useRegisterKit } from '.'
import { useDispatch, useSelector } from 'react-redux'
import { api } from 'Core'
import * as yup from 'yup'
import CloseIcon from '@mui/icons-material/Close'
import RegisterPatient from '../RegisterPatient'
import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import { Prompt } from 'components/Prompt'
import useStateCallback from 'hooks/useStateCallback'
import InfoIcon from '@mui/icons-material/Info'
import useFormik from 'hooks/useFormik'
import { cloneDeep, debounce } from 'lodash'
import {
  checkPatientExistsInSite,
  getPatientIdFromMapper,
  getSiteIds,
  isShkDomain
} from 'utils/utilFuntions'
import { analyticEventTracker } from 'utils/analytics'
import SignatureBox from 'components/SignatureBox'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ReactInputMask from 'react-input-mask'
import {
  checkScreeningQuestions,
  requireSupervision,
  validateKitAgainstPatientAge
} from 'utils/kit'
// import RichTextEditor, { createEditorStateFromRaw } from 'components/RichTextEditor'
import DOMPurify from 'dompurify'
import useSite from 'hooks/useSite'
import Loader from 'components/Loader'
import AddIcon from '@mui/icons-material/Add'
import moment from 'moment'
import PatientInfoFormProgram from './PatientInfoFormProgram'
import { useTranslation } from 'react-i18next'
import { useWhiteLabelConfig } from '../../utils/white-label/WhiteLabelConfig'
import { ReduxState } from '../../types/ReduxState'
import { PatientI } from '../../types/db/PatientI'
import { SiteI } from '../../types/db/SiteI'
import { TFunction } from 'i18next'
import { Kit } from '../../types/db/Kit'
// import SelectedPatientSummary from './SelectedPatientSummary'
import EditPatient from '../Profile/EditPatient'
import { useMutation } from '@tanstack/react-query'
import PatientCardList from './PatientCardList'
import AddPatientButton from './AddPatientButton'
import { getMinAge } from '../RegisterPatient/utils'
import NotAllowedDueToSexAssignedAtBirthAlert, { checkIfAllowedDueToSexAssignedAtBirth } from '../../components/NotAllowedDueToSexAssignedAtBirthAlert'
import { parseISO } from 'date-fns'
import SameDayRegistrationAlert from 'components/SameDayRegistrationAlert'

function PatientInfoForm () {
  const whiteLabelConfig = useWhiteLabelConfig()
  const isSHK = isShkDomain()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const patientFormStep = useSelector((state: ReduxState) => state.ui['newPatientForm/step'] || null)

  const { data, updateData, saveKitAction } = useRegisterKit()
  const [showPromptOnExit, setShowPromptOnExit] = useStateCallback(true)

  const [showNewPatientForm, setShowNewPatientForm] = useState(false)
  const [showEditPatientForm, setShowEditPatientForm] = useState(false)
  const [isProgramTestLoading, setProgramTestLoading] = useState(false)

  const [currentPatient, setCurrentPatient] = useState(
    data?.patientInfoForm?.patient
  )
  const [currentEditPatient, setCurrentEditPatient] = useState<PatientI | null>(null)
  const [selectedOption, setSelectedOption] = useState('')
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showPromptOnExit)
  const [showSiteOptions, setShowSiteOptions] = useState(true)

  const toggleShowSiteOptions = () => {
    formik.setFieldValue('sitecode', '')
    setShowSiteOptions(current => !current)
  }

  const patients = useSelector((state: ReduxState) => state.patients)
  const siteIds = getSiteIds(patients)
  const { sitesData } = useSite({ siteIds })

  const { t } = useTranslation()

  const sitePatients: SitePatientsI = cloneDeep(patients)

  const drawerRef = useRef<HTMLDivElement>(null)

  const [isPatientInSite, setIsPatientInSite] = useState(true)
  const [searchingForSite, setSearchingForSite] = useState(false)

  const isRetailTest =
    sitePaymentMethod(data.site || undefined, data?.kit?.kitTypeConfig) === 'prepaid'

  const formik = useFormik({
    initialValues: {
      ...data.patientInfoForm,
      shouldDuplicatePatient: 'no',
      fullName: '',
      supervision: {
        fullName: '',
        dob: '',
        phone: '',
        email: '',
        signBase64: null,
        fullNameSign: '',
        ...(data.patientInfoForm.supervision || {})
      }
    },
    validationSchema: getValidationSchema(
      isRetailTest,
      isPatientInSite,
      currentPatient || undefined,
      data.site || undefined,
      data?.kit || undefined,
      t
    ),
    onSubmit: async formData => {
      setShowPromptOnExit(false)
      try {
        let isPatientPresentInSite = isPatientInSite
        isPatientPresentInSite = checkPatientExistsInSite(
          formData.patient,
          data.site || undefined
        )

        const patientId = !isPatientPresentInSite
          ? formData.patient.id
          : getPatientIdFromMapper(formData.patient.idMapper, data.site || undefined)
        if (!patientId) throw Error('Something is wrong')
        let newPatientId: any = null
        if (!isPatientPresentInSite) {
          const response = await api.patient.registerPatientToNewSite({
            patient_id: patientId,
            newSite: data?.site?.id
          })
          newPatientId = response.patientId
        }

        let updatedPatientWithMapper: PatientI | undefined
        if (newPatientId) {
          const patients = await api.patient.getAll()
          updatedPatientWithMapper = patients.find(
            p =>
              p.first_name === formData.patient.first_name &&
              p.last_name === formData.patient.last_name &&
              p.dob === formData.patient.dob
          )
        }

        await api.kit.register(data?.kit?.code as string, {
          siteName: data?.site?.name,
          requestType: 'register'
          // pageInfo: 'PatientSelection'
        })

        await api.kit.update(data?.kit?.id as number, {
          patient_id: newPatientId || patientId,
          supervision: formData.supervision,
          pageInfo: 'PatientSelection'
        })

        const payment = sitePaymentMethod(data.site || undefined, data?.kit?.kitTypeConfig)
        updateData(state => ({
          ...state,
          patientInfoForm: newPatientId
            ? { ...formData, patient: { ...updatedPatientWithMapper } }
            : formData,
          site: {
            ...state.site,
            retail:
              sitePaymentMethod(state?.site || undefined, state?.kit?.kitTypeConfig) ===
              'prepaid'
          },
          payment
        }) as RegisterKitDataI)
        saveKitAction('PatientSelection')
        analyticEventTracker('(E) Next Button Clicked on Patient Info Form', {
          category: 'Register kit'
        })
        const hasScreeningQuestions = checkScreeningQuestions(
          data?.kit?.kitTypeConfig,
          data.site
        )
        if (hasScreeningQuestions) {
          navigate('../screening')
          return
        }
        if (payment !== 'prepaid') {
          navigate('../payment-method')
          return
        }
        navigate('../sample-collection')
      } catch (e) {
        console.error(e)
      }
    }
  })

  const handleClose = () => {
    // Sent the step in which the user quit the patient registration form to Analytics
    analyticEventTracker(`(F) Quit patient - ${patientFormStep}`)
    dispatch({ type: 'ui/newPatientForm/step', payload: null })
    // Close drawer
    setShowNewPatientForm(false)
    setShowEditPatientForm(false)
  }
  const handleEditPatient = useMutation({
    mutationFn: api.patient.getAll,
    onSuccess: (data) => {
      const patient = data.find(p => p.id === formik.values.patient.id)
      if (!patient) {
        setShowEditPatientForm(false)
        return
      }
      setCurrentPatient(patient)
      setSelectedOption(JSON.stringify(patient))
      formik.setValues({
        ...formik.values,
        patient,
        shouldDuplicatePatient: 'no'
      })
      setIsPatientInSite(checkPatientExistsInSite(patient, formik.values.sitecode))
      setShowEditPatientForm(false)
      setShowNewPatientForm(false)
    }
  })
  // This is just looking at age less than minAgeLimit
  const allowedDueToTheAge = formik.values.patient && validateKitAgainstPatientAge(data?.kit || undefined, formik.values.patient, data.site || undefined)

  const showSupervisionForm = requireSupervision(formik.values.patient, data.site || undefined, data.kit || undefined)

  useEffect(() => {
    const updateSite = async () => {
      if (!isSHK) {
        formik.setFieldValue('sitecode', {
          code: whiteLabelConfig?.siteCode,
          label: whiteLabelConfig?.siteName,
          id: whiteLabelConfig?.siteId
        })
        setSearchingForSite(true)
        try {
          const response = await api.site.getSiteFromCode(
            whiteLabelConfig?.siteCode as string
          )
          updateData(data => ({
            ...data,
            site: response.data
          }))
        } catch (error) {
          console.error('Error fetching site:', error)
        }
        setSearchingForSite(false)
      }
    }
    updateSite()
  }, [])

  useEffect(() => {
    if (!formik.values.sitecode && data?.site) {
      formik.setFieldValue('sitecode', {
        code: data?.site.code?.toUpperCase(),
        label: data?.site?.name?.toUpperCase(),
        id: data?.site?.id
      })
    }
  }, [data.site])

  useEffect(() => {
    if (!allowedDueToTheAge) {
      setShowPromptOnExit(false)
    }
  }, [allowedDueToTheAge])

  let patient = formik.values?.patient
  if (data?.kit?.isProgramKit) {
    patient = data.patientInfoForm.patient
  }

  const showInvalidState = useMemo(() => {
    let showInvalidState = false;
    if (data.kit) {
      showInvalidState = data.kit.kitTypeConfig.notAllowedStates.includes(
        patient?.address?.state_abbreviation
      )
      if(patient?.address?.state_abbreviation === 'NY' && data.site?.ny_test_config?.isActive){
        const nyBlockedKitConfig = data.site?.ny_test_config?.kitTypeConfigIds || [];
        showInvalidState = showInvalidState || nyBlockedKitConfig.includes(data.kit.kitTypeConfig.id);
      }
    }
    return showInvalidState;
   }, [data.kit?.id, patient?.id, patient?.address?.state_abbreviation]);
 

  const filteredSites = (sitesData.data || []).filter(site => !site.is_hidden)

  const siteOptions: {
    id: number | string
    code?: string
    label?: string
    value?: JSX.Element
  }[] = filteredSites.map(site => ({
    id: site.id,
    code: site.code.toUpperCase(),
    label: site.name.toUpperCase()
  }))

  siteOptions.push({
    id: 'addNewSite',
    value: (
      <Stack flexDirection='row' gap={ 4 }>
        <AddIcon
          sx={ theme => ({
            color: theme.palette.primary.main,
            opacity: 0.54
          }) }
        />
        <Typography>
          { t('registerKit.patientDetails.patientForm.patient.addNewSiteCode') }
        </Typography>
      </Stack>
    )
  })

  useEffect(() => {
    const sitePresent = (sitesData.data || []).find(
      site => site.code === formik.values?.sitecode?.code
    )
    const siteHidden = (filteredSites || []).find(
      site => site.code !== formik.values?.sitecode?.code
    )

    if (sitePresent && siteHidden) {
      setShowSiteOptions(false)
    }
  }, [])

  const getSiteData = useCallback(
    debounce(async data => {
      try {
        const response = await api.site.getSiteFromCode(data)
        updateData(data => ({
          ...data,
          site: response.data
        }))
        setSearchingForSite(false)
      } catch (error) {
        formik.setFieldError(
          'sitecode',
          t('registerKit.patientDetails.patientForm.sitecode.errors.invalid')
        )
        setSearchingForSite(false)
        updateData(data => ({
          ...data,
          site: null
        }))
      }
    }, 300),
    []
  )

  const validateSiteCode: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (evt) => {
    toUpperOnChange(evt)
    formik.setFieldError('sitecode', '')
    formik.setFieldValue('sitecode', { code: evt.target.value })
    setSearchingForSite(true)
    getSiteData(evt.target.value)
  }

  const handleProgramTestNext = async () => {
    setShowPromptOnExit(false)
    setProgramTestLoading(true)

    const site = data.site
    const tempSite = { id: site?.id, code: site?.code, label: site?.name }

    await api.kit.register(data?.kit?.code as string, {
      siteName: site?.name,
      requestType: 'register'
      // pageInfo: 'PatientSelection'
    })

    await api.kit.update(data?.kit?.id as number, {
      patient_id: data?.patientInfoForm?.patient?.id,
      pageInfo: 'PatientSelection',
      supervision: {
        fullName: '',
        dob: '',
        phone: '',
        email: '',
        signBase64: null
      }
    })

    const payment = sitePaymentMethod(site || undefined, data?.kit?.kitTypeConfig)
    const hasScreeningQuestions = checkScreeningQuestions(
      data?.kit?.kitTypeConfig,
      data.site
    )

    updateData(state => ({
      ...state,
      patientInfoForm: {
        patient: data.patientInfoForm.patient,
        sitecode: tempSite
      },
      site: {
        ...site,
        retail:
          sitePaymentMethod(state?.site || undefined, state?.kit?.kitTypeConfig) === 'prepaid'
      },
      payment
    }) as RegisterKitDataI)

    if (hasScreeningQuestions) {
      navigate('../screening')
      return
    }
    if (payment !== 'prepaid') {
      navigate('../payment-method')
      return
    }
    navigate('../sample-collection')
  }

  useEffect(() => {
    if (formik.values.patient) {
      setSelectedOption(JSON.stringify(formik.values.patient))
    }
  }
  , [formik.values.patient])

  if (sitesData.isLoading) return <Loader />

  const { patientMinAge } = getMinAge(data?.kit || undefined, data?.site || undefined)

  const restrictedStateAlert = (
    <Alert severity='warning'>
      { t('registerKit.patientDetails.notAllowedInState', {
        stateName: patient?.address?.state_abbreviation
      }) }
      <br />
      <br />
      { t('registerKit.patientDetails.contactForHelp', {
        email:
          whiteLabelConfig?.supportEmail ||
          process.env.REACT_APP_SUPPORT_EMAIL
      }) }
    </Alert>
  )

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip { ...props } classes={ { popper: className } } />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11
    }
  }))

  const tooltipPatientSelect = 'Your account can have one or more patient profiles on it. We need to know which patient will be taking this test in order to provide results.'

  // The kit type config can be configured to allow only a specific sex assigned at birth
  const isAllowedDueToTheSexAssignedAtBirth = checkIfAllowedDueToSexAssignedAtBirth(data?.kit?.kitTypeConfig, patient)

  return (
    <>
      { data?.kit?.isProgramKit
        ? (
          <Stack gap={ 3 }>
            <PatientInfoFormProgram patient={ data?.patientInfoForm?.patient || null } />
            <Prompt
              open={ showPrompt && showPromptOnExit }
              onClose={ cancelNavigation }
              confirmNavigation={ confirmNavigation }
            />
            { showInvalidState ? restrictedStateAlert : null }
            { !allowedDueToTheAge || showInvalidState
              ? (
                <Button variant='contained' component={ Link } to='/' fullWidth>
                  { t('registerKit.patientDetails.returnToDashboard') }
                </Button>
                )
              : (
                <RegisterKitNavigation
                  back={
                    <Button
                      disabled
                      fullWidth={ mobile }
                      onClick={ () => {
                        analyticEventTracker('(E) Back Button Clicked on Patient Info Form', {
                          category: 'Register kit'
                        })
                        setShowPromptOnExit(false, () => navigate('../'))
                      }}
                      sx={ {
                        textDecoration: mobile ? 'underline' : 'none',
                        '&:hover': { textDecoration: mobile ? 'underline' : 'none' },
                        '&:active': { textDecoration: mobile ? 'underline' : 'none' }
                      } }
                    >
                      { t('multiFormNavigation.back') }
                    </Button>
                  }
                  next={
                    <LoadingButton
                      loading={ isProgramTestLoading }
                      onClick={ () => {
                        analyticEventTracker('(E) Next Button Clicked on Patient Info Form', {
                          category: 'Register kit'
                        })
                        handleProgramTestNext()
                      } }
                      variant='contained'
                      fullWidth={ mobile }
                    >
                      { t('multiFormNavigation.next') }
                    </LoadingButton>
                  }
                />
                ) }
          </Stack>
          )
        : (
          <Stack
            component='form'
            autoComplete='off'
            sx={ { gap: 3 } }
            onSubmit={ formik.handleSubmit }
          >
            <Prompt
              open={ showPrompt && showPromptOnExit }
              onClose={ cancelNavigation }
              confirmNavigation={ confirmNavigation }
            />
            { !data?.kit?.is_fulfilled && data?.kit?.code && isSHK
              ? (
                <>
                  <Typography variant='h5' component='h2'>
                    { t('registerKit.patientDetails.patientForm.sitecode.title') }
                  </Typography>
                  <Stack
                    sx={ {
                      gap: 1,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      alignItems: 'flex-start'
                    } }
                  >
                    <Box sx={ { flex: '1 1 300px' } }>
                      { showSiteOptions && siteOptions.length
                        ? (
                          <Autocomplete
                            disabled={ searchingForSite }
                            id='siteCode'
                            getOptionLabel={ option =>
                              option.label ? option.label : ''
                            }
                            options={ siteOptions }
                            value={ formik.values.sitecode || null }
                            isOptionEqualToValue={ (option, value) =>
                              option.code === value.code
                            }
                            onChange={ async (_evt, option) => {
                              try {
                                if (option?.id === 'addNewSite') {
                                  toggleShowSiteOptions()
                                  return
                                }
                                formik.setFieldValue('sitecode', option)
                                formik.setErrors({ sitecode: undefined })

                                setSearchingForSite(true)
                                const response = await api.site.getSiteFromCode(
                                  option.code
                                )
                                updateData(data => ({
                                  ...data,
                                  site: response.data
                                }))
                                setSearchingForSite(false)
                              } catch (error) {
                                formik.setFieldError(
                                  'sitecode',
                                  t(
                                    'registerKit.patientDetails.patientForm.sitecode.errors.invalid'
                                  )
                                )
                                setSearchingForSite(false)
                                updateData(data => ({
                                  ...data,
                                  site: null
                                }))
                              }
                            } }
                            renderInput={ props => (
                              <TextField
                                { ...props }
                                label={ t(
                                  'registerKit.patientDetails.patientForm.sitecode.label'
                                ) }
                                helperText={ formik.errors.sitecode as string }
                                error={ !!formik.errors.sitecode }
                                InputProps={ {
                                  ...props.InputProps,
                                  endAdornment: (
                                    <>
                                      { searchingForSite
                                        ? (
                                          <CircularProgress color='inherit' size={ 20 } />
                                          )
                                        : null }
                                      { props.InputProps.endAdornment }
                                    </>
                                  )
                                } }
                              />
                            ) }
                            renderOption={ (props, option) => (
                              <ListItem { ...props } id={ option.id } key={ option.id }>
                                { option.id === 'addNewSite'
                                  ? option.value
                                  : option.label }
                              </ListItem>
                            ) }
                          />
                          )
                        : (
                          <TextField
                            fullWidth
                            autoFocus
                            label={ t(
                              'registerKit.patientDetails.patientForm.sitecodeTextfield.label'
                            ) }
                            name='sitecode'
                            value={ formik.values.sitecode?.code || '' }
                            onChange={ validateSiteCode }
                            helperText={
                              formik.errors.sitecode ? formik.errors.sitecode as string : ' '
                            }
                            error={ !!formik.errors.sitecode }
                            FormHelperTextProps={ {
                              style: {
                                height: '20px'
                              }
                            } }
                            InputProps={ {
                              endAdornment: (
                                <>
                                  { searchingForSite
                                    ? (
                                      <CircularProgress color='inherit' size={ 20 } />
                                      )
                                    : null }
                                </>
                              )
                            } }
                          />
                          ) }
                    </Box>
                    { siteOptions.length && !showSiteOptions
                      ? (
                        <Button
                          sx={ { mt: '3px', justifyContent: 'start' } }
                          onClick={ () => {
                            analyticEventTracker('(E) Toggled Site Selection on Patient Info Form', {
                              category: 'Register kit'
                            })
                            toggleShowSiteOptions()
                          }
                          }
                        >
                          { t(
                            'registerKit.patientDetails.patientForm.sitecode.switchBtn'
                          ) }
                        </Button>
                        )
                      : null }
                  </Stack>
                </>
                )
              : null }
            {/* <Divider /> */ }
            <Stack sx={ { gap: 2 } }>
              <Stack direction='row' justifyContent='space-between' alignContent='center' alignItems='center'>
                <Typography variant='h5' component='h2'>
                  { t('registerKit.patientDetails.patientForm.patient.title') }
                </Typography>
                <LightTooltip title={ tooltipPatientSelect } placement='right'>
                  <InfoIcon color='action' fontSize='small' />
                </LightTooltip>
              </Stack>
              <Typography variant='subtitle1' component='h3'>
                { t('registerKit.patientDetails.patientForm.subtitle') }
              </Typography>

              { data.site && sitePatients.length > 0
                ? <PatientCardList
                  setSelectedOption={ setSelectedOption }
                  selectedOption={ selectedOption }
                  setCurrentPatient={ setCurrentPatient }
                  setCurrentEditPatient={ setCurrentEditPatient }
                  setIsPatientInSite={ setIsPatientInSite }
                  setShowEditPatientForm={ setShowEditPatientForm }
                  sitePatients={ sitePatients }
                  formik={ formik }
                  data={ data }
                />
                : null }

              { data.site
                ? <AddPatientButton
                  moreThanOnePatient={ sitePatients.length > 0 }
                  setShowNewPatientForm={ setShowNewPatientForm }
                  setCurrentPatient={ setCurrentPatient }
                  formik={ formik } />
                : null }

              { allowedDueToTheAge && !isRetailTest && !isPatientInSite && (
                <>
                  <Typography>
                    { t(
                      'registerKit.patientDetails.patientForm.patient.shouldDuplicatePatient.title'
                    ) }
                  </Typography>
                  <RadioGroup
                    aria-labelledby='duplicate-patient'
                    name='shouldDuplicatePatient'
                    value={ formik.values.shouldDuplicatePatient }
                    onChange={ formik.handleChange }
                  >
                    { formik.isError('shouldDuplicatePatient')
                      ? (
                        <FormHelperText
                          error
                          dangerouslySetInnerHTML={ {
                            __html: DOMPurify.sanitize(
                              t(
                                'registerKit.patientDetails.patientForm.patient.shouldDuplicatePatient.errors.selectYes'
                              )
                            )
                          } }
                        />
                        )
                      : null }
                    <FormControl
                      sx={ { gap: 1 } }
                      error={ formik.isError('shouldDuplicatePatient') }
                    >
                      <FormControlLabel
                        control={ <Radio /> }
                        label={ t(
                          'registerKit.patientDetails.patientForm.patient.shouldDuplicatePatient.options.yes'
                        ) }
                        value='yes'
                      />
                      <FormControlLabel
                        control={ <Radio /> }
                        label={ t(
                          'registerKit.patientDetails.patientForm.patient.shouldDuplicatePatient.options.no'
                        ) }
                        value='no'
                      />
                    </FormControl>
                  </RadioGroup>
                </>
              ) }

              { allowedDueToTheAge && showSupervisionForm
                ? (
                  <Stack sx={ { gap: 3, my: 2 } }>
                    <Alert severity='info'>
                      { t(
                        'registerKit.patientDetails.patientForm.supervisionForm.underAgeAlert',
                        { age: patientMinAge }
                      ) }
                    </Alert>
                    <Stack sx={ { gap: 2 } }>
                      <Typography>
                        { t(
                          'registerKit.patientDetails.patientForm.supervisionForm.subtitle'
                        ) }
                      </Typography>
                      <TextField
                        value={ formik.values.supervision.fullName || '' }
                        onChange={ formik.handleChange }
                        name='supervision.fullName'
                        label={ t(
                          'registerKit.patientDetails.patientForm.supervisionForm.fullName.label'
                        ) }
                        error={ formik.isError('supervision.fullName') }
                      />
                      <LocalizationProvider dateAdapter={ AdapterDateFns }>
                        <DatePicker
                          label={ t(
                            'registerKit.patientDetails.patientForm.supervisionForm.dob.label'
                          ) }
                          onChange={ newValue => {
                            const value = moment(newValue).format('YYYY-MM-DD')
                            formik.setFieldValue('supervision.dob', value || '')
                          } }
                          value={ formik.values.supervision.dob ? parseISO(formik.values.supervision.dob) : undefined }
                          slotProps={ {
                            textField: {
                              error: formik.isError('supervision.dob'),
                              helperText: formik.getError('supervision.dob')
                            }
                          } }
                        />
                      </LocalizationProvider>
                      <ReactInputMask
                        mask='(999) 999-9999'
                        maskChar=''
                        value={ formik.values.supervision.phone }
                        onChange={ formik.handleChange }
                        id='phone'
                      >
                        {/* @ts-ignore */ }
                        { () => (
                          <TextField
                            id='phone'
                            value={ formik.values.supervision.phone }
                            onChange={ formik.handleChange }
                            name='supervision.phone'
                            label={ t(
                              'registerKit.patientDetails.patientForm.supervisionForm.phone.label'
                            ) }
                            error={ formik.isError('supervision.phone') }
                            helperText={ formik.getError('supervision.phone') }
                          />
                        ) }
                      </ReactInputMask>
                      <TextField
                        value={ formik.values.supervision.email }
                        onChange={ formik.handleChange }
                        name='supervision.email'
                        label={ t(
                          'registerKit.patientDetails.patientForm.supervisionForm.email.label'
                        ) }
                        error={ formik.isError('supervision.email') }
                        helperText={ formik.getError('supervision.email') }
                      />
                      <Stack sx={ { mt: 1 } }>
                        <SignatureBox
                          label={ t(
                            'registerKit.patientDetails.patientForm.supervisionForm.sign.label'
                          ) }
                          onSave={ signBase64 =>
                            formik.setFieldValue(
                              'supervision.signBase64',
                              signBase64
                            )
                          }
                          onClear={ () =>
                            formik.setFieldValue('supervision.signBase64', '')
                          }
                          error={ formik.getError('supervision.signBase64') }
                          defaultValue={ formik.values.supervision.signBase64 }
                        />
                      </Stack>
                      <Typography>
                        { t(
                          'registerKit.patientDetails.patientForm.supervisionForm.cannotSignInfo'
                        ) }
                      </Typography>
                      <TextField
                        name='supervision.fullNameSign'
                        onChange={ formik.handleChange }
                        value={ formik.values.supervision.fullNameSign }
                        label={ t(
                          'registerKit.patientDetails.patientForm.supervisionForm.fullNameSign.title'
                        ) }
                        error={ formik.isError('supervision.signBase64') }
                        helperText={
                          formik.isError('supervision.signBase64')
                            ? t(
                              'registerKit.patientDetails.patientForm.supervisionForm.fullNameSign.errors.required'
                            )
                            : formik.isError('supervision.fullNameSign')
                              ? formik.getError('supervision.fullNameSign')
                              : null
                        }
                      />
                    </Stack>
                  </Stack>
                  )
                : null }

              { formik.values.patient && !allowedDueToTheAge
                ? (<Alert severity='warning'>
                  { t('registerKit.patientDetails.notAllowedDueToUnderAge', {
                    minAge: patientMinAge
                  }) }
                </Alert>)
                : null }

              { showInvalidState ? restrictedStateAlert : null }

              <NotAllowedDueToSexAssignedAtBirthAlert
                kitTypeConfig={ data?.kit?.kitTypeConfig }
                patient={ patient }
              />
              <SameDayRegistrationAlert/>
              { (formik.values.patient && !allowedDueToTheAge) || showInvalidState
                ? (<Button variant='contained' component={ Link } to='/' fullWidth>
                  { t('registerKit.patientDetails.returnToDashboard') }
                </Button>)
                : (<RegisterKitNavigation
                  back={
                    <Button
                      fullWidth={ mobile }
                      onClick={ () => {
                        analyticEventTracker('(E) Back Button Clicked on Patient Info Form', {
                          category: 'Register kit'
                        })
                        setShowPromptOnExit(false, () => navigate('../'))
                      }}
                      sx={ {
                        textDecoration: mobile ? 'underline' : 'none',
                        '&:hover': { textDecoration: mobile ? 'underline' : 'none' },
                        '&:active': { textDecoration: mobile ? 'underline' : 'none' }
                      } }
                    >
                      { t('multiFormNavigation.back') }
                    </Button>
                  }
                  next={
                    <LoadingButton
                      fullWidth={ mobile }
                      loading={ formik.isSubmitting }
                      disabled={
                        (!data?.kit?.is_fulfilled && (!data.site || searchingForSite)) || !['male', 'female'].includes(patient?.sex_assigned_at_birth?.toLowerCase()) || !isAllowedDueToTheSexAssignedAtBirth
                      }
                      type='submit'
                      variant='contained'
                    >
                      { t('multiFormNavigation.next') }
                    </LoadingButton>
                  }
                />)
              }
            </Stack>

            { data.site && (
              <SwipeableDrawer
                ref={ drawerRef }
                anchor='bottom'
                open={ showNewPatientForm || showEditPatientForm }
                onClose={ handleClose }
                onOpen={ () => {} }
                sx={ {
                  '& .MuiPaper-root': {
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    height: '100%',
                    maxHeight: { xs: '100%', md: '90%' },
                    width: 'min(100%, 700px)',
                    m: 'auto'
                  }
                } }
              >
                <Stack sx={ { position: 'relative' } }>
                  <Puller onClose={ handleClose } />
                </Stack>
                {
                  showNewPatientForm
                    ? (<RegisterPatient
                      onRegister={ patient => {
                        setShowNewPatientForm(false)
                        formik.setFieldValue('patient', patient)
                        // This value is used in analytics
                        setSelectedOption(JSON.stringify(patient))
                        dispatch({ type: 'ui/newPatientForm/step', payload: null })
                      } }
                      key={ formik?.values?.patient?.id }
                      sitecode={ data.site.code }
                      kit={ data.kit || undefined }
                      isAdmin={ false }
                      onFormChange={ () => {
                        if (drawerRef.current) {
                          const muiDrawerPaper = drawerRef.current.querySelector(
                            '.' + drawerClasses.paper
                          )
                          if (muiDrawerPaper) {
                            muiDrawerPaper.scrollTo(0, 0)
                          }
                        }
                      } }
                    />)
                    : null
                }

                {
                  showEditPatientForm && currentEditPatient
                    ? (<EditPatient
                      patient={ currentEditPatient }
                      siteCode={ data.site.code }
                      onEdit={ () => handleEditPatient.mutate() }
                      isAdmin={ false }
                      step={ 0 }
                      kit={ data.kit || undefined }
                    />)
                    : null
                }

              </SwipeableDrawer>
            ) }
          </Stack>
          ) }
    </>
  )
}

export function Puller ({ onClose }: PullerProps) {
  return (
    <Stack
      direction='row'
      sx={ {
        alignItems: 'center',
        padding: 2,
        borderTopLeftRadius: 8,
        borderTopRightRaduis: 8,
        justifyContent: 'center'
      } }
    >
      <Box
        sx={ {
          borderRadius: 2,
          height: 6,
          width: 30,
          backgroundColor: 'gray'
        } }
      />
      <IconButton
        onClick={ () => {
          analyticEventTracker('(E) Close Button Clicked on Drawer on Patient Info Form', {
            category: 'Register kit'
          })
          if (onClose) { onClose() }
        }
        }
        sx={ { position: 'absolute', right: '10px' } }
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}

// Utils

const toUpperOnChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  evt.target.value = evt.target.value.toUpperCase()
}

function getSupervisionValidationSchema (t: TFunction) {
  return yup.object().shape({
    fullName: yup
      .string()
      .required(
        t('registerKit.patientDetails.supervisionForm.fullName.errors.required')
      ),
    dob: yup
      .string()
      .required(
        t('registerKit.patientDetails.supervisionForm.dob.errors.required')
      )
      .nullable()
      .test(
        'dob',
        t('registerKit.patientDetails.supervisionForm.dob.errors.invalid'),
        dob => {
          const age = moment().diff(moment(dob), 'years', false)
          return age >= 18
        }
      ),
    phone: yup
      .string()
      .required(
        t('registerKit.patientDetails.supervisionForm.phone.errors.required')
      ),
    email: yup
      .string()
      .email(
        t('registerKit.patientDetails.supervisionForm.email.errors.invalid')
      )
      .required(
        t('registerKit.patientDetails.supervisionForm.email.errors.required')
      ),
    fullNameSign: yup.string(),
    signBase64: yup
      .string()
      .nullable()
      .when('fullNameSign', (fullNameSign, schema) => {
        if (!fullNameSign) {
          return schema
            .required(
              t(
                'registerKit.patientDetails.supervisionForm.fullNameSign.errors.required'
              )
            )
            .nullable()
        }
        return schema
      })
  })
}

function getBaseValidationSchema (t: TFunction) {
  return yup.object().shape({
    patient: yup
      .object()
      .required(
        t('registerKit.patientDetails.patientForm.patient.errors.required')
      )
      .nullable(),
    sitecode: yup
      .object()
      .when('isKitFulfilled', {
        is: false,
        then: yup
          .object()
          .required(
            t('registerKit.patientDetails.patientForm.sitecode.errors.required')
          )
          .nullable()
      })
      .nullable()
  })
}

function getRetailValidationSchema (t: TFunction) {
  return yup.object().shape({
    patient: yup
      .object()
      .required(
        t('registerKit.patientDetails.patientForm.patient.errors.required')
      )
      .nullable(),
    sitecode: yup
      .object()
      .when('isKitFulfilled', {
        is: false,
        then: yup
          .object()
          .required(
            t('registerKit.patientDetails.patientForm.sitecode.errors.required')
          )
          .nullable()
      })
      .nullable(),
    fullName: yup
      .string()
  })
}
// .required(
//   t('registerKit.patientDetails.retailForm.fullName.errors.required')
// )
function getPatientNotInSiteValidationSchema (t: TFunction) {
  return yup.object().shape({
    patient: yup
      .object()
      .required(
        t('registerKit.patientDetails.patientForm.patient.errors.required')
      )
      .nullable(),
    sitecode: yup
      .object()
      .when('isKitFulfilled', {
        is: false,
        then: yup
          .object()
          .required(
            t('registerKit.patientDetails.patientForm.sitecode.errors.required')
          )
          .nullable()
      })
      .nullable(),
    disclaimerAgree: yup
      .boolean()
      .oneOf(
        [true],
        t(
          'registerKit.patientDetails.retailForm.disclaimerAgree.errors.required'
        )
      ),
    fullName: yup
      .string(),
    shouldDuplicatePatient: yup.string().matches(/(yes)/)
  })
}

function getValidationSchema (
  isRetailTest: boolean,
  isPatientInSite: boolean,
  patient: PatientI | undefined,
  site: SiteI | undefined,
  kit: Kit | undefined,
  t: TFunction
) {
  let dynamicValidationSchema = null
  if (isRetailTest) {
    dynamicValidationSchema = getRetailValidationSchema(t)
  } else if (!isPatientInSite) {
    dynamicValidationSchema = getPatientNotInSiteValidationSchema(t)
  } else {
    dynamicValidationSchema = getBaseValidationSchema(t)
  }
  return dynamicValidationSchema.concat(
    yup.object().shape({
      supervision: yup
        .object()
        .nullable()
        .when('patient', {
          is: () => requireSupervision(patient, site, kit),
          then: getSupervisionValidationSchema(t)
        })
    })
  )
}

// Interfaces
interface PullerProps {
  onClose: () => void
}

// interface DisclaimerAndConsentProps {
//   formik: ReturnType<typeof useFormik>
//   site: SiteI | undefined
// }

// interface DisclaimerDialogProps {
//   open: boolean
//   onClose: () => void
//   site: SiteI
// }

// interface ConsentDialogProps {
//   open: boolean
//   onClose: () => void
//   site: SiteI
// }

type SitePatientsI = (PatientI | { id: 'newPatient'; value: JSX.Element })[]

export default PatientInfoForm
