import React, { useEffect, useState } from 'react'
import LayoutWisFlow from './components/LayoutWisconsonFlow'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import RadioGroupComponent from './components/RadioGroupComponent'
import { useFormik } from 'formik'
import { TestingProgramNavigation, useTestingProgram } from '.'
import { Navigate, useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import useAuth0 from 'hooks/useAuth0'
import { api } from 'Core'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { useQueryClient } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { ExpandGroupComponent, Paper } from 'components'
import { Trans, useTranslation } from 'react-i18next'
import { AnalyteConfigI } from '../../types/db/AnalyteConfigI'
import { ProgramConfigWithHeaderI } from '../../Core/api'
import { KitTypeConfigI } from '../../types/db/KitTypeConfigI'

export const RenderLabel = ({ renderData }: RenderLabelProps) => {
  const { t } = useTranslation()

  if (!renderData) return null

  const tests = (renderData.analytes || [])?.map((analyte, idx, array) => {
    const analyteConfig = analyte?.analyteConfig as AnalyteConfigI
    if (!analyteConfig) return null

    return (<Typography variant="body1" key={analyteConfig?.id}>
        {idx === array.length - 1
          ? (analyteConfig?.display_name)
          : (<span>{analyteConfig?.display_name},&nbsp;</span>)}
      </Typography>)
  })

  return (
    <Box>
      <Typography variant="subtitle1" component="h3" fontWeight={700}>
        {renderData.skuName}
      </Typography>
      <Stack
        display="flex"
        flexDirection="row"
        whiteSpace="nowrap"
        flexWrap="wrap"
      >
        {tests.length > 0 ? <Typography mr={0.5}>{t('ftp.renderLabel.testsFor')}:</Typography> : null }
        {tests}
      </Stack>
    </Box>
  )
}

function EligibleTrue () {
  const { t } = useTranslation()
  const { jsonData, data, updateData, frequency } = useTestingProgram()
  const { user } = useAuth0()
  const { slug } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [showSkuIneligible, setShowSkuInelgible] = useState(data.err === 'PATIENT_SKU_INELIGIBLE')

  useEffect(() => {
    let allowedSKU = []
    try {
      allowedSKU = JSON.parse(window.sessionStorage.getItem('allowedSKU') || '[]')
    } catch (error) {}

    if (allowedSKU.length > 0) {
      setShowSkuInelgible(true)
      const programConfigData = queryClient.getQueryData([
        'program-content',
        slug
      ]) as ProgramConfigWithHeaderI
      queryClient.setQueryData(['program-content', slug], {
        ...programConfigData,
        allowedSiteSkus: allowedSKU
      })
      window.sessionStorage.removeItem('allowedSKU')
    }
  }, [])

  const options = (jsonData?.allowedSiteSkus || []).map((option) => ({
    label: <RenderLabel renderData={{ ...option.kitTypeConfig, skuName: option.skuName }} />,
    value: option.skuID
  }))

  const validationSchema = yup.object({
    yoursituation: yup.string().required(t('registerPatient.validation.requiredField'))
  })

  const formik = useFormik({
    initialValues: {
      yoursituation: data.eligibleTrue?.selectedSKU
    },
    onSubmit: async (values) => {
      try {
        const response = await api.program.checkProgramPauseStatus(slug as string)
        if (response.pauseStatus) {
          const programConfigData = queryClient.getQueryData([
            'program-content',
            slug
          ])
          queryClient.setQueryData(['program-content', slug], {
            // @ts-ignore
            ...programConfigData,
            isPaused: true
          })
          navigate('../program-paused')
          return
        }
        if (user && data.eligibility?.dob) {
          const isPatientEligible =
            await api.program.checkSKUEligibilityFromDob(slug as string, {
              dob: data.eligibility.dob
            })
          if (!isPatientEligible?.eligible) {
            navigate('../not-eligible')
          } else if (isPatientEligible?.msg === 'PATIENT_SKU_INELIGIBLE') {
            if (
              isPatientEligible?.ineligibleSkus?.includes(values.yoursituation)
            ) {
              setShowSkuInelgible(true)
              const programConfigData = queryClient.getQueryData([
                'program-content',
                slug
              ])
              const newAllowedSkus = jsonData?.allowedSiteSkus?.filter(
                (sku) => !isPatientEligible?.ineligibleSkus?.includes(sku.skuID)
              )
              queryClient.setQueryData(['program-content', slug], {
                // @ts-ignore
                ...programConfigData,
                allowedSiteSkus: newAllowedSkus
              })
              return
            }
          }
        }
        updateData((currentData) => ({
          ...currentData,
          eligibleTrue: {
            selectedSKU: values.yoursituation
          }
        }))
        navigate('../your-info')
      } catch (error) {
        console.log(error)
        dispatch(
          showSnackBar({
            show: true,
            severity: 'error',
            message: t('afterCare.review.snackbar.error')
          })
        )
      }
    },
    validationSchema
  })

  if (!jsonData?.allowedSiteSkus?.length) {
    return <Navigate to='../not-eligible' replace />
  }

  const orderFrequency = frequency?.orderFreq

  return (
    <Stack>
      <LayoutWisFlow headerText={showSkuIneligible ? t('ftp.selectTest.ineligibleSkuHeader') : jsonData.eligilbleTrue.title}>
        <Stack component="form" onSubmit={formik.handleSubmit} gap={2}>
          {
            showSkuIneligible
              ? (
                  <Alert severity='info'>
                    <Trans i18nKey='ftp.selectTest.ineligibleSkuDescription' orderFrequency={orderFrequency}>
                    This program only offers each person the same test every {{ orderFrequency }} months. Please choose a different free test from the list below or check out these{' '}
                    <Link to = 'https://store.simplehealthkit.com/' target="_blank" >
                      <strong>low cost testing options.</strong>
                    </Link>
                    </Trans>
                  </Alert>
                )
              : (
                  <Stack
                    component="img"
                    src={`/assets/${jsonData.eligilbleTrue.img.src}`}
                    alt={jsonData.eligilbleTrue.img.alt}
                    sx={{ m: 'auto' }}
                  />
                )
          }
          <RadioGroupComponent
            headerText={jsonData.eligilbleTrue.radioOptions.title}
            onChange={formik.handleChange}
            name={'yoursituation'}
            options={options}
            value={formik.values.yoursituation || ''}
            error={
              formik.touched?.yoursituation && !!formik.errors.yoursituation
            }
            helperText={
              formik.touched.yoursituation &&
                Boolean(formik.errors.yoursituation)
                ? formik.errors.yoursituation as string
                : ''
            }
          />
          <Paper sx={{ backgroundColor: '#0000000A', padding: 2 }}>
            <Stack sx={{ gap: 1 }}>
              <Typography sx={{ fontWeight: 700 }}>{jsonData.eligilbleTrue.freeSupportSection.title}</Typography>
              <Typography>{jsonData.eligilbleTrue.freeSupportSection.description}</Typography>
            </Stack>
          </Paper>
          <ExpandGroupComponent
            headerText={jsonData.eligilbleTrue.expandOptions.title}
            datas={jsonData.eligilbleTrue.expandOptions.data}
          />
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: jsonData.eligilbleTrue.footerText }} />

          <TestingProgramNavigation
            back={
              <Button onClick={() => navigate('../eligibility')}>{t('multiFormNavigation.back')}</Button>
            }
            next={
              <LoadingButton
                loading={formik.isSubmitting}
                type="submit"
                variant="contained"
              >
                {t('multiFormNavigation.next')}
              </LoadingButton>
            }
          />
        </Stack>
      </LayoutWisFlow>
    </Stack>
  )
}

EligibleTrue.propTypes = {}

interface RenderLabelProps {
  renderData: Partial<KitTypeConfigI> & { skuName: string }
}

export default EligibleTrue
