import { useEffect, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const useFingerprint = () => {
    const [fingerprint, setFingerprint] = useState<null|string>(null);
    useEffect(() => {
        const fingerprint = window.localStorage.getItem(`device_fingerprint`);
        
        if(fingerprint)
            return setFingerprint(fingerprint);
        const getFingerprint = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            window.localStorage.setItem(`device_fingerprint`, result.visitorId);
            setFingerprint(result.visitorId);
        };
        getFingerprint();
    }, []);
    return fingerprint;
};

export default useFingerprint;
