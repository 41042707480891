import './index.css'
import { analyticEventTracker } from 'utils/analytics'
import {
  Button,
  Alert,
  Typography,
  CircularProgress,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Box
} from '@mui/material'
import { cachedQuery } from 'Core/utils/cachingUtils'
import { intervals } from 'hooks/useAnalytics'
import { isShkDomain, limsFormatDate } from 'utils/utilFuntions'
import { LoadingButton } from '@mui/lab'
import { SAVE_SITE_DETAILS, showSnackBar } from 'state/actions'
import { getMinAge, getRegisterPatientSchema } from './utils'
import { TextButton } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import api from 'Core/api'
import cookieUtils from 'Core/utils/cookieUtils'
import MedicalData from './components/MedicalData'
import moment, { Moment } from 'moment'
import patientPortalApi from 'Core/patientPortalApi'
import PaymentInfo from 'components/PatientComponents/PaymentInfo'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Site from './components/Site'
import useEmailVerificationPolling from 'hooks/useEmailVerificationPolling'
import useFormik from 'hooks/useFormik'
import useSendEmailVerification from 'hooks/useSendEmailVerification'
import { useTranslation } from 'react-i18next'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import { ReduxState } from '../../types/ReduxState'
import { PatientI } from '../../types/db/PatientI'
import { Kit } from '../../types/db/Kit'
import { paymentTypes, PaymentTypesI } from '../../utils/insurance'
import { useGetSite } from '../../hooks/query/useGetSite'

const RegisterPatient = ({
  isAdmin,
  onRegister,
  sitecode,
  kit,
  onFormChange
}: Props) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const whitelabelConfig = useWhiteLabelConfig()
  const isSHK = isShkDomain()
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState<{ [key: number]: boolean }>({})
  const [copyAddress, setCopyAddress] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [maskedEmail] = useState('')
  const [paymentType, setPaymentType] = useState<keyof PaymentTypesI>(paymentTypes.prepaid)
  const [showEmailVerifyAlert, setShowEmailVerifyAlert] = useState(false)
  const [siteLoading, setSiteLoading] = useState(false)
  const [startTime, setStartTime] = useState(moment())
  const [validation2, setValidationSchema2] = useState<'dl' | 'ssn' | 'insured' | null>(null)
  const [verifyToken] = useState<string | undefined>(undefined)
  const [verifyType] = useState<'portal' | 'lims'>('portal')

  const stepDetails = ['registerPatient.personalInformation', 'registerPatient.insuranceInfo']

  if (isSHK) stepDetails.unshift('registerPatient.siteCode')

  const { id: siteId, code: siteName } = useSelector((state: ReduxState) => state.patientRegistration.siteDetails)
  const totalPacients = useSelector((state: ReduxState) => state.patients).length || 0

  const { data: site } = useGetSite(siteId)
  const customField = site?.register_patient?.custom_field

  const totalSteps = () => paymentType === paymentTypes.prepaid ? stepDetails.length - 1 : stepDetails.length

  const completedSteps = () => Object.keys(completed).length

  const isLastStep = () => {
    if (isSHK) {
      return activeStep === totalSteps() - 1
    } else {
      return activeStep === totalSteps()
    }
  }
  const allStepsCompleted = () => completedSteps() === totalSteps()

  const { patientMinAge } = getMinAge(kit, site)

  const formik = useFormik({
    initialValues: {
      isPartOfOrganization: sitecode ? 'Yes' : !isSHK ? 'Yes' : null,
      registeredType: '',
      locationCode: isSHK ? '' : whitelabelConfig?.siteCode || '',
      patientId: 'new',
      insuranceType: '',
      signBase64: '',
      signName: '',
      tnc: false,
      disclaimerConsentTnc: false,
      consentSignBase64: '',
      consentSignName: '',
      firstName: '',
      lastName: '',
      medicalAddress: {
        addressLine1: '',
        city: '',
        state: '',
        zipCode: '',
        plus4Code: '',
        county: '',
        country: 'US',
        fips: ''
      },
      gender: '',
      sexAssignedAtBirth: '',
      dob: '',
      ethnicity: '',
      email: '',
      confirmedMail: '',
      phone: '',
      race: '',
      driverLicense: '',
      answers: {},
      insured: {
        primaryInsuranceName: '',
        policyNumber: '',
        groupNumber: '',
        patientRelationshipToInsured: '',
        insuredFirstName: '',
        insuredLastName: '',
        insuredDOB: '',
        insuredGender: '',
        primary_insurance_id: '',
        insuranceDisclaimer: false
      },
      uninsured: {
        driverLicense: '',
        socialSecurityNo: ''
      },
      insuredAddress: {
        addressLine1: '',
        city: '',
        state: '',
        zipCode: '',
        plus4Code: '',
        county: '',
        country: 'US',
        fips: ''
      },
      notes: '',
      isInsured: 'uninsured',
      guardianFirstName: '',
      guardianLastName: '',
      guardianDob: ''
    },
    validationSchema: getRegisterPatientSchema({ activeStep, isAdmin: !!isAdmin, paymentType, validation2, customField }),
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        sendTimeToAnalytics(
          startTime,
          setStartTime,
          t(stepDetails[activeStep]),
          location.pathname
        )

        const insurances = window.sessionStorage.getItem('insurance') ? JSON.parse(window.sessionStorage.getItem('insurance') || '') : []
        const insuranceSelected = insurances?.data?.find(
          (ins: any) => ins.id === values.insured?.primary_insurance_id
        )

        setError(null)

        if (activeStep === 0) {
          if (values.isPartOfOrganization !== 'Yes') {
            setPaymentType('uninsured')
            return setActiveStep(2)
          }
          setPaymentType('insured')
          return handleSiteCodeSubmit(values.locationCode)
        }

        if (!isLastStep()) {
          handleComplete()
        }

        if (isLastStep()) {
          const dobArray = limsFormatDate(values.dob).split('-')
          const insuredDobArray = limsFormatDate(
            values?.insured?.insuredDOB
          ).split('-')

          const dataObj = {
            registeredType: '',
            address: '',
            address2: '',
            addressComponents: {
              city_name: values.medicalAddress.city,
              country: values.medicalAddress.country,
              county: values.medicalAddress.county,
              delivery_line_1: values.medicalAddress.addressLine1,
              plus4_code: values.medicalAddress.plus4Code,
              state_abbreviation: values.medicalAddress.state,
              zipcode: values.medicalAddress.zipCode
            },
            agreed: values.tnc,
            agreedConsent: false,
            dob: values.dob,
            dob_pretty: `${dobArray[1]}/${dobArray[2]}/${dobArray[0]}`,
            driver_license_image: '',
            driver_license_name: '',
            driver_license_number: values.uninsured.driverLicense,
            error_uninsured: '',
            ethnicity: values.ethnicity,
            firstName: values.firstName,
            gender: values.gender,
            group_number: values.insured.groupNumber,
            guardianFirstName: '',
            guardianLastName: '',
            guardian_dob: '',
            guardian_dob_pretty: '',
            insurance_card_image: '',
            insurance_card_name: '',
            insurance_pay:
              paymentType === 'prepaid' ? 'client_bill' : paymentType,
            insured_address_components: {
              city_name: values.insuredAddress.city,
              country: values.insuredAddress.country,
              county: values.insuredAddress.county,
              delivery_line_1: values.insuredAddress.addressLine1,
              plus4_code: values.insuredAddress.plus4Code,
              state_abbreviation: values.insuredAddress.state,
              zipcode: values.insuredAddress.zipCode
            },
            insured_date_of_birth:
              paymentType === 'insured'
                ? limsFormatDate(values.insured.insuredDOB)
                : limsFormatDate(values.dob),
            insured_date_of_birth_pretty:
              paymentType === 'insured'
                ? `${insuredDobArray[1]}/${insuredDobArray[2]}/${insuredDobArray[0]}`
                : `${dobArray[1]}/${dobArray[2]}/${dobArray[0]}`,
            insured_first_name:
              paymentType === 'insured'
                ? values.insured.insuredFirstName
                : values.firstName,
            insured_gender:
              paymentType === 'insured'
                ? values.insured.insuredGender
                : values.gender,
            insured_last_name:
              paymentType === 'insured'
                ? values.insured.insuredLastName
                : values.lastName,
            lastName: values.lastName,
            med_driver_license_number: '',
            med_social_security_number: '',
            notes: values.notes,
            passport: '',
            past_medical: '',
            phone: values.phone,
            policy_number: values.insured.policyNumber
              ? values.insured.policyNumber
              : paymentAliases[paymentType],
            present_medical: '',
            primary_insurance: values.insured.primaryInsuranceName
              ? values.insured.primaryInsuranceName
              : paymentAliases[paymentType],
            race: values.race,
            relationship: values.insured.patientRelationshipToInsured,
            signature: values.signBase64,
            signature_text: values.signName,
            siteCode: values.locationCode,
            social_security_number: values.uninsured.socialSecurityNo,
            transaction_id: null,
            validatedSiteCode: null,
            sexAssignedAtBirth: values.sexAssignedAtBirth,
            isInsured: values.isInsured
          }

          const registryData = {
            siteId: formik.values.locationCode ? isSHK ? siteId : whitelabelConfig?.siteId : '',
            name: `${values.firstName} ${values.lastName}`,
            first_name: values.firstName,
            last_name: values.lastName,
            gender: values.gender,
            date_of_birth: limsFormatDate(values.dob),
            ethnicity: values.ethnicity,
            phone: values.phone,
            country: values.medicalAddress.country,
            primary_insurance:
              paymentType === 'insured'
                ? values.insured.primaryInsuranceName
                : null,
            primary_insurance_id: values.insured.primary_insurance_id,
            bill_id: insuranceSelected?.bill_type_id,
            primary_insured_address:
              paymentType === 'insured'
                ? `${values.insuredAddress.addressLine1}, ${values.insuredAddress.city}, ${values.insuredAddress.state} ${values.insuredAddress.zipCode}`
                : '',
            primary_number:
              paymentType === 'insured' ? values.insured.policyNumber : null,
            primary_insured_dob:
              paymentType === 'insured'
                ? limsFormatDate(values.insured.insuredDOB)
                : limsFormatDate(values.dob),
            primary_relationship_to_insured:
              values.insured.patientRelationshipToInsured,
            insured: '',
            primary_insured_last_name:
              paymentType === 'insured'
                ? values.insured.insuredLastName
                : values.lastName,
            secondary_insured_last_name: '',
            address: `${values.medicalAddress.addressLine1}, ${values.medicalAddress.city}, ${values.medicalAddress.state} ${values.medicalAddress.zipCode}`,
            zipcode: values.medicalAddress.zipCode,
            primary_group: values.insured.groupNumber,
            primary_insured_zip:
              paymentType === 'insured'
                ? values.insuredAddress.zipCode
                : values.medicalAddress.zipCode,
            primary_insured_first_name:
              paymentType === 'insured'
                ? values.insured.insuredFirstName
                : values.firstName,
            primary_insured_gender:
              paymentType === 'insured'
                ? values.insured.insuredGender
                : values.gender,
            notes: values.notes,
            race: values.race,
            driver_license:
              values.driverLicense || values.uninsured.driverLicense,
            name_dob: `${values.firstName} ${values.lastName} (${dobArray[1]}/${dobArray[2]}/${dobArray[0]})`,
            name_dob_zip: `${values.firstName} ${values.lastName} (${dobArray[1]}/${dobArray[2]}/${dobArray[0]}#${values.medicalAddress.zipCode})`,
            client_site: formik.values.locationCode ? siteName : '',
            bill: paymentType === 'insured' ? 'Insured' : 'Uninsured',
            ssn: values.uninsured.socialSecurityNo,
            smartystreets: `${values.medicalAddress.addressLine1}, ${values.medicalAddress.city}, ${values.medicalAddress.state} ${values.medicalAddress.zipCode}`,
            minimumAgeAllowed: 18,
            guardian_first_name: values.guardianFirstName,
            guardian_last_name: values.guardianLastName,
            guardian_dob: values.guardianDob
              ? limsFormatDate(values.guardianDob)
              : '',
            customFieldValue: undefined
          }

          if (customField?.enabled) {
            registryData.customFieldValue = values.customFieldValue
          }

          if (paymentType === 'insured') {
            const relationData = relationMapper.find(
              (rltn) =>
                rltn.name === values.insured.patientRelationshipToInsured
            )
            registryData.primary_relationship_to_insured = relationData?.id
          }

          if (values.patientId === 'new') {
            if (!values.isInsured && values.isPartOfOrganization === 'Yes') {
              formik.setFieldError(
                'isInsured',
                'registerPatient.validation.isInsured'
              )
              return
            }

            registryData.minimumAgeAllowed = patientMinAge
            const newPatient = await api.patient.addPatient({
              registryData,
              otherInfo: dataObj,
              verifyToken
            })
            analyticEventTracker('New Patient created', {
              category: 'Register patient'
            })
            // Check if the user created his account on this session
            const isAccountNew = cookieUtils.getCookie('newUser')
            // Check if the user has a patient registered
            if (totalPacients === 0) {
              // Register the when the user created his first pacient in Google Analytics
              if (isAccountNew) {
                analyticEventTracker('First patient created when signup', {
                  category: 'Register patient'
                })
              } else {
                analyticEventTracker('First patient created after signup', {
                  category: 'Register patient'
                })
              }
            }
            sendTimeToAnalytics(
              startTime,
              setStartTime,
              t(stepDetails[activeStep]),
              location.pathname
            )

            // if (registryRes.message === 'verify-lims-mail') {
            //   setShowEmailVerifyAlert(true)
            //   setVerifyToken(
            //     registryRes.message === 'verify-lims-mail'
            //       ? registryRes.token
            //       : null
            //   )
            //   setVerifyType(
            //     registryRes.message === 'verify-lims-mail' ? 'lims' : 'portal'
            //   )
            //   setMaskedEmail(registryRes.maskedEmail)
            //   return
            // }
            if (newPatient) {
              // setShowDialog(true)
              const allPatients = await api.patient.getAll()
              await queryClient.invalidateQueries(['patients'])
              dispatch(
                showSnackBar({
                  show: true,
                  message: t('registerPatient.snackbar.success')
                })
              )
              if (onRegister) {
                const patientCreatedWithIdMapper = allPatients.find(
                  (p) =>
                    p.first_name === newPatient.first_name &&
                    p.last_name === newPatient.last_name &&
                    p.dob === newPatient.dob
                )

                if (patientCreatedWithIdMapper) {
                  onRegister(patientCreatedWithIdMapper)
                }
              } else if (location.state?.locationCode) {
                navigate('/register', {
                  state: {
                    locationCode: formik.values.locationCode,
                    kitcode: location.state.kitcode
                  }
                })
              } else {
                navigate('/account')
              }
            }
          }
        }
      } catch (error: any) {
        console.error(error)
        setShowEmailVerifyAlert(false)
        if (error?.responseJSON?.error === 'email-mismatch') {
          return setError(t('registerPatient.error.emailMismatch'))
        }
        setError(
          error?.responseJSON?.error === t('registerPatient.error.alreadyExists')
            ? error?.responseJSON?.error
            : t('registerPatient.error.wentWrong')
        )
      }
    }
  })

  const handleNext = () => {
    let newActiveStep =
      isLastStep() && !allStepsCompleted() && isSHK
        ? stepDetails.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    if (formik.values.isPartOfOrganization !== 'Yes' && activeStep === 0) {
      newActiveStep = 2
    }
    setActiveStep(newActiveStep)
    onFormChange && onFormChange()
  }

  const handleBack = () => {
    if (activeStep === 1 && sitecode) return
    if (activeStep !== 0) {
      if (activeStep === 2 && formik.values.isPartOfOrganization !== 'Yes') {
        setActiveStep((current) => current - 2)
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
      }
      onFormChange && onFormChange()
    }
    sendTimeToAnalytics(
      startTime,
      setStartTime,
      t(stepDetails[activeStep]),
      location.pathname
    )
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  useEffect(() => {
    if (activeStep === 0 && !isSHK) {
      setActiveStep(1)
    }
  }, [activeStep])

  useEffect(() => {
    const errors = Object.keys(formik.errors)
    if (errors.length > 0) {
      // Send errors to Analytics
      errors.forEach((error) =>
        analyticEventTracker(`(E) Patient form (${error})`, {
          category: 'Register patient'
        })
      )
    }
  }, [formik.errors])

  useEffect(() => {
    dispatch({
      type: 'ui/newPatientForm/step',
      payload: t(stepDetails[activeStep])
    })
    setStartTime(moment())
  }, [activeStep])

  const handleSiteCodeSubmit = async (siteCode: string) => {
    try {
      formik.setFieldValue('locationCode', siteCode)
      setSiteLoading(true)
      const { values } = formik
      setLoading(true)
      // const res = await cachedQuery(siteCode, () =>
      //   patientPortalApi.validateSite(siteCode)
      // )
      const res = await api.site.getSiteFromCode(siteCode)
      if (!res.data) {
        setLoading(false)
        formik.setFieldError('locationCode', 'Invalid Client Site Code')
        window.sessionStorage.removeItem(siteCode)
        window.sessionStorage.removeItem(`${siteCode}ExpiryToken`)
        setSiteLoading(false)
      } else {
        const insuranceNames = await cachedQuery('insurance', () =>
          api.proxy.getInsuranceProviders()
        )
        const insuranceData = insuranceNames.data.map((data: { name: string, id: string }) => ({
          label: data.name,
          id: data.id
        }))
        const race = await cachedQuery(`${siteCode}-race`, () =>
          patientPortalApi.getRace(siteCode)
        )
        const gender = await cachedQuery(`${siteCode}-gender`, () =>
          patientPortalApi.getGender(siteCode)
        )
        const ethnicity = await cachedQuery(
          `${values.locationCode}-ethnicity`,
          () => patientPortalApi.getEthnicity(siteCode)
        )
        dispatch({
          type: SAVE_SITE_DETAILS,
          payload: {
            ...res.data,
            ethnicity: ethnicity.data,
            race: race.data,
            gender: gender.data,
            insuranceNames: insuranceData
          }
        })
        if (values.insured.patientRelationshipToInsured === 'Self') {
          setCopyAddress(true)
        } else setCopyAddress(false)
        setLoading(false)
        handleComplete()
        setSiteLoading(false)
      }
    } catch (error) {
      formik.setFieldError('locationCode', t('registerPatient.validation.invalidSiteCode'))
      setLoading(false)
      setSiteLoading(false)
    }
  }

  useEffect(() => {
    if (activeStep === 0 && !['tests', 'profile'].some((path) => location.pathname.includes(path))) {
      if (sitecode) {
        setActiveStep(1)
        handleSiteCodeSubmit(sitecode)
      }
    }
  }, [])

  useEffect(() => {
    if (formik.values.patientId !== 'new') {
      switch (formik.values.insuranceType) {
        case 'client_bill':
          setPaymentType('prepaid')
          break
        case 'uninsured':
          setPaymentType('uninsured')
          break
        case 'cash':
          setPaymentType('cash')
          break
        default:
          setPaymentType('insured')
          break
      }
    }
  }, [formik.values.patientId, formik.values.insuranceType])

  useEffect(() => {
    (async () => {
      try {
        const kitTypeConfigId = kit?.kitTypeConfigId || kit?.kitTypeConfig?.id

        if (!sitecode || !kitTypeConfigId) return
        const response = await api.kit.getInsurance(
          sitecode,
          String(kitTypeConfigId)
        )
        if (!response.insurance) {
          setPaymentType('prepaid')
          setValidationSchema2(null)
        } else {
          setPaymentType('insured')
          setValidationSchema2('insured')
        }
      } catch (error) {
        console.error(error)
        setPaymentType('prepaid')
        setValidationSchema2(null)
      }
    })()
  }, [sitecode])

  useEffect(() => {
    if (!formik.isSubmitting) return

    if (Object.keys(formik.errors).length > 0) {
      if (Object.keys(formik.errors)[0] === 'medicalAddress') {
        const renamedArray = Object.keys(formik.errors.medicalAddress as object).map(
          (x) => {
            return `medicalAddress.${x}`
          }
        )
        document.getElementsByName(renamedArray[0])[0]?.scrollIntoView()
      } else if (Object.keys(formik.errors).reverse()[0] === 'insured') {
        const renamedArray = Object.keys(formik.errors.insured as object).map((x) => {
          return `insured.${x}`
        })
        document.getElementsByName(renamedArray[0])[0]?.scrollIntoView()
      } else if (Object.keys(formik.errors).reverse()[0] === 'insuredAddress') {
        const renamedArray = Object.keys(formik.errors.insuredAddress as object).map(
          (x) => {
            return `insuredAddress.${x}`
          }
        )
        document.getElementsByName(renamedArray[0])[0]?.scrollIntoView()
      } else {
        document
          .getElementsByName(Object.keys(formik.errors)[0])[0]
          ?.scrollIntoView()
      }
    }
  }, [formik])

  if (siteLoading && location?.state?.from?.pathname?.includes('/register')) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ width: 'min(90%, 600px)', margin: 'auto' }}>
      {error && (
        <Alert
          severity="error"
          sx={{
            mb: '16px !important',
            height: 'auto !important',
            width: 'min(100%, 800px) !important'
          }}
        >
          {error}
        </Alert>
      )}

      <>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          {activeStep > 0
            ? (
              <Typography variant="h5">{isSHK ? t(stepDetails[activeStep]) : t(stepDetails[activeStep - 1])}</Typography>
              )
            : null}
          <Box sx={{ px: 0, py: 1 }}>
            {(activeStep === 0 && isSHK) && <Site formik={formik} />}
            {activeStep === 1 && (
              <MedicalData
                site={site}
                formik={formik}
                disableAllFields={formik.values.patientId !== 'new'}
                editMode={false}
                isAdmin={false}
                kit={kit}
              />
            )}
            {activeStep === 2 && (
              <PaymentInfo
                formik={formik}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
                copyAddress={copyAddress}
                setCopyAddress={setCopyAddress}
                setValidationSchema2={setValidationSchema2}
                disableAllFields={false}
                hideCheckBox={false}
                isAdmin={false}
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: 1,
              px: 0,
              mt: 2
            }}
          >
            <Button
              onClick={handleBack}
              variant="text"
              disabled={
                formik.isSubmitting ||
                loading ||
                (location?.state?.from?.pathname?.includes('/register') &&
                  activeStep === 1) ||
                activeStep === 0 ||
                (!!sitecode && activeStep === 1)
              }
            >
              {t('registerPatient.button.back')}
            </Button>

            <LoadingButton
              type="submit"
              id="patientRegNextBtn"
              loading={formik.isSubmitting || loading}
              variant="contained"
              disabled={ paymentType === paymentTypes.insured && isLastStep() && formik.values.isInsured === 'uninsured' }
            >
              {isLastStep() ? t('registerPatient.button.submit') : t('registerPatient.button.next')}
            </LoadingButton>
          </Box>
        </form>
      </>
      <VerifyEmailDialog
        open={showEmailVerifyAlert}
        onClose={() => setShowEmailVerifyAlert(false)}
        formik={formik}
        type={verifyType}
        token={verifyToken}
        maskedEmail={maskedEmail}
      />
    </Box>
  )
}

function VerifyEmailDialog ({
  open,
  onClose,
  formik,
  type,
  token,
  maskedEmail
}: VerifyEmailDialogProps) {
  const sendEmailVerificationMutation = useSendEmailVerification({
    type,
    token
  })

  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('registerPatient.verifyEmailDialog.title')}</DialogTitle>
      <DialogContent>
        <Stack>
          {sendEmailVerificationMutation.isLoading
            ? (
              <Typography>
                {t('registerPatient.verifyEmailDialog.sendMail')} {maskedEmail}{' '}
                <CircularProgress size={16} />
              </Typography>
              )
            : sendEmailVerificationMutation.isSuccess
              ? (
                <Stack sx={{ gap: 2 }}>
                  <WaitingForEmailVerify
                    onVerify={() => formik.submitForm()}
                    type={type}
                    token={token}
                  />
                  <Stack
                    sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}
                  >
                    <Typography>{t('registerPatient.verifyEmailDialog.dontSeeEmail')}</Typography>
                    <TextButton
                      onClick={() => sendEmailVerificationMutation.mutate()}
                    >
                      {t('registerPatient.verifyEmailDialog.button.resend')}
                    </TextButton>
                  </Stack>
                </Stack>
                )
              : (
                <Stack sx={{ gap: 1 }}>
                  <Typography>
                    {t('registerPatient.verifyEmailDialog.verifyOwnership')}{' '}
                  </Typography>
                  <LoadingButton
                    loading={sendEmailVerificationMutation.isLoading}
                    variant="contained"
                    onClick={() => sendEmailVerificationMutation.mutate()}
                  >
                    {t('registerPatient.verifyEmailDialog.verifyOldAccount')}
                  </LoadingButton>
                </Stack>
                )}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

function WaitingForEmailVerify ({ onVerify, type, token }: WaitingForEmailVerifyProps) {
  useEmailVerificationPolling({
    onVerify,
    type,
    token
  })

  const { t } = useTranslation()

  return (
    <Typography>
      {t('registerPatient.waitingForEmailVerify.waitingEmail')} <CircularProgress size={16} />
    </Typography>
  )
}

// UTILS
// Send to analytics the time the user spends on the step
const sendTimeToAnalytics = (startTime: Moment, setStartTime: Dispatch<SetStateAction<Moment>>, stepName: string, pathname: string) => {
  const changeStepTime = moment()
  const totalTimeSpentOnPage = intervals(
    changeStepTime.diff(startTime, 'minutes')
  )
  analyticEventTracker(`(P) Patient - ${stepName} (${totalTimeSpentOnPage})`, {
    category: 'Pages',
    page: pathname
  })
  setStartTime(moment())
}

const paymentAliases: { [key: string]: string } = {
  uninsured: 'UNINSURED',
  clientBill: 'CLIENT BILL',
  cash: 'CASH'
}

const relationMapper = [
  { id: '1', name: 'Self' },
  { id: '3', name: 'Child' },
  { id: '4', name: 'Parent' },
  { id: '2', name: 'Spouse' }
]

// Interfaces and types
interface Props {
  isAdmin?: boolean
  onRegister?: (patient: PatientI) => void
  sitecode?: string
  kit?: Kit
  onFormChange?: () => void
}

interface VerifyEmailDialogProps {
  open: boolean
  onClose: () => void
  formik: ReturnType<typeof useFormik>
  type: 'portal' | 'lims'
  token: string | undefined
  maskedEmail: string
}

interface WaitingForEmailVerifyProps {
  onVerify: () => void
  type: 'portal' | 'lims'
  token: string | undefined
}

export default RegisterPatient
