import { Navigate, Outlet, Route, Routes } from 'react-router'
import PasswordRequest from 'pages/Auth/PasswordRequest'
import React, { Suspense, useEffect, useState } from 'react'
import ResetPassword from './pages/Auth/ResetPassword'
import { getSubdomain, isShkDomain } from 'utils/utilFuntions'
import SupportPage from 'pages/SupportPage'
import AuthEmailVerified from 'pages/Landing/components/AuthEmailVerified'
import Loader from 'components/Loader'
import jwtUtil from 'Core/utils/jwtUtil'
import { Authenticate, StoreRedirectPath } from 'Authenticate'
import BeaconNonSHK from 'components/BeaconNonSHK'
import { useStoreLastVisitedRoute } from 'index'
import { useSearchParams } from 'react-router-dom'
import useAuth0 from 'hooks/useAuth0'
import { useTranslation } from 'react-i18next'
import { useAnalytics } from './hooks/useAnalytics'
import MfaConfig from 'pages/Profile/MfaConfig'
import useDocumentTitle from 'hooks/useDocumentTitle'
import ChoosePortal from 'pages/Profile/ChoosePortal'
import { isAppPortal } from 'utils/auth0Service'
import MuiXLicense from 'components/MuiXLicense'

const PatientPortalApp = React.lazy(() => import('apps/PatientPortalApp'))
const ProviderApp = React.lazy(() => import('apps/ProviderApp'))
const ManageApp = React.lazy(() => import('apps/ManageApp'))
const LabPortalApp = React.lazy(() => import('apps/LabPortalApp'))
const AdminApp = React.lazy(() => import('apps/AdminApp'))
const HealthApp = React.lazy(() => import('apps/HealthApp'))
const HealthDemoApp = React.lazy(() => import('apps/HealthDemoApp'))
const CustomerAdminApp = React.lazy(() => import('apps/CustomerAdminApp'))

function App () {
  useStoreLastVisitedRoute()
  const { user, getAccessTokenSilently, logout } = useAuth0()
  const { t } = useTranslation()
  const [blurTimer, setBlurTimer] = useState<NodeJS.Timeout>()
  useDocumentTitle();

  // Track user activities like page visit, time spent on page, etc.
  useAnalytics()

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    const token = window.sessionStorage.getItem('token') || ''
    if (token) {
      if (blurTimer) { clearTimeout(blurTimer) }
      const timeout = parseInt(process.env.TIMEOUT || '10')
      const timer = setTimeout(() => {
        if (document.hidden) {
          const pageInfo = window.document.title || 'N/A';
          logout(pageInfo, { actionBy: 'Session Timeout', reason: `No user action for ${timeout} minutes` })
          window.location.href = `https://${window.location.host}/?redirectTo=${window.location.pathname}`
          alert(t('user.loggedout.alert'))
        }
      }, timeout * 1000 * 60)
      setBlurTimer(timer)
    }
  }

  const onFocus = () => {
    const token = window.sessionStorage.getItem('token') || ''
    if (token && blurTimer) {
      clearTimeout(blurTimer)
    }
  }

  useEffect(() => {
    const token = window.sessionStorage.getItem('token') || ''
    if (token) {
      window.addEventListener('blur', onBlur)
      window.addEventListener('focus', onFocus)
      return () => {
        if (token) {
          window.removeEventListener('blur', onBlur)
          window.removeEventListener('focus', onFocus)
        }
      }
    }
  }, [blurTimer])

  useEffect(() => {
    const token = window.sessionStorage.getItem('token') || ''
    if (token) {
      window.addEventListener('blur', onBlur)
      window.addEventListener('focus', onFocus)
      const minutes = parseInt(window.sessionStorage.getItem('expiresIn') || '45m') // Extract the numeric part of the string
      if (isNaN(minutes)) {
        console.log('Invalid duration format')
        return
      }
      const expiresIn = (minutes * 60000 * (0.75)) || (60000 * 45)
      const silentRefresh = setInterval(() => {
        getAccessTokenSilently(false)
      }, expiresIn)

      return () => {
        if (token) {
          clearInterval(silentRefresh)
          window.removeEventListener('blur', onBlur)
          window.removeEventListener('focus', onFocus)
        }
      }
    }
  }, [user])

  if(user){
    const userConfig = jwtUtil.getMfaData();
    const userRoles = jwtUtil.getRoles();
    const isSsoUser = jwtUtil.checkSsoUser();
    const is2faBypass = jwtUtil.check2faBypass();
    
    if(!userConfig.mfaConfig?.enabled && userRoles.length && !isSsoUser && !is2faBypass) {
      return(
        <>
          <BeaconNonSHK isBeaconVisible={ !isShkDomain() } />
          <Routes>
            <Route element={ <Authenticate /> }>
              <Route path='/mfa-config/*' element={ <MfaConfig /> } />
            </Route>
            <Route element={ <StoreRedirectPath pathKey='mfa-redirect'/> }>
              <Route path='*' element={ <Navigate to={`/mfa-config`} /> } />
            </Route>
          </Routes>
        </>
      )
    }
  }
  return (
    <>
      <BeaconNonSHK isBeaconVisible={ !isShkDomain() } />
      <MuiXLicense/>
      <Routes>
        <Route path='/callback' element={ <Callback /> } />
        <Route path='reset-password' element={ <ResetPassword /> } />
        <Route path='/password-request/:token' element={ <PasswordRequest /> } />
        <Route path='/support' element={ <SupportPage /> } />
        <Route path='/email-verified' element={ <AuthEmailVerified /> } />
        <Route element={ <Authenticate /> }>
          <Route path='/choose-portal' element={ <ChoosePortal/> } />
        </Route>
        <Route element={ <StoreRedirectPath pathKey='mfa-redirect'/> }>
          <Route element={ <Authenticate /> }>
            <Route path='/mfa-config/*' element={ <MfaConfig /> } />
          </Route>
        </Route>
        <Route element={ <SuspenseLayoutRoute /> }>
          <Route path='*' element={ <AppBasedOnSubdomain /> } />
        </Route>
      </Routes>
    </>
  )
}

export default App

function Callback () {
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo') || '/'
  const isShk = isShkDomain()
  const isPatientPortal = isAppPortal()

  return <Navigate to={ isShk && isPatientPortal? `/choose-portal?redirectTo=${redirectTo}`: redirectTo } replace />
}

const subdomains = Object.freeze({
  provider: 'provider',
  manage: 'manage',
  admin: 'admin',
  labPortal: 'ninjalab',
  health: 'health',
  healthdemo: 'healthdemo',
  customerAdmin: 'customeradmin'
})

const APPS = [
  {
    subdomain: subdomains.provider,
    component: <ProviderApp />
  },
  {
    subdomain: subdomains.customerAdmin,
    component: <CustomerAdminApp />
    // authorize: jwtUtil.checkCustomerAdmin
  },
  {
    subdomain: subdomains.manage,
    component: <ManageApp />,
    authorize: jwtUtil.checkManageAdmin
  },
  {
    subdomain: subdomains.admin,
    component: <AdminApp />,
    authorize: jwtUtil.checkSuperAdmin
  },
  {
    subdomain: subdomains.labPortal,
    component: <LabPortalApp />,
    authorize: jwtUtil.checkLabPortal
  },
  {
    subdomain: subdomains.health,
    component: <HealthApp />,
    authorize: jwtUtil.checkHealthUser
  },
  {
    subdomain: subdomains.healthdemo,
    component: <HealthDemoApp />,
    authorize: jwtUtil.checkHealthUser
  }
]

function AppBasedOnSubdomain () {
  const app = APPS.find(app => app.subdomain === getSubdomain())

  if (!app) return <PatientPortalApp />
  if (!app.authorize) return app.component
  return (
    <Routes>
      <Route element={ <Authenticate authorize={ app.authorize } /> }>
        <Route path='*' element={ app.component } />
      </Route>
    </Routes>
  )
}

function SuspenseLayoutRoute () {
  return (
    <Suspense fallback={ <Loader /> }>
      <Outlet />
    </Suspense>
  )
}
