import moment from 'moment'
import { appendQueryString } from 'utils/utilFuntions'
import ajax from './ajax'
import { PromotionalWidgetI, SiteI, TPrepProgramConfigSite } from 'types/db/SiteI'
import { PatientI } from 'types/db/PatientI'
import { TPrepProgram } from 'pages/PrepProgram/schemas'
import { TRegisteredPrepProgram } from 'types/db/RegisteredPrepProgram'
import {
  downloadFileRequestData,
  kitFulfillmentJSONData
} from 'health-app/pages/Reports/KitFulfillmentReport'
import { MemberUploadPayload } from 'health-app/components/MemberCSVModal'
import { KitsFromDBI } from 'types/KitFromLimsI'
import { ResultFromLimsI } from 'types/ResultFromLimsI'
import { KitTypeConfigI } from 'types/db/KitTypeConfigI'
import {
  HedisFileJSONData
} from 'health-app/pages/Reports/HedisExtract'
import { AuthUser } from '../types/AuthUser'
import { Kit } from '../types/db/Kit'
import { ConsultI, Pharmacy } from '../types/db/Consult'
import { HealthPlanUtilizationAnalyticsI } from '../types/healthPlan/HealthPlanUtilizationAnalyticsI'
import { UseQueryOptions } from '@tanstack/react-query'
import { HealthPlanTestsAnalyticsI } from '../types/healthPlan/HealthPlanTestsAnalyticsI'
import { HealthPlanOverviewAnalyticsI } from '../types/healthPlan/HealthPlanOverviewAnalyticsI'
import { PatientAnalyticsI } from 'types/healthPlan/PatientAnalyticsI'
import { HealthPlanMembersAnalyticsI } from 'types/healthPlan/HealthPlanMembersAnalyticsI'
import { PopulationHealthDownloadI, ProgramInsightsDownloadI, ProgramInsightsI } from '../types/ProgramInsightsI'
import { PopulationHealthI } from '../types/PopulationHealthI'
import { AppNotificationI, IVRNotificationTextI } from '../types/db/AppNotificationI'
import { OperationsAnalyticsI } from '../types/OperationsAnalyticsI'
import { HealthPlanAnalyticsRawData } from '../types/healthPlan/HealthPlanAnalyticsRawData'
import { SampleI } from '../types/db/SampleI'
import { AnalyteConfigI } from '../types/db/AnalyteConfigI'
import { PatientFromLimsI } from '../types/PatientFromLimsI'
import { HealthFeedbackAnalytics } from 'types/healthPlan/FeedbackAnalytics'
import { THealthPlanNotificationSettings } from 'types/db/HealthPlanNotificationSettings'
import { HealthPlanMemberI } from '../types/db/HealthPlanMemberI'
import { MemberDetailsType } from 'health-app/components/MembersTable/MemberDetails'
import { TranslationI } from '../types/TranslationI'
import { ProgramConfig, ProgramI } from '../types/db/ProgramI'
import { CohortI } from '../types/db/CohortI'
import { SkuResponse } from 'types/db/HealthOptInProgramI'
import { SftpSettingsI, SftpSettingsUpdate } from '../types/db/SftpSettingsI'
import { SsoSettingsI } from 'types/db/SsoSettingsI'
import { Sku } from '../types/db/Sku'
import { MailOnHoldI } from '../types/MailsOnHoldI'
import { FaxOnHoldI } from '../types/FaxOnHoldI'
import { ResultsMailerUpdateFormI } from '../manage-app/pages/Mails/components/UpdateResultsMailerModal'
import { UpdateFaxFormI } from '../manage-app/pages/Faxes/components/UpdateFaxAndResendModal'
import { ResultDeliveryReportI } from '../types/ResultsDeliveryReportI'
import { CustomAnalyticsI } from 'types/db/CustomAnalyticsI'
import { GrafanaDashboardI } from 'types/GrafanaDashboardI'
import { OutreachLog, PCP_Data } from 'types/db/OutreachLog'
import { ReportingConfigForm } from '../admin-app/pages/Site/ResultsAndReports/ReportingSettings/UpdateReportingSettingsModal'
import { GridSortModel } from '@mui/x-data-grid-pro'
import { ProgramKitI } from '../types/db/ProgramKitI'
import { AvailibilityDaysAPI, AvailibilitySlotsAPI, CancelAppointmentAPI, CompleteCareCoachingCheckoutAPI, CompleteConsultCheckoutAPI, SendConsultFormAPI } from '../types/MWLProgram'
import { APIAuditLogI } from '../types/db/ApiAuditLogI'
import { KitFromLimsForResult } from '../components/TestResult'
import { useOutreachResponseI } from '../manage-app/pages/OutreachVisits/OutreachConsultTypes'
import { NinjaLabCliaAudit, NinjaLabReportRunner } from '../types/NinjaLabReportRunner'
import { MessageLogI } from '../types/db/MessageLogI'
import { HealthPlanUploadI } from '../types/db/HealthPlanUpload'
import { ContentI } from '../types/db/ContentI'
import { FeedbackI } from '../types/db/FeedbackI'
import { memberJourneyI } from '../types/MemberJourneyI'
import { AnalyticsReportI } from '../types/AnalyticsReport'
import { RegistrationDataReportI } from '../types/RegistrationDataReportI'
import { usePatientHookResponseI } from '../admin-app/hooks/usePatient'
import { FulfillmentReportI } from '../types/FulfillmentReportI'
import { DoctorI } from '../types/DoctorI'
import { DevSmsLogsI, WhiteListNumberI } from '../types/db/WhiteListNumberI'
import { RawDraftContentState } from 'draft-js'
import { PortalUserI } from '../types/db/PortalUserI'
import { UserStatusUpdateI } from '../pages/Admin/components/UsersNotificationsSubscription'
import { OutreachAttemptReportI } from 'types/OutreachLog'
import { SftpSettingsFormI } from '../admin-app/pages/Settings/SftpResultUpload/SftpResultUpload'
import { MailLabReportFormI } from '../admin-app/pages/Tests/components/MailLabReportButton/MailLabReportFormModal'
import { FaxLabReportFormI } from '../admin-app/pages/Tests/components/FaxLabReportButton/FaxLabReportFormModal'

const api = {
  admin: {
    resultsHeatMap: function (queryString: any): Promise<{
      coordinatesByZipcode: any;
      zipcodesCount: any;
    }> {
      const url = appendQueryString('a/results-heat-map', { ...queryString })
      return ajax(url, { method: 'GET' })
    },
    getRequisitions: function ({
      patientId,
      maxTestsAge,
      page,
      pageSize,
      sorting,
      showApprovalRecords,
      selectedTests,
      textFilter,
      showOnlyAbnormal
    }: {
      patientId?: string
      textFilter?: string
      maxTestsAge?: string,
      page?: number,
      pageSize?: number,
      sorting?: GridSortModel
      showApprovalRecords?: string | null,
      selectedTests?: string[],
      showOnlyAbnormal?: boolean
    }): Promise<KitsFromDBI> {
      return ajax('a/requisitions', {
        method: 'GET',
        data: {
          patientId,
          maxTestsAge,
          page,
          pageSize,
          sorting,
          showApprovalRecords,
          selectedTests,
          textFilter,
          showOnlyAbnormal
        }
      })
    },
    getRequisitionById: function (kitId: string): Promise<KitsFromDBI> {
      return ajax(`a/requisitions/${kitId}`, {
        method: 'GET'
      })
    },
    getNonLimsRequisitions: function ({
      startDate,
      endDate,
      status
    }: {
      startDate?: string
      endDate?: string
      status?: string[]
    }): Promise<{ data: any[], siteId: string }> {
      return ajax('a/non-lims-requisitions', {
        method: 'GET',
        data: {
          startDate,
          endDate,
          status
        }
      })
    },
    getPatient: function (data: { id: string }): Promise<usePatientHookResponseI> {
      return ajax(`a/patient/${data.id}`, {
        method: 'GET'
      })
    },
    getPatients: function (queryString: any = {}): Promise<{
      headers: string,
      patients: PatientI[]
      pagination: {
        count: number
        offset: number
        limit: number
      }
    }> {
      const url = appendQueryString('a/patients', { ...queryString })

      return ajax(url, {
        method: 'GET'
      })
    },
    getPatientByLims: function (data: { id: string }): Promise<{
      patient: PatientI
      error?: string
    }> {
      return ajax(`a/patient-by-lims/${data.id}`, {
        method: 'GET'
      })
    },
    triggerReqSyncCron: function () {
      return ajax('a/trigger-req-sync-cron', { method: 'GET' })
    },
    triggerKitTracking: function () {
      return ajax('a/trigger-kit-tracking', { method: 'GET' })
    },
    triggerUSSLCron: function () {
      return ajax('a/trigger-ussl-cron', { method: 'GET' })
    },
    triggerNotificationsCron: function () {
      return ajax('a/cron/notifications', { method: 'GET' })
    },
    triggerReqDeletionCron: function () {
      return ajax('a/trigger-req-deletion-cron', { method: 'GET' })
    },
    triggerMailerCron: function (): Promise<{ success: boolean }> {
      return ajax('a/trigger-mailer-cron', { method: 'GET' })
    },
    clearDBCache: function () {
      return ajax('a/clear-db-cache', { method: 'GET' })
    },
    fetchSitesPatients: function (data: { sitecode: string }): Promise<{ patients: PatientFromLimsI[] }> {
      return ajax('a/get-sites-patients', { method: 'POST', data })
    },
    fetchSitesAllPatients: function (data: { siteids: number[] }): Promise<{ patients: PatientFromLimsI[] }> {
      return ajax('a/get-sites-all-patients', { method: 'POST', data })
    },
    checkKit: function (data: { kitcode: string, sitecode: string }) {
      return ajax('a/check-kit', {
        method: 'GET',
        data: { kitcode: data.kitcode, sitecode: data.sitecode }
      })
    },
    registerPatientToNewSite: function (data: any) {
      return ajax('a/duplicate-patient', { method: 'POST', data })
    },
    updateKit: function (data: any) {
      return ajax('a/update-kit', { method: 'PATCH', data })
    },
    deleteKit: function (data: any) {
      return ajax('a/delete-kit', { method: 'POST', data })
    },
    syncSites: function () {
      return ajax('a/sync-sites', { method: 'GET' })
    },
    getSites: function () {
      return ajax('a/sites', { method: 'GET' }) as Promise<SiteI[]>
    },
    getAllGrafanaDashboards: function () {
      return ajax('a/get-all-grafana-dashboards', { method: 'GET' }) as Promise<{
        success: boolean,
        error: boolean,
        data: GrafanaDashboardI[]
      }>
    },
    getAllPromotionalWidgets: function () {
      return ajax('a/promotional-widgets', { method: 'GET' }) as Promise<PromotionalWidgetI[]>
    },
    getResultDetails: function (id: string, queryString: string = '') {
      return ajax(`a/result-details/${id}?${queryString}`, {
        data: { id },
        method: 'GET'
      }) as Promise<{ data: SampleI[], token: string }>
    },
    getResultsDetails: function (
      ids: string[]
    ): Promise<ResponseGetResultsDetails> {
      return ajax('a/results-details', {
        data: { ids },
        method: 'GET'
      })
    },
    newDownloadResult: function (kitcode: string): Promise<DownloadResultResp> {
      return ajax(`a/new-download-result/${kitcode}`, {
        method: 'GET'
      })
    },
    fetchQuestions: function (id: string) {
      return ajax(`a/fetch-questions/${id}`, {
        method: 'GET'
      })
    },
    accessSites: function (params: string = ''): Promise<SiteI[]> {
      return ajax(`a/access-sites${params}`, { method: 'GET' })
    },
    updateApprovalSites: function (id: string, data: any) {
      return ajax(`a/site/${id}/approval-sites`, {
        method: 'PATCH',
        data
      })
    },
    updateKitPaymentOption: function ({ siteId, kitType, ...data }: { siteId: string, kitType: string }) {
      return ajax(`a/site/${siteId}/payment-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateKitAftercareConfig: function ({ siteId, kitType, ...data }: { siteId: string, kitType: string }) {
      return ajax(`a/site/${siteId}/aftercare-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateKitAgeConfig: function ({ siteId, kitType, ...data }: { siteId: string, kitType: string }) {
      return ajax(`a/site/${siteId}/age-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateKitVideoTutorialConfig: function ({ siteId, kitType, ...data }: { siteId: string, kitType: string }) {
      return ajax(`a/site/${siteId}/video-tutorial-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateProductLinkConfig: function ({ siteId, kitType, ...data }: { siteId: string, kitType: string }) {
      return ajax(`a/site/${siteId}/product-link-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateOutreachConfig: function ({ siteId, kitType, ...data }: { siteId: number, kitType: string }): Promise<SiteI> {
      return ajax(`a/site/${siteId}/outreach-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateAftercareLockConfig: function ({ siteId, kitType, ...data }: { siteId: string, kitType: string, isWorkingWithDoctorEditable: boolean }): Promise<SiteI> {
      return ajax(`a/site/${siteId}/update-aftercare-lock-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateApprovalLockConfig: function ({ siteId, kitType, ...data }: { siteId: string, kitType: string }) {
      return ajax(`a/site/${siteId}/update-approval-lock-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateKitsOnHoldStatus: function (ids: string[]) {
      return ajax('a/update-kits-on-hold', { method: 'PATCH', data: { ids } })
    },
    fetchRoles: function () {
      return ajax('a/fetch-roles', { method: 'GET' })
    },
    inviteUser: function (data: any) {
      return ajax('a/invite-user', { method: 'POST', data })
    },
    getApiAuditLogs: function ({
      limit = 10,
      offset = 0,
      date = moment().format('l')
    }: { limit: number, offset: number, date?: string }): Promise<{
      rows: APIAuditLogI[];
      count: number;
    }> {
      return ajax(`a/api-audit-logs?limit=${limit}&offset=${offset}&date=${date}`, { method: 'GET' })
    },
    registerPatient: function (data: Record<string, any>) {
      return ajax('a/register-patient', { method: 'POST', data })
    },
    validateSite: function (sitecode: string) {
      return ajax(`a/validate-site/${sitecode}`, {
        method: 'GET',
        data: { sitecode }
      })
    },
    resendNotification: function (data: any): Promise<{ success: boolean }> {
      return ajax('a/resend-notification', { method: 'POST', data })
    },
    resendBulkNotification: function (data: any) {
      return ajax('a/resend-bulk-notifications', { method: 'POST', data })
    },
    forceSyncKit: function (data: any) {
      return ajax('a/force-sync-kit', { method: 'POST', data })
    },
    linkLimsPatient: function (data: any) {
      return ajax('a/link-lims-patient', { method: 'POST', data })
    },
    askPatientForUpdate: function (data: any) {
      return ajax('a/ask-patient-for-update', { method: 'POST', data })
    },
    approveAndNotifyTests: function (data: { kitCodes: string[] }): Promise<{ success: boolean }> {
      return ajax('a/approve-notify', { method: 'POST', data })
    },
    toggleSiteCodeVisibility: function (data: any) {
      return ajax('a/toggle-sitecode-visibility', { method: 'POST', data })
    },
    updatePatientNotes: function ({ patientId, ...data }: { patientId: number, provider_notes: RawDraftContentState }) {
      return ajax(`a/patient/${patientId}/notes`, { method: 'PATCH', data })
    },
    updateSiteNotes: function ({ siteId, ...data }: { siteId: string }) {
      return ajax(`a/site/${siteId}/notes`, { method: 'PATCH', data })
    },
    updateKitNotes: function ({ kitId, ...data }: { kitId: string }) {
      return ajax(`a/kit/${kitId}/notes`, { method: 'PATCH', data })
    },
    updatePatientInsurance: function (data: any) {
      return ajax('a/update-patient-insurance', { method: 'POST', data })
    },
    fetchUsersWithRole: function (siteId: string) {
      return ajax(`a/users-with-role?site=${siteId}`, { method: 'GET' })
    },
    inviteUserToSite: function (data: any) {
      return ajax('a/invite-user-to-site', {
        method: 'POST',
        data
      })
    },
    fetchEmailFromToken: function (token: string) {
      return ajax(`a/fetch-email?token=${token}`, {
        method: 'GET'
      })
    },
    removeFromSite: function (data: any) {
      return ajax('a/remove-from-site', { method: 'POST', data })
    },
    updatePatientDetails: function (data: any) {
      return ajax('a/update-patient-details', {
        data,
        method: 'PATCH'
      })
    },
    downloadResultExternal: function ({ id, ...data }: any): Promise<DownloadResultResp> {
      return ajax(`a/external/download-result/${id}`, {
        data,
        method: 'POST'
      })
    },
    sendPaymentLink: function (data: any) {
      return ajax('a/send-payment-link', {
        data,
        method: 'POST'
      })
    },
    checkKitStatus: function (code: string) {
      return ajax(`a/kits/status/${code}`, { method: 'GET' })
    },
    getConsultsAssociatedToSiteCodes: function ({
      filterModel,
      searchTerm,
      page,
      pageSize,
      sortModel,
      exportData = false
    }: GetConsultsParams) {
      const consultStatusFilter = filterModel?.items?.find(item => item.field === 'consult_status')
      if (consultStatusFilter?.value) {
        const consultStatusFilterValue = consultStatusFilter?.value
        let status
        if (consultStatusFilterValue === 'Picked Up') status = ['picked_up']
        else if (consultStatusFilterValue === 'Cancelled') status = ['cancelled']
        else if (consultStatusFilterValue === 'In progress') status = ['unassigned', 'notify_rx', 'ordered_rx']
        else if (Array.isArray(consultStatusFilterValue)) status = consultStatusFilterValue
        else status = [consultStatusFilterValue]
        consultStatusFilter.value = status
      }

      const data = {
        filterModel,
        searchTerm,
        page,
        pageSize,
        sortModel,
        exportData
      }

      return ajax('a/consults', {
        method: 'POST',
        data
      })
    },
    getAnalyteNames: function (): Promise<{ name: string }[]> {
      return ajax('a/analytes/names', { method: 'GET' })
    },
    getActionsLog: function (data: any) {
      return ajax('a/user-actions', { method: 'POST', data })
    },
    getEventReports: function (data: any) {
      return ajax('a/event-reports', { method: 'POST', data })
    },
    updateOverrideScreeningQuestions: function (siteId: string, data: SiteI['override_screening_questions']): Promise<{ sucess: true, site: SiteI }> {
      return ajax(`a/site/${siteId}/override-screening-questions`, {
        method: 'PATCH',
        data
      })
    },
    updateNyTestConfig: function (siteId: number, data: { nyTestConfig: SiteI['ny_test_config'] }): Promise<{ sucess: true, site: SiteI }> {
      return ajax(`a/site/${siteId}/ny-test-config`, {
        method: 'PATCH',
        data
      })
    },
    copyConfig: function (data: any): Promise<{
      success: boolean
      message: string
      error?: string
    }> {
      return ajax('a/copy-config', { method: 'POST', data })
    },
    exportRegistrationData: function (data: { kitCodes: string[] }): Promise<{
      data: RegistrationDataReportI[]
    }> {
      return ajax('a/export-registration-data', { method: 'POST', data })
    },
    resetPwWidget: function (data: any) {
      return ajax('a/temp-pw', { method: 'POST', data })
    },
    removeHPNotifications: function (data: any) {
      return ajax('a/remove-hp-notifications', { method: 'POST', data })
    },
    postResultsToSftp: function (data: SftpSettingsFormI): Promise<{
      success: boolean,
      data: {
        kitCount: number
      }
    }> {
      return ajax('a/post-results-to-sftp', { method: 'POST', data })
    },
    getAllSftpResultHistory: function (): Promise<{
      success: boolean,
      data: {
        id: number;
        completed_at: null | string;
        status: string;
        site_name: string;
        upload_location: string;
      }[]
      err?: string
    }> {
      return ajax('a/get-results-upload-sftp', {
        method: 'GET'
      })
    },
    populateLimsDataToDb: function () {
      return ajax('a/populate-lims-data-db', {
        method: 'GET'
      })
    }
  },
  kitTypeConfig: {
    create: function (data: any) {
      return ajax('a/kit-type-config/', { method: 'POST', data })
    },
    getAll: function (translate: boolean): Promise<KitTypeConfigI[]> {
      return ajax(`a/kit-type-config?translate=${translate}`, { method: 'GET' })
    },
    getAllIdentifiers: function (): Promise<Pick<KitTypeConfigI, 'identifier' | 'id'>[]> {
      return ajax('a/kit-type-config/get-all-identifiers', { method: 'GET' })
    },
    getKitTypesInfo: function (): Promise<{
      kitTypeConfigs: KitTypeConfigI[],
      translation: KitTypeConfigI[]
    }> {
      return ajax('a/kit-type-config/info', { method: 'GET' })
    },
    getById: function (id: string, translate: boolean = false): Promise<KitTypeConfigI> {
      return ajax(`a/kit-type-config/${id}?translate=${translate}`, { method: 'GET' })
    },
    update: function (data: any) {
      return ajax(`a/kit-type-config/${data.id}`, { method: 'PATCH', data })
    },
    remove: function (id: number) {
      return ajax(`a/kit-type-config/${id}`, { method: 'DELETE' })
    }
  },
  portalAuth: {
    activateMfa: function (data: any, token: string): Promise<{
      success: boolean,
      message: string
    }> {
      return ajax(`portal-auth/activate-mfa/${token}`, {
        data,
        method: 'POST'
      })
    },
    signup: function (data: any): Promise<{
      success: boolean, user: AuthUser
    }> {
      return ajax('portal-auth/register', {
        data,
        method: 'POST'
      })
    },
    send2FaOtp: function (data: any): Promise<{
      success: boolean
      token?: string
    }> {
      return ajax('portal-auth/send-2fa-otp', {
        data,
        method: 'POST'
      })
    },
    login: function (data: any): Promise<RefreshApiResponse> {
      return ajax('portal-auth/login', {
        data,
        method: 'POST'
      })
    },
    grafanaToken: function (): Promise<{
      success: boolean,
      accessToken: string,
      expiresIn: string
    }> {
      return ajax('portal-auth/grafana-token', {
        method: 'POST'
      })
    },
    refresh: function (): Promise<RefreshApiResponse> {
      return ajax('portal-auth/refresh', {
        method: 'POST'
      })
    },
    logout: function (data: any) {
      return ajax('portal-auth/logout', {
        method: 'POST',
        data,
        credentials: 'same-origin'
      })
    },
    emailVerify: function (token: string) {
      return ajax(`portal-auth/email-verify/${token}`, {
        method: 'GET'
      })
    },
    checkOtpToken: function (token: string): Promise<{
      success: boolean,
      message: string
    }> {
      return ajax(`portal-auth/verify-opt-token/${token}`, {
        method: 'GET'
      })
    },
    checkOtp: function (token: string, data: any): Promise<{
      success: boolean,
      message: string,
      error?: string
    }> {
      return ajax(`portal-auth/verify-opt/${token}`, {
        method: 'POST',
        data
      })
    },
    setRememberDevice: function (data: any): Promise<{ success: boolean, data: string }> {
      return ajax('portal-auth/set-remember-device', {
        data,
        method: 'POST'
      })
    },
    getRememberDevice: function (data: any): Promise<{ success: boolean, message: string }> {
      return ajax('portal-auth/get-remember-device', {
        data,
        method: 'POST'
      })
    },
    setCookie: function (query: any) {
      const url = appendQueryString('portal-auth/set-cookie', query)
      return ajax(url, { method: 'GET' })
    },
    checkDomain: function (query: any = {}) {
      const url = appendQueryString('portal-auth/login-check', query)
      return ajax(url, { method: 'GET' })
    }
  },
  auth: {
    getSubdomain: function (email: string) {
      return ajax(`auth/get-subdomain/${email}`, {
        method: 'GET'
      })
    },
    sendResetPasswordMail: function (data: any) {
      return ajax('auth/send-reset-password-mail', {
        data,
        method: 'POST'
      })
    },
    sendQuery: function (data: any) {
      return ajax('auth/sendQuery', { method: 'POST', data })
    },
    sendFeedback: function (data: any) {
      return ajax('auth/sendFeedback', { method: 'POST', data })
    },
    sendVerificationMailLink: function (data: any): Promise<{
      success: boolean
    }> {
      return ajax('auth/send-verification-mail-link', {
        data,
        method: 'POST'
      })
    },
    updateProfile: function (data: UpdateProfileData): Promise<AuthUser> {
      return ajax('auth/profile', { method: 'PATCH', data, dispatch: false })
    },
    checkTicket: function (ticket: string) {
      return ajax(`auth/check-ticket/${ticket}`, { method: 'GET' })
    },
    changePassword: function (data: any) {
      return ajax('auth/change-password', { method: 'PATCH', data })
    },
    checkAccountExists: function (data: any): Promise<{
      success: boolean,
      isPortalUser: boolean,
      email_verified: boolean,
      mfa_enabled: boolean,
      mfa_method: boolean,
      phone: boolean
    }> {
      return ajax('auth/check', { method: 'POST', data })
    },
    verifyUser: function (data: any) {
      return ajax('auth/verify-user', { method: 'PATCH', data })
    },
    sendUpdateEmailLink: function (data: any) {
      return ajax('auth/send-update-email-link', { method: 'POST', data })
    },
    validateUpdateEmailToken: function (data: any) {
      return ajax('auth/validate-update-email-token', { method: 'POST', data })
    },
    checkUpdateEmailTokenValid: function (token: string) {
      return ajax(`auth/check-update-email-token/${token}`, { method: 'GET' })
    },
    updateTncData: function () {
      return ajax('auth/legal-info', { method: 'PUT' })
    },
    validateMagicLink: function ({ token, key }: { token: string, key: string }) {
      return ajax(
        `auth/signup-verify/${token}/${key}`,
        { method: 'GET' }
      ) as Promise<{
        success: boolean,
        action: string,
        data?: object
      }>
    },
    signup: function (data: any) {
      return ajax('auth/signup', {
        data,
        method: 'POST'
      })
    }
  },
  patient: {
    getAll: function () {
      return ajax('patient', { dispatch: 'patients' }) as Promise<PatientI[]>
    },
    getPatient: function (data: any): Promise<{ patient: PatientI, siteCode: string }> {
      return ajax('patient/getPatient', { method: 'POST', data })
    },
    checkSync: function () {
      return ajax('patient/sync', { method: 'GET' })
    },
    updatePatient: function (data: any) {
      return ajax('patient/profile/update', {
        data,
        method: 'PATCH',
        dispatch: 'patients',
        type: 'update'
      })
    },
    register: function (data: any) {
      return ajax('patient/register', { data, method: 'POST' })
    },
    registerToDifferentSite: function (data: any) {
      return ajax('patient/registerToDifferentSite', { data, method: 'POST' })
    },
    registerPatientToNewSite: function (data: any): Promise<{ patientId: string }> {
      return ajax('patient/duplicate-patient', { data, method: 'POST' })
    },
    getProfileDetails: function (data: any) {
      return ajax('patient/profile/getProfileDetails', { data, method: 'POST' })
    },
    streetSearch: function (data: any) {
      return ajax('patient/smarty', {
        data,
        method: 'POST'
      })
    },
    addPatient: function (data: any): Promise<PatientI> {
      return ajax('patient/addPatient', { data, method: 'POST' })
    },
    updateInsurance: function (data: any) {
      return ajax('patient/updateInsurance', { data, method: 'PATCH' })
    },
    validateUpdateRequestToken: function (tokenCache: string) {
      return ajax(`patient/validate-update-request-token/${tokenCache}`, {
        method: 'GET'
      })
    },
    validateTokenCache: function (data: any) {
      return ajax('patient/update-cache-token', { data, method: 'POST' })
    },
    sendLimsVerificationMail: function (data: any): Promise<{ success: boolean }> {
      return ajax('patient/lims-email-verification', { data, method: 'POST' })
    },
    verifyLimsEmail: function (token: string) {
      return ajax(`patient/lims-email-verification/${token}`, {
        method: 'PATCH'
      })
    },
    getLimsEmailVerifiedDetails: function (token: string): Promise<{ email_verified: boolean }> {
      return ajax(`patient/lims-email-verification/${token}`, { method: 'GET' })
    },
    linkPatientsToAccount: function () {
      return ajax('patient/link', { method: 'GET' })
    },
    checkInLims: function () {
      return ajax('patient/check-in-lims', { method: 'GET' })
    }
  },
  kit: {
    getUserLatestKit: function (): Promise<Kit> {
      return ajax('kit/get-user-latest-kit', {
        method: 'GET'
      })
    },
    getAll: function (): Promise<Kit[]> {
      return ajax('kit/search', {
        method: 'POST',
        dispatch: 'kits',
        deleteFirst: true
      })
    },
    getKitsByPatient: function (patientId: string | number, queryString: string = ''): Promise<{ kits: PartialKitWithSample[] }> {
      return ajax(`kit/by-patient/${patientId}${queryString.length ? '?' + queryString : ''}`, { method: 'GET' })
    },
    check: function (
      code: string,
      mock: boolean = false,
      provider: boolean = false
    ): Promise<KitCheckResponse> {
      return ajax('kit/check', { data: { code, mock, provider } })
    },
    saveKitAction: function (code: string, pageInfo: string, additionalInfo: any = {}) {
      return ajax(`kit/save-kit-action/${code}`, {
        method: 'POST',
        data: { pageInfo, additionalInfo }
      })
    },
    register: function (code: string, { requestType = '', siteName = '' }: any = {}) {
      return ajax('kit/register', {
        method: 'POST',
        data: { code, requestType, siteName },
        dispatch: 'kits'
      })
    },
    update: function (id: string | number, data: any): Promise<Kit> {
      return ajax(`kit/${id}`, {
        data,
        method: 'PATCH',
        dispatch: 'kits'
      })
    },
    generateKitCodes: function (data: any) {
      return ajax('kit/a/add', {
        data,
        method: 'POST'
      })
    },
    getResultDetails: function (id: string, token: string): Promise<SampleI[]> {
      return ajax(`kit/result-details/${id}`, {
        data: { id, token },
        method: 'GET'
      })
    },
    getResultDetailsAuth: function (id: string): Promise<SampleI[]> {
      return ajax(`kit/get-result-auth/${id}`, {
        data: { id },
        method: 'GET'
      })
    },
    getResult: function (data: any): Promise<KitFromLimsForResult> {
      return ajax('kit/get-result', {
        data,
        method: 'POST'
      })
    },
    checkExternalResultView: function (kitCode: string): Promise<{ needsLogin: boolean, memberIdRequired?: boolean }> {
      return ajax(`kit/check-external-result/${kitCode}`, {
        method: 'GET'
      })
    },
    downloadResult: function (id: string | number): Promise<DownloadResultResp> {
      return ajax(`kit/download-result/${id}`, {
        data: { id },
        method: 'GET'
      })
    },
    downloadResultReporting: function (id: string): Promise<string> {
      return ajax(`kit/download-result-reporting/${id}`, {
        data: { id },
        method: 'GET'
      })
    },
    deleteKit: function (data: any) {
      return ajax('kit/delete', {
        data,
        method: 'POST',
        dispatch: 'kits',
        type: 'remove'
      })
    },
    getInsurance: function (siteCode: string, kitTypeConfigId: string): Promise<{ insurance: boolean }> {
      return ajax(
        `kit/insurance/${siteCode?.toUpperCase()}/${kitTypeConfigId}`,
        {
          method: 'GET'
        }
      )
    },
    getKit: function (kitCode: string | number): Promise<{ kit: Kit }> {
      return ajax(`kit/${kitCode}`, { method: 'GET' })
    },
    shareResultWithProvider: function ({ id, ...data }: { id: string, name: string, email: string }) {
      return ajax(`kit/${id}/share-result-with-provider`, {
        method: 'POST',
        data
      })
    },
    sendRetestNotification: function (data: any) {
      return ajax('kit/send-retest-notification', { method: 'POST', data })
    },
    getDiabetesTrend: function (kitcode: string): Promise<{
      ok: boolean,
      content: {
        title: string
        description: string
        color: string
        meaning: string | null
      },
      trend: Array<{
        Hb1Ac: number,
        name: string
      }>
    }> {
      return ajax(`kit/diabetes-trend/${kitcode}`, { method: 'GET' })
    },
    isLatestKit: function (kitId: string) {
      return ajax(`kit/${kitId}/is-latest`, { method: 'GET' })
    },
    getSearchOrders: function (): Promise<ProgramKitI[]> {
      return ajax('kit/search-orders', { method: 'POST' })
    },
    getPatientDetailsFromKit: function (kitcode: string) {
      return ajax(`kit/reporting/${kitcode}`, {
        method: 'GET'
      })
    },
    isFaultyKit: function (sampleId: string): Promise<{ isMislabeled: boolean, isShippedBadKit: boolean }> {
      return ajax(`kit/faulty/${sampleId}`, { method: 'GET' })
    },
    runReport: function (data: any): Promise<{ success: boolean, data: NinjaLabReportRunner[] | NinjaLabCliaAudit[] }> {
      return ajax('kit/run-report', { method: 'POST', data })
    },
    adminRegisterKit: function (data: {
      collected_at: string
      kitcode: string
      patient_id: string
      site_code: string
    }) {
      return ajax('kit/admin-register-kit', { method: 'POST', data })
    },
    autoCreateReq: function (kitcode: string): Promise<{
      success: boolean,
      message: string
      error?: string
    }> {
      return ajax(`kit/create-hp-req/${kitcode}`, {
        method: 'GET'
      })
    },
    generateSampleIDs: function (data: any): Promise<{ success: boolean, data: string[], error?: string }> {
      return ajax('kit/generate-sample-ids', { method: 'POST', data })
    },
    uploadGeneratedSampleIDs: function (data: any): Promise<{
      success: boolean,
      message?: string,
      error?: string
    }> {
      return ajax('kit/upload-generated-ids', { method: 'POST', data })
    }
  },
  whitelistNumber: {
    getAllSmsLogs: function (): Promise<{ data: DevSmsLogsI[] }> {
      return ajax('whitelist-number/get-all-sms-logs', {
        method: 'GET'
      })
    },
    getAll: function (): Promise<{ data: WhiteListNumberI[] }> {
      return ajax('whitelist-number/get-all', {
        method: 'GET'
      })
    },
    create: function (data: any) {
      return ajax('whitelist-number/create', {
        data,
        method: 'POST'
      })
    },
    delete: function (id: string) {
      return ajax(`whitelist-number/delete/${id}`, {
        method: 'DELETE'
      })
    }
  },
  content: {
    getByKey: function (key: string): Promise<ContentI> {
      return ajax(`content/${key}`, { method: 'GET' })
    },
    updateByKey: function (key: string, data: any) {
      return ajax(`content/${key}`, { method: 'PATCH', data })
    },
    createOrUpdateByKey: function (key: string, data: any) {
      return ajax(`content/${key}`, { method: 'PUT', data })
    },
    get: function () {
      return ajax('content', { dispatch: 'content' })
    },
    update: function (data: any) {
      return ajax('content', {
        data,
        method: 'PATCH'
      })
    },
    validateSite: function (code: string) {
      return ajax('content/validate-site', { data: { code } })
    },
    getUser: function (): Promise<{
      signature: string,
      user: {
        firstName: string,
        lastName: string,
        email: string,
        language: string
      }
    }> {
      return ajax('content/user', {
        dispatch: 'user'
      })
    }
  },
  ping: {
    checkConnection: function () {
      return ajax('ping', {})
    }
  },
  proxy: {
    getInsuranceProviders: function () {
      return ajax('proxy/insurance_providers', {})
    },
    hasAppointmentOccured: function (data: any): Promise<any> {
      return ajax('proxy/has_appointment_occured', { method: 'POST', data })
    },
    getSites: function (data: any) {
      return ajax('proxy/get_sites', { method: 'POST', data })
    }
  },
  consult: {
    checkEligibility: function (data: any) {
      return ajax('consult/check-eligibility', {
        method: 'POST',
        data
      })
    },
    createConsultData: function (data: any) {
      return ajax('consult/create-data', {
        method: 'POST',
        data
      })
    },
    updateConsultData: function (id: string, data: any) {
      return ajax(`consult/${id}`, {
        method: 'PATCH',
        data
      })
    },
    getConsultData: function (id: any): Promise<ConsultI> {
      return ajax(`consult/${id}`, {
        method: 'GET'
      })
    },
    createConsult: function (data: any) {
      return ajax('consult/create-consult/', {
        method: 'POST',
        data
      })
    },
    getConsults: function (
      page: string | number = '1',
      limit: string | number = '10',
      queryString?: {
        patientId: string
        kitTypeConfigId: string
      }
    ): Promise<ConsultsResponse> {
      const url = appendQueryString('consult', { page: `${page}`, limit: `${limit}`, ...queryString })
      return ajax(url, { method: 'GET' })
    },
    getConsultsByKit: function (kitId: string): Promise<ConsultI[]> {
      return ajax(`consult/kit/${kitId}`, { method: 'GET' })
    },
    markConsultAsPickedUp: function (id: string) {
      return ajax(`consult/mark-picked/${id}`, { method: 'GET' })
    },
    getPharmacies: function (
      consultId: string,
      zipCode: string
    ): Promise<Pharmacy[]> {
      return ajax(`consult/${consultId}/pharmacies?zip=${zipCode}`, {
        method: 'GET'
      })
    },
    checkProviderResponse: function () {
      return ajax('consult/check-provider-response', { method: 'GET' })
    },
    checkProviderResponseForConsult: function (consultCode: string) {
      return ajax(`consult/check-provider-response/${consultCode}`, {
        method: 'GET'
      })
    },
    getConsultFromFeedbackToken: function (token: string) {
      return ajax(`consult/feedback/${token}`, { method: 'GET' })
    },
    getConsultFromId: function (id: string) {
      return ajax(`consult/id/${id}`, {
        method: 'GET'
      })
    },
    getAvailableConsultDays: function (data: AvailibilityDaysAPI): Promise<any> {
      return ajax('consult/get-available-days', {
        method: 'POST',
        data
      })
    },
    getAvailableConsultSlots: function (data: AvailibilitySlotsAPI): Promise<any> {
      return ajax('consult/get-available-slots', {
        method: 'POST',
        data
      })
    },
    completeConsultAppointmentCheckout: function (data: CompleteConsultCheckoutAPI) {
      return ajax('consult/complete-consult-appointment-checkout', {
        method: 'POST',
        data
      })
    },
    rescheduleConsultAppointmentCheckout: function (data: CompleteConsultCheckoutAPI) {
      return ajax(`consult/reschedule-consult-appointment/${data.mwl_consult_id}/${data.ol_appointment_id || 'any'}`, {
        method: 'PATCH',
        data
      })
    },
    rescheduleCareCoachingAppointmentCheckout: function (data: CompleteConsultCheckoutAPI) {
      return ajax(`consult/reschedule-care-appointment/${data.mwl_consult_id}/${data.ol_appointment_id}`, {
        method: 'PATCH',
        data
      })
    },
    completeCareCoachingAppointmentCheckout: function (data: CompleteCareCoachingCheckoutAPI) {
      return ajax('consult/complete-care-coaching-appointment-checkout', {
        method: 'POST',
        data
      })
    },
    sendConsultFormData: function (data: SendConsultFormAPI) {
      return ajax('consult/send-consult-form', {
        method: 'POST',
        data
      })
    },
    cancelConsultAppointment: function (data: CancelAppointmentAPI) {
      return ajax(`consult/cancel-consult-appointment/${data.mwl_consult_id}/${data.ol_appointment_id}`, {
        method: 'POST',
        data
      })
    },
    cancelCareAppointment: function (data: CancelAppointmentAPI) {
      return ajax(`consult/cancel-care-appointment/${data.mwl_consult_id}/${data.ol_appointment_id}`, {
        method: 'POST',
        data
      })
    }
  },
  square: {
    processPayment: function (data: any) {
      return ajax('square/payments', { method: 'POST', data })
    },
    getPayment: function (data: any) {
      return ajax('square/payment', { method: 'GET', data })
    }
  },
  manage: {
    getPatients: function (queryStringObject = {}) {
      const url = appendQueryString('manage/patients', queryStringObject)
      return ajax(url, { method: 'GET' })
    },
    getPatient: function (patientId: string) {
      const url = `manage/patients/${patientId}`
      return ajax(url, { method: 'GET' })
    },
    getRequisitions: function (queryStringObject = {}) {
      const url = appendQueryString('manage/requisitions', queryStringObject)
      return ajax(url, { method: 'GET' })
    },
    getSites: function (): Promise<SiteI[]> {
      return ajax('manage/sites', { method: 'GET' })
    },
    getOutreachConsults: function (queryStringObject = {}): Promise<useOutreachResponseI> {
      return ajax('manage/outreach-visits', {
        method: 'GET',
        data: queryStringObject
      })
    },
    setOutreachConsultsResolved: function (data: any) {
      return ajax('manage/outreach-visits/resolve', { method: 'POST', data })
    },
    getMessages: function (): Promise<MessageLogI[]> {
      return ajax('manage/messages', { method: 'GET' })
    },
    setMessagesResolved: function (data: any): Promise<{ status: boolean }> {
      return ajax('manage/messages/resolve', { method: 'POST', data })
    },
    getPatientByPhone: function (phone: string): Promise<PatientI[]> {
      return ajax(`manage/patients/check-phone/${phone}`, { method: 'GET' })
    }
  },
  feedback: {
    createFeedback: function (data: any): Promise<FeedbackI> {
      return ajax('feedback', { method: 'POST', data })
    },
    createFeedbackLoggedOut: function (token: string, data: any) {
      return ajax(`feedback/nps/create/${token}`, { method: 'POST', data })
    },
    updateFeedback: function (data: any): Promise<FeedbackI> {
      const { id, ...feedback } = data
      return ajax(`feedback/${id}`, { method: 'PATCH', data: feedback })
    },
    getAll: function (query: any): Promise<{
      data: FeedbackI[],
      pagination: { count: number, limit: number, offset: number }
    }> {
      const url = appendQueryString('feedback', query)
      return ajax(url, { method: 'GET' })
    },
    getCheckToken: function (token: string, query: any = {}) {
      const url = appendQueryString(`feedback/nps/check-token/${token}`, query)
      return ajax(url, { method: 'GET' })
    },
    getCheckHashToken: function (token: string, query: any = {}) {
      const url = appendQueryString(`feedback/check-hash/${token}`, query)
      return ajax(url, { method: 'GET' })
    },
    unsubscribeKitNotification: function (token: string) {
      return ajax(`feedback/unsubscribe-kit-remainder/${token}`, { method: 'GET' })
    },
    optOutFTP: function (token: string) {
      return ajax(`feedback/opt-out/${token}`, { method: 'GET' })
    },
    getProductLink: function (code: string): Promise<{ productUrl: string | null, siteId?: string, skuId?: string, featureId?: string }> {
      return ajax(`feedback/review-link/${code}`, { method: 'GET' })
    },
    createAfterConsultFeedbacks: function (token: string, data: any) {
      return ajax(`feedback/after-consult/${token}`, { method: 'POST', data })
    }
  },
  site: {
    getSiteBrand: function (domain: string, translate = true): Promise<SiteI['white_label_config']> {
      return ajax(`site/brand/${domain}?translate=${translate}`, { method: 'GET' })
    },
    getSites: function (data: any): Promise<SiteI[]> {
      return ajax('site/get-sites', { method: 'POST', data })
    },
    getSite: function (id: string | number): Promise<SiteI> {
      return ajax(`site/get-site/${id}`, { method: 'GET' })
    },
    updateSite: function (
      siteId: string,
      data: Partial<SiteI>
    ): Promise<SiteI> {
      return ajax(`site/${siteId}`, { method: 'PATCH', data })
    },
    updateSitePromotion: function (
      siteId: string,
      data: number[]
    ) {
      return ajax(`site/update-site-promotion/${siteId}`, { method: 'PATCH', data })
    },
    getPromoWidgets: function (): Promise<Pick<ProgramI, 'id' | 'type' | 'config'>[]> {
      return ajax('site/get-promotion-widgets', { method: 'GET' })
    },
    getDoctors: function (siteId: string): Promise<DoctorI[]> {
      return ajax(`site/${siteId}/doctors`, { method: 'GET' })
    },
    assignRole: function (data: any) {
      return ajax('site/assign-role', { method: 'POST', data })
    },
    getSiteStats: function () {
      return ajax('site/get-site-stats', { method: 'GET' })
    },
    toggleReceiveNotification: function (data: any) {
      return ajax('site/toggle-receive-positive-notifications', {
        method: 'POST',
        data
      })
    },
    updateCustomFieldConfig: function ({ id, ...data }: { id: string }) {
      return ajax(`site/${id}/custom-field`, { method: 'PATCH', data })
    },
    getSiteFromCode: function (code: string): Promise<{ data: SiteWithRetailI }> {
      return ajax(`site/code/${code}`, { method: 'GET' })
    },
    getSiteAftercare: function (siteId: string): Promise<{ aftercare_config: SiteI['aftercare_config'] }> {
      return ajax(`site/${siteId}/aftercare`, { method: 'GET' })
    },
    updateAutomatedReportsConfig: function (siteId: string, data: ReportingConfigForm) {
      return ajax(`site/automated-reports/${siteId}`, { method: 'PATCH', data })
    },
    updateNotifyProviderForAbnormalResults: function (siteId: string, data: ReportingConfigForm) {
      return ajax(`site/update-notify-provider-config/${siteId}`, { method: 'PATCH', data })
    }
  },
  lab: {
    getAllPlates: function () {
      return ajax('lab/getAllPlates', {
        method: 'GET'
      })
    },
    uploadPlates: function (data: any) {
      return ajax('lab/uploadPlates', {
        method: 'POST',
        data: {
          plates: data
        }
      })
    },
    exportResultHash: function (plateId: string) {
      return ajax(`lab/export/${plateId}`, {
        method: 'GET'
      })
    },
    deletePlate: function (plateId: string) {
      return ajax(`lab/delete/${plateId}`, {
        method: 'GET'
      })
    },
    exportResult: function (hash: string) {
      return ajax(`lab/getFile/${hash}/csv`, {
        method: 'GET'
      })
    },
    getAllTrackID: function () {
      return ajax('lab/getalltrackId', {
        method: 'GET'
      })
    },
    reprocessResults: function (data: any) {
      return ajax('lab/reprocess-results', {
        method: 'POST',
        data
      })
    }
  },
  order: {
    getAllOrders: function (paginationDetails = {}) {
      const url = appendQueryString('order/order-approvals', paginationDetails)
      return ajax(url, {
        method: 'GET'
      })
    },
    getAllOrdersWhitelabel: function (paginationDetails = {}) {
      const url = appendQueryString('order/order-approvals-whitelabel', paginationDetails)
      return ajax(url, {
        method: 'GET'
      })
    },
    getTrackingDetailsByKitId: function (queryStringObject = { kitId: '' }) {
      const url = appendQueryString('order/get-tracking-details-by-kitId', queryStringObject)
      return ajax(url, {
        method: 'GET'
      })
    },
    getAllOrdersMetrics: function () {
      return ajax('order/order-approvals-metrics', {
        method: 'GET'
      })
    },
    getAllOrdersQuery: function (queryStringObject = {}) {
      const url = appendQueryString('order/order-approvals', queryStringObject)
      return ajax(url, { method: 'GET' })
    },
    getSpecificOrder: function (data: { id: string, sample_id: string }): Promise<{ success: boolean, doesExist: boolean }> {
      return ajax(`order/order-detail/${data.id}/${data.sample_id}`, {
        method: 'GET'
      })
    },
    getRequester: function (id: string) {
      return ajax(`order/order-requester/${id}`, {
        method: 'GET'
      })
    },
    getOrderForSite: function (id: string) {
      return ajax(`order/provider-orders/${id}`, {
        method: 'GET'
      })
    },
    getOrdersForSites: function (data: any) {
      return ajax('order/provider-orders', {
        method: 'POST',
        data
      })
    },
    uploadOrder: function (data: any) {
      return ajax('order/upload-order', {
        method: 'POST',
        data
      })
    },
    sendToGBF: function (data: any) {
      return ajax('order/send-gbf', {
        method: 'POST',
        data
      })
    },
    approveOrders: function (data: any) {
      return ajax('order/approve-orders', {
        method: 'POST',
        data
      })
    },
    createOrders: function (data: any) {
      return ajax('order/create-orders', {
        method: 'POST',
        data
      })
    },
    insertRejection: function (data: any) {
      return ajax('order/insert-rejection', {
        method: 'POST',
        data
      })
    },
    getOrderById: function (id: string) {
      return ajax(`order/specify-order/${id}`, {
        method: 'GET'
      })
    },
    editOrder: function (data: any) {
      return ajax('order/edit-order', {
        method: 'PATCH',
        data
      })
    },
    cancelOrder: function (data: any) {
      return ajax('order/cancel-order', {
        method: 'PATCH',
        data
      })
    },
    cancelKitterOrder: function (data: any) {
      return ajax('order/cancel-kitter-order', {
        method: 'PATCH',
        data
      })
    },
    getSKUsForSite: function (id: string) {
      return ajax(`order/site-skus/${id}`, {
        method: 'GET'
      })
    },
    uploadKitOrders: function (data: any) {
      return ajax('order/upload-kit-orders', { method: 'POST', data })
    },
    getDistinctCohorts: function (): Promise<CohortI[]> {
      return ajax('order/get-distinct-cohorts', { method: 'GET' })
    },
    editAddress: function (data: any) {
      return ajax('order/edit-address', { method: 'PATCH', data })
    },
    getFulfillmentData: function (startDate: string, endDate: string): Promise<{ data: FulfillmentReportI[], success: boolean }> {
      return ajax('order/fulfillment-data', {
        method: 'GET',
        data: {
          startDate,
          endDate
        }
      })
    },
    getAllExternalIds: function (): Promise<{ data: string[] }> {
      return ajax('order/get-all-ext-ids', { method: 'GET' })
    },
    getOrdersData: (data: GetOrdersDataAPI): Promise<GetOrdersDataResponse> => {
      return ajax('order/orders-data', { method: 'POST', data })
    }
  },
  analytics: {
    getHealthFeedbackAnalytics: function (queryString = ''): Promise<HealthFeedbackAnalytics> {
      return ajax('analytics/health-feedback-analytics' + queryString, { method: 'GET' })
    },
    getAllAnalytics: function () {
      return ajax('analytics/all-analytics', { method: 'GET' })
    },
    getZipCodeData: function (data: any) {
      return ajax('analytics/get-zipcode-data', { method: 'POST', data })
    },
    getReportData: function (data: any): Promise<{ ok: boolean, analytics: AnalyticsReportI }> {
      return ajax('analytics/report', { method: 'POST', data })
    },
    getRegisterKitFlowSatisfaction: function (queryString = {}) {
      const url = appendQueryString(
        'analytics/register-kit-flow-satisfaction',
        queryString
      )
      return ajax(url, { method: 'GET' })
    },
    getDownloadOrShareResultUtilization: function (queryString = {}) {
      const url = appendQueryString(
        'analytics/download-share-result-utilization',
        queryString
      )
      return ajax(url, { method: 'GET' })
    },
    getTelehealthUtilization: function (queryString = {}) {
      const url = appendQueryString(
        'analytics/telehealth-utilization',
        queryString
      )
      return ajax(url, { method: 'GET' })
    },
    getTelehealthConsultsCount: function (queryString = {}) {
      const url = appendQueryString(
        'analytics/telehealth-consults-count',
        queryString
      )
      return ajax(url, { method: 'GET' })
    },
    getResultOpened: function (queryString = {}) {
      const url = appendQueryString('analytics/results-opened', queryString)
      return ajax(url, { method: 'GET' })
    },
    getPrescriptionPickedUpRate: function (queryString = {}) {
      const url = appendQueryString(
        'analytics/prescription-picked-up-rate',
        queryString
      )
      return ajax(url, { method: 'GET' })
    },
    getUtilizationAnalytics: function ({
      queryKey
    }: UseQueryOptions): Promise<HealthPlanUtilizationAnalyticsI> {
      const params = queryKey?.[1] || {}
      return ajax(`analytics/utilization?${params || ''}`, { method: 'GET' })
    },
    getMemberInfo: function ({
      queryKey
    }: UseQueryOptions): Promise<HealthPlanMembersAnalyticsI> {
      const params = queryKey?.[1] || {}
      return ajax(`analytics/member-info?${params || ''}`, { method: 'GET' })
    },
    getPatientInfo: function ({
      queryKey
    }: UseQueryOptions): Promise<PatientAnalyticsI> {
      const params = queryKey?.[1] || {}
      return ajax(`analytics/patient-info?${params || ''}`, { method: 'GET' })
    },
    getTestAnalytics: function ({
      queryKey
    }: UseQueryOptions): Promise<HealthPlanTestsAnalyticsI> {
      const params = queryKey?.[1] || {}
      return ajax(`analytics/test-analytics?${params || ''}`, { method: 'GET' })
    },
    getProgramInsights: function ({
      queryKey
    }: UseQueryOptions): Promise<ProgramInsightsI> {
      const params = queryKey?.[1] || {}

      const searchParams = new URLSearchParams(params as any)
      if (!searchParams || !searchParams.has('site')) return Promise.reject(new Error('Site is required'))

      return ajax(`analytics/program-insights?${params || ''}`, {
        method: 'GET'
      })
    },
    getPopulationHealth: function ({
      queryKey
    }: UseQueryOptions): Promise<PopulationHealthI> {
      const params = queryKey?.[1] || {}
      return ajax(`analytics/population-health?${params || ''}`, {
        method: 'GET'
      })
    },
    getOperations: function ({
      queryKey
    }: UseQueryOptions): Promise<OperationsAnalyticsI> {
      const params = queryKey?.[1] || {}
      return ajax(`analytics/operations?${params || ''}`, { method: 'GET' })
    },
    downloadProgramInsights: function ({
      queryKey
    }: UseQueryOptions): Promise<ProgramInsightsDownloadI> {
      const params = queryKey?.[1] || {}
      return ajax(`analytics/download-insights?${params || ''}`, {
        method: 'GET'
      })
    },
    downloadPopulationHealth: function ({
      queryKey
    }: UseQueryOptions): Promise<PopulationHealthDownloadI> {
      const params = queryKey?.[1] || {}
      return ajax(`analytics/download-population-health?${params || ''}`, {
        method: 'GET'
      })
    },
    downloadHealthPlanAnalyticsRawData: function ({
      queryKey
    }: UseQueryOptions): Promise<HealthPlanAnalyticsRawData> {
      const params = queryKey?.[1] || {}

      return ajax(`analytics/download-health-program?${params || ''}`, {
        method: 'GET'
      })
    },
    grafanaLogin: function () {
      return ajax('analytics/grafana-login', {
        method: 'GET'
      })
    }
  },
  notification: {
    createNotification: function (data: any) {
      return ajax('notification/create', {
        method: 'POST',
        data
      })
    },
    createMultipleNotifications: function (data: any) {
      return ajax('notification/multiple/create', {
        method: 'POST',
        data
      })
    },
    updateNotification: function (data: any): Promise<Partial<AppNotificationI>> {
      return ajax('notification/update', {
        method: 'PATCH',
        data
      })
    },
    getNotificationsByAccount: function (): Promise<{
      ok: boolean,
      notifications: AppNotificationI[]
    }> {
      return ajax('notification/account', {
        method: 'GET'
      })
    },
    getNotificationsByPatient: function (patientId: string): Promise<{ ok: boolean, notifications: AppNotificationI[] }> {
      return ajax(`notification/patient/${patientId}`, {
        method: 'GET'
      })
    },
    getNotificationsBySites: function (): Promise<{ ok: boolean, notifications: AppNotificationI[] }> {
      return ajax('notification/site', {
        method: 'GET'
      })
    },
    getNotification: function (notificationId: string): Promise<{ ok: boolean, notification: AppNotificationI }> {
      return ajax(`notification/${notificationId}`, {
        method: 'GET'
      })
    },
    getListOfSitesAndPatients: function (): Promise<{
      ok: boolean,
      sites: SiteI[],
      patients: PatientI[]
    }> {
      return ajax('notification/list/all', {
        method: 'GET'
      })
    },
    testHealthPlanIVR: function (data: IVRNotificationTextI) {
      return ajax('ivr/test-healthplan-notifications', {
        method: 'POST',
        data
      })
    }
  },
  sku: {
    getSkusForSite: function (id: string): Promise<CustomSiteSku[]> {
      return ajax(`sku/site-skus/${id}`, {
        method: 'GET'
      })
    },
    getSkusForSiteDefault: function (id: string): Promise<Sku[]> {
      return ajax(`sku/site-skus-default/${id}`, {
        method: 'GET'
      })
    },
    getAllSkus: function (): Promise<Sku[]> {
      return ajax('sku/skus', {
        method: 'GET'
      })
    },
    addSku: function (data: any) {
      return ajax('sku/add-sku', {
        method: 'POST',
        data
      })
    },
    editSiteSku: function (data: any) {
      return ajax('sku/edit-sku', {
        method: 'PATCH',
        data
      })
    },
    deleteSiteSku: function (data: any) {
      return ajax('sku/delete-sku', {
        method: 'PATCH',
        data
      })
    },
    enableSku: function (data: any) {
      return ajax('sku/enable-sku', {
        method: 'PATCH',
        data
      })
    },
    createSku: function (data: any) {
      return ajax('sku/create-sku', {
        method: 'POST',
        data
      })
    },
    editSku: function (data: any) {
      return ajax('sku/sku-config/edit', {
        method: 'PATCH',
        data
      })
    },
    deleteSku: function (data: any) {
      return ajax('sku/sku-config/delete', {
        method: 'POST',
        data
      })
    },
    editRequestSku: function (data: any) {
      return ajax('sku/edit-request-sku', {
        method: 'PATCH',
        data
      })
    }
  },
  inventory: {
    getSitesInventory: function () {
      return ajax('inventory/sites-inventory', {
        method: 'GET'
      })
    },
    getSitesInventoryOpt: function () {
      return ajax('inventory/sites-inventory-opt', {
        method: 'GET'
      })
    },
    getSpecificSiteData: function (name: string) {
      return ajax(`inventory/${name}`, {
        method: 'GET'
      })
    },
    getFulfilledKits: function () {
      return ajax('inventory/kits-fulfilled', {
        method: 'GET'
      })
    },
    getActiveKits: function () {
      return ajax('inventory/active-kits', {
        method: 'GET'
      })
    }
  },
  outreachLog: {
    getAllLogsByKitId: function (kit_id: string): Promise<{ data: OutreachLog[], needs_pcp: boolean, pcpData: PCP_Data, isOlhConfigured?: boolean }> {
      return ajax(`outreach-log/kit/${kit_id}`, { method: 'GET' }) as Promise<{ data: OutreachLog[], needs_pcp: boolean, pcpData: PCP_Data, isOlhConfigured?: boolean}>
    },
    getAllKitLogs: function ({
      page,
      pageSize,
      sorting,
      selectedTests,
      textFilter
    }: {
      textFilter?: string,
      page?: number,
      pageSize?: number,
      sorting?: GridSortModel
      selectedTests?: string[]
    }): Promise<KitsFromDBI> {
      return ajax('outreach-log/outreach_kits', {
        method: 'GET',
        data: {
          page,
          pageSize,
          sorting,
          selectedTests,
          textFilter
        }
      }) as Promise<KitsFromDBI>
    },
    create: function (outreachLog: OutreachLog): Promise<OutreachLog> {
      return ajax('outreach-log', { method: 'POST', data: outreachLog }) as Promise<OutreachLog>
    },
    updateById: function (id: number, outreachLog: OutreachLog): Promise<OutreachLog> {
      return ajax(`outreach-log/${id}`, { method: 'PATCH', data: outreachLog }) as Promise<OutreachLog>
    },
    getOutreachReport: function (data: { siteCodes: string[] }): Promise<OutreachAttemptReportI[]> {
      return ajax('outreach-log/report', { method: 'POST', data })
    }
  },
  analyteConfig: {
    getAll: function () {
      return ajax('analyte-config', { method: 'GET' }) as Promise<AnalyteConfigI[]>
    },
    getById: function (id: string, translate = true): Promise<AnalyteConfigI> {
      return ajax(`analyte-config/${id}?translate=${translate}`, { method: 'GET' })
    },
    create: function (analyteConfig: any) {
      return ajax('analyte-config', { method: 'POST', data: analyteConfig })
    },
    updateById: function (id: string, analyteConfig: any) {
      return ajax(`analyte-config/${id}`, {
        method: 'PATCH',
        data: analyteConfig
      })
    },
    deleteById: function (id: number) {
      return ajax(`analyte-config/${id}`, { method: 'DELETE' })
    }
  },
  event: {
    create: function (data: any) {
      return ajax('event', { method: 'POST', data })
    }
  },
  registeredPrepProgram: {
    register: function (data: { formData: TPrepProgram; siteId: number }) {
      return ajax('registered-prep-program', {
        method: 'POST',
        data
      }) as Promise<{
        success: boolean
        registeredPrepProgram: TRegisteredPrepProgram
      }>
    },
    getById: function (id: number): Promise<TRegisteredPrepProgram> {
      return ajax(`registered-prep-program/${id}`, {
        method: 'GET'
      })
    },
    getAll: function (): Promise<TRegisteredPrepProgram> {
      return ajax('registered-prep-program', {
        method: 'GET'
      })
    }
  },
  mwlProgram: {
    createConsult: function (data: any): Promise<{
      success: boolean
      message: string
      data: {
        consultId: string;
        status: string;
      }
    }> {
      return ajax('simple-weight-plan/create', {
        method: 'POST',
        data
      })
    },
    setIneligibleConsult: function (slug: string) {
      return ajax(`simple-weight-plan/set-consult-ineligible/${slug}`, {
        method: 'PATCH'
      })
    },
    updateConsult: function (id: string, data: any): Promise<{
      success: true,
      message: string
    }> {
      return ajax(`simple-weight-plan/update-consult/${id}`, {
        method: 'PATCH',
        data
      })
    },
    updateCreateEligibility: function (data: any) {
      return ajax('simple-weight-plan/update-eligibility', {
        method: 'PATCH',
        data
      })
    },
    updateConsultPaymentStatus: function (id: string, data: any) {
      return ajax(`simple-weight-plan/update-consult-payment-completed/${id}`, {
        method: 'PATCH',
        data
      })
    },
    updateMoreInfo: function (id: string, data: any): Promise<{
      success: boolean,
      message: string
    }> {
      return ajax(`simple-weight-plan/update-more-info/${id}`, {
        method: 'PATCH',
        data
      })
    },
    updateFinalizeConsult: function (id: string) {
      return ajax(`simple-weight-plan/update-finalize-consult/${id}`, {
        method: 'PATCH'
      })
    },
    pauseSubscription: function (id: string, data: any): Promise<{
      success: boolean,
      message: string
    }> {
      return ajax(`simple-weight-plan/pause-subscription/${id}`, {
        method: 'PATCH',
        data
      })
    },
    resumeSubscription: function (id: number, resume_option: 'immediately' | 'specific_date', resume_date: number | null, cycle_passed: boolean = false): Promise<{
      success: boolean,
      message: string
    }> {
      return ajax(`simple-weight-plan/resume-subscription/${id}`, {
        method: 'PATCH',
        data: { resume_date, resume_option, cycle_passed }
      })
    },
    getPortalToken: function (id: number): Promise<{
      success: boolean,
      data: any
    }> {
      return ajax(`simple-weight-plan/get-portal-token/${id}`, {
        method: 'GET'
      })
    },
    getConsultById: function (id: number | string) {
      return ajax(`simple-weight-plan/get-by-id/${id}`, { method: 'GET' }) as Promise<{
        success: boolean
        data: any,
        content: any,
        slug: string
      }>
    },
    getSubscriptionsByUser: function () {
      return ajax('simple-weight-plan/get-subscription-by-user', { method: 'GET' }) as Promise<{
        success: boolean
        data: any,
      }>
    },
    getConsultNotesByType: function (refill_id: string, type: string) {
      return ajax(`simple-weight-plan/get-notes-by-type/${refill_id}/${type}`, { method: 'PATCH' }) as Promise<{
        success: boolean
        data: any
      }>
    },
    getConsultNoteDatesByType: function (id: string, type: string) {
      return ajax(`simple-weight-plan/get-note-dates-by-type/${id}/${type}`, { method: 'GET' }) as Promise<{
        success: boolean
        data: any
      }>
    },
    getAllNotesById: function (id: string) {
      return ajax(`simple-weight-plan/get-all-notes-by-id/${id}`, { method: 'GET' }) as Promise<{
        success: boolean
        data: any
      }>
    },
    getConsults: function () {
      return ajax('simple-weight-plan/get-all', { method: 'GET' }) as Promise<{
        success: boolean
        data: any
      }>
    }
  },
  program: {
    getPrepProgramConfig: function (slug: string) {
      return ajax(`program/prep/${slug}/config`, { method: 'GET' }) as Promise<{
        success: boolean
        site: TPrepProgramConfigSite
      }>
    },
    getPrepProgramPatients: function () {
      return ajax('program/prep/patients', { method: 'GET' }) as Promise<{
        success: boolean
        patients: PatientI[]
      }>
    },
    getProgramConfig: function (slug: string): Promise<ProgramConfigWithHeaderI> {
      return ajax(`program/${slug}`, { method: 'GET' })
    },
    getProgramByType: function (siteId: string, type: string): Promise<ProgramWithHeaderI> {
      return ajax(`program/${type}/${siteId}`, { method: 'GET' })
    },
    getProgramType: function (slug: string) {
      return ajax(`program/get-type/${slug}`, { method: 'GET' })
    },
    checkSKUEligibilityFromDob: function (slug: string, data: any): Promise<{
      eligible: boolean,
      msg: string,
      ineligibleSkus?: string[]
    }> {
      return ajax(`program/${slug}/check-eligibility-from-dob`, {
        method: 'POST',
        data
      })
    },
    updateProgram: function (
      siteId: string,
      type: string,
      data: Partial<ProgramI>
    ): Promise<ProgramI> {
      return ajax(`program/${type}/${siteId}`, { method: 'PATCH', data })
    },
    confirmFreeOrder: function (data: any) {
      return ajax('program/confirm-free-order', { method: 'POST', data })
    },
    checkPatientEligibility: function (slug: string, data: any) {
      return ajax(`program/${slug}/check-eligibility`, { method: 'POST', data })
    },
    addNewNotifyee: function (data: any) {
      return ajax('program/add-new-notifyee', { method: 'POST', data })
    },
    checkProgramPauseStatus: function (slug: string): Promise<{
      pauseStatus: boolean
    }> {
      return ajax(`program/${slug}/check-pause-status`, { method: 'GET' })
    }
  },
  healthPlan: {
    updateMember: function (id: number, data: MemberDetailsType) {
      return ajax(`health-plan/update-member/${id}`, { method: 'PATCH', data })
    },
    uploadMemberCSV: function (data: MemberUploadPayload) {
      return ajax('health-plan/upload', { method: 'POST', data })
    },
    downloadKitFulfillmentCSV: function (data: downloadFileRequestData) {
      return ajax('health-plan/kit-fulfillment/download', {
        method: 'POST',
        data
      }) as Promise<kitFulfillmentJSONData>
    },
    downloadMemberEngagementCSV: function (data: downloadFileRequestData) {
      return ajax('health-plan/member-engagement/download', {
        method: 'POST',
        data
      }) as Promise<kitFulfillmentJSONData>
    },
    getMembers: function ({ site_id, query = {} }: any) {
      let url
      if (site_id) {
        url = appendQueryString(`health-plan/members/${site_id}`, query)
      } else {
        url = appendQueryString('health-plan/members', query)
      }
      return ajax(url, { method: 'GET' }) as Promise<GetMembersResponse>
    },
    memberJourney: function (id: number): Promise<{
      memberJourney: memberJourneyI[]
    }> {
      return ajax(`health-plan/member-journey/${id}`, {
        method: 'GET'
      })
    },
    getMostRecentUpload: function (): Promise<{ ok: boolean, message: string, upload: HealthPlanUploadI }> {
      return ajax('health-plan/member-uploads', { method: 'GET' })
    },
    undoLatestUploadOfASite: function (upload_id: string): Promise<{
      ok: boolean,
      message: string
    }> {
      return ajax(`health-plan/upload/${upload_id}`, { method: 'DELETE' })
    },
    reportOfChanges: function (): Promise<any> {
      return ajax('health-plan/report/changes', { method: 'GET' })
    },
    verifyMember: function (memberData: { 
      memberCode: string; 
      dob: string;
      email?: string;
      phone?: string;
      updateConsent?: boolean;
    }): Promise<HealthPlanMemberI & { account_id: string }> {
      return ajax('health-plan/members/verify', {
        data: memberData,
        method: 'GET'
      })
    },
    assignAccount: function (auth0Id: string, key: string, updateConsent = false ) {
      return ajax('health-plan/assign-account', {
        method: 'PATCH',
        data: { auth0Id, key, updateConsent }
      })
    },
    getBasicInfo: function (siteId: string): Promise<SiteI['health_plan_config']> {
      return ajax(`health-plan/${siteId}/basic-info`, { method: 'GET' })
    },
    validateMagicLink: function ({ siteId, memberCode }: { siteId: string; memberCode: string }): Promise<{
      success: boolean
      healthPlanMember: { data: { Member_Language: string, Member_Access_ID: string } }
    }> {
      return ajax(
        `health-plan/${siteId}/validate-magic-link?memberCode=${memberCode}`,
        { method: 'GET' }
      )
    },
    getHedisData: function (data: { sites: string }): Promise<HedisFileJSONData> {
      return ajax('health-plan/hedis', {
        method: 'POST',
        data
      })
    },
    getHedisCignaData: function (data: { sites: string }): Promise<HedisFileJSONData> {
      return ajax('health-plan/hedis-cigna', {
        method: 'POST',
        data
      })
    },
    getMemberLevelCenteneData: function (data: { sites: string, startDate?: string, endDate?: string }): Promise<HedisFileJSONData> {
      return ajax('health-plan/member-level-centene', {
        method: 'POST',
        data
      })
    },
    getInvoiceCignaData: function (data: { sites: string, startDate?: string, endDate?: string, invoiceNumber?: string | null }): Promise<HedisFileJSONData> {
      return ajax('health-plan/invoice-data-cigna', {
        method: 'POST',
        data
      })
    },
    getvenderDispositionCenteneData: function (data: { sites: string }): Promise<HedisFileJSONData> {
      return ajax('health-plan/vender-disposition-centene', {
        method: 'POST',
        data
      })
    },
    getSgaDataCentene: function (data: { sites: string, startDate?: string, endDate?: string, invoiceNumber?: string | null, invoiceDate?: string | null, invoiceMonth?: string | null }): Promise<HedisFileJSONData> {
      return ajax('health-plan/sga-data-centene', {
        method: 'POST',
        data
      })
    },
    getDispositionReportCentene: function (data: { sites: string, startDate?: string, endDate?: string, invoiceNumber?: string | null, invoiceDate?: string | null, invoiceMonth?: string | null }): Promise<HedisFileJSONData> {
      return ajax('health-plan/disposition-report-centene', {
        method: 'POST',
        data
      })
    },
    getHealthPlanOverviewData: function ({
      queryKey
    }: UseQueryOptions): Promise<HealthPlanOverviewAnalyticsI> {
      const params = queryKey?.[1] || {}
      return ajax(`analytics/overview?${params || ''}`, {
        method: 'GET'
      })
    },
    replaceHealthPlanKit: function (data: any): Promise<{ success: boolean, memberKit: Kit }> {
      return ajax('kit/kit-replace', { method: 'POST', data })
    },
    verifyHpUser: function (data: any) {
      return ajax('health-plan/check-hp-user', { method: 'POST', data })
    },
    confirmHpOrder: function (data: any): Promise<{
      success: boolean
      error: string
      data: string
    }> {
      return ajax('health-plan/confirm-order', { method: 'POST', data })
    },
    getAllEligibleSkusPerUser: function (memberId: string): Promise<{
      status: string,
      error: string,
      data: SkuResponse[],
      incentiveMapper: Record<string, number>
    }> {
      return ajax(`health-plan/get-all-available-skus/${memberId}`, {
        method: 'GET'
      })
    },
    getAggregateResultsDeliveryReport: function (data: GetResultsDeliveryReportI): Promise<ResultDeliveryReportI[]> {
      return ajax('health-plan/report/aggregate-results-delivery', { method: 'POST', data })
    },
    getResultDeliveryHoldsReport: function (data: GetResultsDeliveryReportI): Promise<ResultDeliveryReportI[]> {
      return ajax('health-plan/report/results-delivery-holds', { method: 'POST', data })
    },
    getResultDeliveryReport: function (data: GetResultsDeliveryReportI): Promise<ResultDeliveryReportI[]> {
      return ajax('health-plan/report/results-delivery', { method: 'POST', data })
    }
  },
  i18nextContent: {
    getAll: function (): Promise<TranslationI[]> {
      return ajax('i18next-content', { method: 'GET' })
    },
    create: function (data: Pick<TranslationI, 'identifier' | 'spanish' | 'english'>): Promise<TranslationI> {
      return ajax('i18next-content', { method: 'POST', data })
    },
    update: function (data: TranslationI): Promise<TranslationI> {
      return ajax(`i18next-content/${data.id}`, { method: 'PATCH', data })
    },
    delete: function (id: number): Promise<{ success: boolean }> {
      return ajax(`i18next-content/${id}`, { method: 'DELETE' })
    },
    getTranslation: function (idOrKey: string | number): Promise<TranslationI> {
      // The endpoint works with the id or the identifier (key) of the translation
      return ajax(`i18next-content/${idOrKey}`, { method: 'GET' })
    },
    getMultipleTranslations: function (data: { identifiers: string[] }): Promise<TranslationI[]> {
      return ajax('i18next-content/multiple', { method: 'POST', data })
    }
  },
  results: {
    uploadKitResults: function (data: any) {
      return ajax('results/upload', { method: 'POST', data })
    },
    getValidOptionsForFile: function (): Promise<ValidOptionsForResultFile> {
      return ajax('results/options', { method: 'GET' })
    }
  },
  healthPlanNotificationSettings: {
    getAll: function (queryString: string): Promise<THealthPlanNotificationSettings[]> {
      return ajax(`health-plan-notification-settings${queryString}`, { method: 'GET' })
    },
    bulkUpdate: function (data: Partial<THealthPlanNotificationSettings>[]) {
      return ajax('health-plan-notification-settings', { method: 'PATCH', data })
    }
  },
  schedules: {
    getAll: function (query = {}) {
      const url = appendQueryString('schedules/get-all', query)
      return ajax(url, { method: 'GET' })
    }
  },
  sftpSettings: {
    getSftpSettings: function (id: number): Promise<SftpSettingsI> {
      return ajax(`sftp/${id}`, { method: 'GET' })
    },
    getAllSftpSettings: function (): Promise<SftpSettingsI[]> {
      return ajax('sftp', { method: 'GET' })
    },
    createSftpSettings: function (data: Omit<SftpSettingsI, 'created_at' | 'updated_at' | 'deleted_at' | 'id' | 'last_updated_by'>): Promise<SftpSettingsI> {
      return ajax('sftp', { method: 'POST', data })
    },
    updateSftpSettings: function (id: number, data: SftpSettingsUpdate): Promise<{ ok: boolean }> {
      return ajax(`sftp/${id}`, { method: 'PATCH', data })
    },
    deleteSftpSettings: function (id: number): Promise<{ ok: boolean }> {
      return ajax(`sftp/${id}`, { method: 'DELETE' })
    }
  },
  mailAndFax: {
    getMailsOnHold: function (): Promise<MailOnHoldI[]> {
      return ajax('mail-fax/mails-on-hold', { method: 'GET' })
    },
    updateAndResendMail: function (data: ResultsMailerUpdateFormI): Promise<{ ok: boolean }> {
      return ajax('mail-fax/resend-mail', { method: 'POST', data })
    },
    getFaxesOnHold: function (): Promise<FaxOnHoldI[]> {
      return ajax('mail-fax/faxes-on-hold', { method: 'GET' })
    },
    updateAndResendFax: function (data: UpdateFaxFormI): Promise<{ ok: boolean }> {
      return ajax('mail-fax/resend-fax', { method: 'POST', data })
    },
    mailASingleReport: function (data: MailLabReportFormI): Promise<{ ok: boolean }> {
      return ajax('mail-fax/mail-single-report', { method: 'POST', data })
    },
    getKitDataToMail: function (kitCode: string): Promise<Omit<MailLabReportFormI, 'mailType'>> {
      return ajax(`mail-fax/get-kit-data-to-mail/${kitCode}`, { method: 'GET' })
    },
    getKitDataToFax: function (kitCode: string): Promise<FaxLabReportFormI> {
      return ajax(`mail-fax/get-kit-data-to-fax/${kitCode}`, { method: 'GET' })
    },
    faxASingleReport: function (data: FaxLabReportFormI): Promise<{ ok: boolean }> {
      return ajax('mail-fax/fax-single-report', { method: 'POST', data })
    }
  },
  ssoSettings: {
    getSsoSettings: function (id: number): Promise<SsoSettingsI> {
      return ajax(`sso/${id}`, { method: 'GET' })
    },
    getAllSsoSettings: function (): Promise<SsoSettingsI[]> {
      return ajax('sso', { method: 'GET' })
    },
    createSsoSettings: function (data: Omit<SsoSettingsI, 'created_at' | 'updated_at' | 'deleted_at' | 'id' | 'last_updated_by'>): Promise<SsoSettingsI> {
      return ajax('sso', { method: 'POST', data })
    },
    updateSsoSettings: function (id: number, data: Omit<SsoSettingsI, 'created_at' | 'updated_at' | 'deleted_at' | 'id' | 'last_updated_by'>): Promise<{ ok: boolean }> {
      return ajax(`sso/${id}`, { method: 'PATCH', data })
    },
    deleteSsoSettings: function (id: number): Promise<{ ok: boolean }> {
      return ajax(`sso/${id}`, { method: 'DELETE' })
    }
  },
  customAnalytics: {
    getCustomAnalytics: function (id: number): Promise<CustomAnalyticsI> {
      return ajax(`custom-dashboard/${id}`, { method: 'GET' })
    },
    getAllCustomAnalytics: function (): Promise<CustomAnalyticsI[]> {
      return ajax('custom-dashboard', { method: 'GET' })
    },
    getCustomAnalyticsByUser: function (): Promise<GrafanaDashboardI[]> {
      return ajax('custom-dashboard/get-details-by-user', { method: 'GET' })
    },
    createCustomAnalytics: function (data: Omit<CustomAnalyticsI, 'created_at' | 'updated_at' | 'deleted_at' | 'id' | 'last_updated_by'>): Promise<CustomAnalyticsI> {
      return ajax('custom-dashboard', { method: 'POST', data })
    },
    updateCustomAnalytics: function (id: number, data: Omit<CustomAnalyticsI, 'created_at' | 'updated_at' | 'deleted_at' | 'id' | 'last_updated_by'>): Promise<{ ok: boolean }> {
      return ajax(`custom-dashboard/${id}`, { method: 'PATCH', data })
    },
    deleteCustomAnalytics: function (id: number): Promise<{ ok: boolean }> {
      return ajax(`custom-dashboard/${id}`, { method: 'DELETE' })
    }
  },
  portalUsers: {
    usersSubscriptionStatus: function (query: {
      offset: number
      limit: number
      search: string | undefined
    }): Promise<{
      users: Partial<PortalUserI>[],
      count: number
    }> {
      const formattedQuery = {
        ...query,
        offset: `${query.offset}`,
        limit: `${query.limit}`
      }

      if (!formattedQuery.search) delete formattedQuery.search

      const url = appendQueryString('portal-user/notification-subscriptions', formattedQuery as any)
      return ajax(url, { method: 'GET' })
    },
    updateNotificationSubscription: function (data: UserStatusUpdateI): Promise<{ success: boolean }> {
      return ajax('portal-user/notification-subscriptions', { method: 'PATCH', data })
    },
    unsubscribeUserFromEmails: function (data: { code: string }): Promise<{ success: boolean }> {
      return ajax('portal-user/unsubscribe', { method: 'POST', data })
    },
    verifyNotificationsSubscription: function (data: { code: string }): Promise<{ email_notifications_enabled: boolean }> {
      return ajax(`portal-user/verify-subscription/${data.code}`, { method: 'GET' })
    },
    getPortalUser: function (code: string, attributes: string[]): Promise<{
      headers: string,
      user: Partial<PortalUserI>
    }> {
      return ajax(`portal-user/${code}?at=${attributes.join(',')}`, { method: 'GET' })
    }
  }
}

// Interfaces of the responses from the API

interface ValidOptionsForResultFile {
  analyteNames: string[]
  sampleInstruments: string[]
  samplePanel: string[]
  sampleTests: string[]
  sampleTypes: string[]
}
interface GetMembersResponse {
  ok: boolean
  message: string
  members: HealthPlanMemberI[]
  pagination: {
    count: number
    limit: number
    page: number
    total: number
  }
}

interface ResponseGetResultsDetails {
  token: string
  data: {
    testId: string
    data: ResultFromLimsI[]
  }[]
}

interface UpdateProfileData {
  notificationEmail?: string
  phone?: string
  language?: string
}

interface KitCheckResponse extends Partial<Kit> {
  kitTypeConfig?: KitTypeConfigI
  site?: SiteI
}

interface ConsultsResponse {
  consults: ConsultI[]
  count: number
}

export interface SiteWithRetailI extends SiteI {
  retail: boolean
}

export type PartialKitWithSample = Partial<Kit> & { samples: Pick<SampleI, 'result'>[] }

export interface ProgramWithHeaderI extends ProgramI {
  headers?: string
}

export interface ProgramConfigWithHeaderI extends ProgramConfig {
  headers?: string
  name?: string
  consent?: string
  address?: string
  allowedSiteSkus?: Array<{
    skuID: string
    skuName: string
    kitTypeConfig: Partial<KitTypeConfigI>
  }>
}

interface DownloadResultResp {
  lisEndPoint: string
  data: {
    patient: Partial<PatientI>
    site: Partial<SiteI>
    kit: Partial<Kit>
    samples: Partial<SampleI>[]
    kitTypeConfig: Partial<KitTypeConfigI>
    analytes: Partial<AnalyteConfigI>[]
  } | null
  buffer: string | null
}

export interface GetOrdersDataAPI {
  startDate: Date
  endDate: Date
  siteIds: number[]
  status: string[]
}

interface GetOrdersDataResponse {
  success: boolean
  data: any[]
}

interface FilterItem {
  columnField: string;
  value: string[] | string;
  field: string
}

interface SortModel {
  field: string;
  sort: 'asc' | 'desc';
}

interface GetConsultsParams {
  filterModel?: { items: FilterItem[] };
  searchTerm?: string;
  page?: number;
  pageSize?: number;
  sortModel?: SortModel[];
  export?: boolean,
  exportData?: any
}

export interface CustomSiteSku extends Pick<Sku, 'name' | 'setting' | 'enabled' | 'sku_id'> {
  site_id: number
  custom_price: string
  kitTypeConfig: Pick<KitTypeConfigI, 'id' | 'name' | 'allowedSexAssignedAtBirth'>
  analyteConfigs: Pick<AnalyteConfigI, 'id' | 'display_name' | 'reference_range' | 'result_states' | 'type'>[]
}

export interface GetResultsDeliveryReportI {
  startDate?: string
  endDate?: string
  siteCodes: string[]
}

export interface RefreshApiResponse {
  success: boolean,
  user: AuthUser,
  accessToken: string,
  refresh: string,
  expiresIn: string
}

export default api
