import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { api } from 'Core'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'

export function useGetKitTypeConfigs (translate: boolean = false) {
  return useQuery({
    queryKey: ['kit-type-configs', translate],
    queryFn: () => {
      return api.kitTypeConfig.getAll(translate)
    }
  })
}

export function useGetKitTypeConfig (id: string, translate = false) {
  return useQuery(['kit-type-configs', id, translate], () => api.kitTypeConfig.getById(id, translate))
}

export function useUpdateKitTypeConfig () {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  return useMutation(api.kitTypeConfig.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(['kit-type-configs'])
      dispatch(showSnackBar({
        severity: 'success',
        message: 'Kit type config updated successfully',
        show: true
      }))
    },
    onError: () => {
      dispatch(showSnackBar({
        severity: 'error',
        message: 'Something went wrong updating the kit type config',
        show: true
      }))
    }
  })
}

export function useRemoveKitTypeConfig () {
  const queryClient = useQueryClient()
  return useMutation(api.kitTypeConfig.remove, {
    onSuccess: () => queryClient.invalidateQueries(['kit-type-configs'])
  })
}

export function useCreateKitTypeConfig () {
  const queryClient = useQueryClient()
  return useMutation(api.kitTypeConfig.create, {
    onSuccess: () => queryClient.invalidateQueries(['kit-type-configs'])
  })
}
