import {
  Stack,
  Typography,
  useMediaQuery,
  Button,
  Alert,
  Tooltip,
  useTheme
} from '@mui/material'
import { api } from 'Core'
import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react'
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useParams,
  useNavigate,
  NavigateFunction
} from 'react-router-dom'
import { Container, Paper, SwipeableDrawer } from 'components'
import Loader from 'components/Loader'
import KitCodeForm from './KitCodeForm'
import PatientInfoForm from './PatientInfoForm'
import ReviewForm from './ReviewForm'
import SampleCollectionForm from './SampleCollectionForm'
import ScreeningForm from './ScreeningForm'
import useSite from 'hooks/useSite'
import { useDispatch, useSelector } from 'react-redux'
import RegisterConfirm from './RegisterConfirm'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { checkScreeningQuestions } from 'utils/kit'
import { PatientPaymentMethods } from './PatientPaymentMethods'
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded'
import AppVideoPlayer from 'components/AppVideoPlayer/index'
import PatientInfoFormProgram from './PatientInfoFormProgram'
import { LoadingButton } from '@mui/lab'
import ExpiredKit from './ExpiredKit'
import { useTranslation } from 'react-i18next'
import { SiteI } from '../../types/db/SiteI'
import { PatientI } from '../../types/db/PatientI'
import { Answers, Kit, Supervision } from '../../types/db/Kit'
import { KitTypeConfigI } from '../../types/db/KitTypeConfigI'
import { SiteWithRetailI } from '../../Core/api'
import { ReduxState } from '../../types/ReduxState'
import { analyticEventTracker } from 'utils/analytics'
import RegisterKitMobileStepper from './RegisterKitMobileStepper'
import RegisterKitDesktopStepper from './RegisterKitDesktopStepper'

export function isPrePaidSite (siteCode = '') {
  return ['PRIDE2022', 'WALMART', 'WMECOM', 'CVSDEMO', 'WALGREENS', 'SHKUTS', 'SHKDEMO', 'DEMOFAC'].includes(siteCode)
}
export function isBadKit (kitCode = '') {
  return ['SHKPTESTDEC6', 'SS-58948-TEST', 'SHKPTESTDEC7', 'SHKPTESTDEC8', 'SHKP50019907', 'SHKP50019917', 'SHKP50019930', 'SHKP50019942', 'SHKP50019953', 'SHKP50019961', 'SHKP50019971', 'SHKP50019983', 'SHKP50019992', 'SHKP50020001', 'SHKP50020011', 'SHKP50020019', 'SHKP50021068', 'SHKP50021077', 'SHKP50021086', 'SHKP50021095', 'SHKP50021105', 'SHKP50021115', 'SHKP50021123', 'SHKP50021130', 'SHKP50021140', 'SHKP50021151', 'SHKP50021160', 'SHKP50021170'].includes(kitCode)
}
export function sitePaymentMethod (site: SiteI | undefined, kitTypeConfig: KitTypeConfigI | undefined) {
  if (!site || !kitTypeConfig) return 'insurance'
  const methods = ['insurance', 'cash']
  const prefix = kitTypeConfig.prefix
  const siteConfig = site?.payment_config?.[prefix]
  if (!siteConfig) return 'insurance'
  const isKitPrepaid = siteConfig?.isPrepaid || false

  if (isKitPrepaid) return 'prepaid'
  if (!isKitPrepaid && siteConfig?.secondary === 'none') return siteConfig?.primary || 'insurance'
  if (!isKitPrepaid && siteConfig?.primary && siteConfig?.secondary && methods.includes(siteConfig?.primary) && methods.includes(siteConfig?.secondary)) return 'both'
  return 'insurance'
}

const RegisterKitContext = createContext<RegisterKitContextI>({} as RegisterKitContextI)
RegisterKitContext.displayName = 'RegisterKitContext'

export function useRegisterKit () {
  const context = useContext(RegisterKitContext)

  if (!context) {
    throw Error('useRegisterKit must be in scope with <RegisterKitProvider /> ')
  }

  const { data, updateData, step, saveKitAction } = context

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step.active])

  return {
    data: {
      kitCodeForm: data.kitCodeForm,
      patientInfoForm: data.patientInfoForm,
      screeningForm: data.screeningForm,
      insuranceDetailsForm: data.insuranceDetailsForm,
      sampleCollectionInfoForm: data.sampleCollectionInfoForm,
      reviewForm: data.reviewForm,
      kit: data.kit,
      payment: data.payment,
      insurance: {
        isEligible: data.insurance.isEligible,
        why: data.insurance.why
      },
      site: data.site || undefined
    },
    updateData,
    saveKitAction,
    step: {
      active: step.active,
      total: step.total,
      isPaymentStepRequired: !!data?.payment && data.payment !== 'prepaid',
      isScreeningQuestionsStepRequired: !!data?.kit && checkScreeningQuestions(data?.kit?.kitTypeConfig, data?.site)
    }
  } as RegisterKitContextI
}

export function RegisterKitProvider ({ children }: { children: React.ReactNode }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const { kitId, kitCode } = useParams()
  // const { validateSiteCode, getSiteContents } = useSite()
  const { validateSiteCode } = useSite()
  const { patients, ui } = useSelector((state: ReduxState) => state)
  const [isLoading, setIsLoading] = useState(!!kitId || !!kitCode)

  const [data, setData] = useState<RegisterKitDataI>({
    kitCodeForm: { kitcode: '', sitecode: null, isKitFulfilled: true },
    patientInfoForm: { patient: null, supervision: {} as Supervision },
    screeningForm: { answers: {} },
    insuranceDetailsForm: {
      insuranceTnC: false,
      continuedWithoutInsurance: false
    },
    sampleCollectionInfoForm: {
      intendToCollect: null,
      collectedDate: '',
      collectedTime: '',
      dobCheckbox: false,
      nameCheckbox: false,
      collectiondateCheckbox: false
    },
    reviewForm: {
      reviewTnCTwo: false,
      reviewTnCDisclaimer: false,
      fullName: ''
    },
    kit: null,
    payment: false,
    insurance: { isEligible: true, why: {} },
    site: null
  })

  const navigate = useNavigate()

  const init = async () => {
    try {
      if (kitCode) {
        const response = await api.kit.check(kitCode, false)
        if (response) {
          const { kit } = await api.kit.getKit(response?.id as number)

          if (kit?.lims_requisition_id) {
            navigate('/')
            return
          }

          const patient = findPatientById(patients, kit.patient_id)
          const payment = sitePaymentMethod(response.site, response.kitTypeConfig)

          setData(currentData => ({
            ...currentData,
            kit: response,
            kitCodeForm: { ...currentData.kitCodeForm, kitcode: kitCode },
            payment,
            patientInfoForm: { patient, supervision: kit.supervision, isKitFulfilled: kit.is_fulfilled },
            site: response.site
          }) as RegisterKitDataI)

          handleExpirationNavigation(response?.expiration_date ?? '', `/register/${response.id}/expired`, navigate)

          navigate(`/register/${response.id}/patient-info`, { replace: true })
          return
        }

        setData(currentData => ({
          ...currentData,
          kitCodeForm: { ...currentData.kitCodeForm, kitcode: kitCode }
        }))

        navigate('/register', { replace: true })
        return
      }

      if (kitId && !data.kit) {
        const { kit, siteResponse, payment } = await processKitById(kitId, patients, validateSiteCode)
        const patient = findPatientById(patients, kit?.patient_id ?? NaN)

        if (kit?.lims_requisition_id) {
          navigate('/')
          return
        }

        setData(currentData => ({
          ...currentData,
          kit,
          site: siteResponse ? siteResponse.data : null,
          kitCodeForm: {
            ...currentData.kitCodeForm,
            kitcode: kit?.code
          },
          patientInfoForm: { patient, supervision: kit?.supervision, isKitFulfilled: kit?.is_fulfilled },
          screeningForm: { answers: kit?.answers || {} },
          payment
        }) as RegisterKitDataI)

        handleExpirationNavigation(kit?.expiration_date, `/register/${kit?.id}/expired`, navigate)

        if (getActiveStep() === 0) {
          navigate(`/register/${kit?.id}/patient-info`, { replace: true })
        }
      } else {
        const kitCode = kitId || ''
        if (!kitCode) return

        const response = await api.kit.check(kitCode, false)
        if (response) {
          if (response?.lims_requisition_id) {
            navigate('/')
            return
          }
          const payment = sitePaymentMethod(response.site, response.kitTypeConfig)
          setData(currentData => ({
            ...currentData,
            kit: response,
            kitCodeForm: { ...currentData.kitCodeForm, kitcode: kitCode },
            payment,
            patientInfoForm: { ...currentData.patientInfoForm, isKitFulfilled: response.is_fulfilled },
            site: response.site
          }) as RegisterKitDataI)

          handleExpirationNavigation(response.expiration_date, `/register/${response.id}/expired`, navigate)

          navigate(`/register/${response.id}/patient-info`, { replace: true })
          return
        }

        setData(currentData => ({
          ...currentData,
          kitCodeForm: { ...currentData.kitCodeForm, kitcode: kitCode }
        }))

        navigate('/register', { replace: true })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isBadKit(kitCode)) {
      return
    }
    init()
  }, [patients])

  useEffect(() => {
    if (kitCode) {
      if (isBadKit(kitCode)) {
        navigate('/faulty-kit', { replace: true })
      }
    }
  }, [kitCode])

  const isScreeningQuestionsStepRequired = !!data?.kit && checkScreeningQuestions(data.kit.kitTypeConfig, data.site)

  const isPaymentStepRequired = !!data?.payment && data.payment !== 'prepaid'

  function getActiveStep () {
    let activeStep = 0
    let pageName = 'Kit Details'
    const pathname = location.pathname
    if (pathname.includes('patient-info')) {
      activeStep = 1
      pageName = 'Patient Info'
    }
    if (pathname.includes('screening')) {
      activeStep = 2
      pageName = 'Screening'
    }
    if (pathname.includes('payment-method')) {
      activeStep = 3
      pageName = 'Payment Method'
      if (!isScreeningQuestionsStepRequired) {
        activeStep -= 1
      }
    }
    if (pathname.includes('sample-collection')) {
      activeStep = data.payment === 'prepaid' ? 3 : 4
      pageName = 'Sample Collection'
      if (!isScreeningQuestionsStepRequired) {
        activeStep -= 1
      }
    }
    if (pathname.includes('review')) {
      activeStep = data.payment === 'prepaid' ? 4 : 5
      pageName = 'Review'
      if (!isScreeningQuestionsStepRequired) {
        activeStep -= 1
      }
    }

    if (ui['newKitForm/step'] !== pageName) {
      // This value is saved for google analytics purposes
      dispatch({ type: 'ui/newKitForm/step', payload: pageName })
    }

    return activeStep
  }

  const getTotalSteps = () => {
    let totalSteps = 6

    if (!isPaymentStepRequired) {
      totalSteps -= 1
    }

    if (!isScreeningQuestionsStepRequired) {
      totalSteps -= 1
    }

    return totalSteps
  }

  const totalSteps = getTotalSteps()

  const saveKitAction = async (pageInfo: string, additionalInfo = {}) => {
    if (data?.kit?.code) {
      await api.kit.saveKitAction(data?.kit?.code, pageInfo, additionalInfo)
    }
  }

  const contextValue = useMemo(() => ({
    data,
    updateData: setData,
    saveKitAction,
    step: {
    active: getActiveStep(),
    total: totalSteps,
    isPaymentStepRequired,
    isScreeningQuestionsStepRequired
    }
  }), [data, setData, saveKitAction, getActiveStep, totalSteps, isPaymentStepRequired, isScreeningQuestionsStepRequired]);

  if (isLoading) return <Loader />

  if (!data.kit && location.pathname !== '/register') return <Navigate to='/register' />

  return (
    <RegisterKitContext.Provider
      value={ contextValue }
    >
      { children }
    </RegisterKitContext.Provider>
  )
}

function RegisterKit () {
  return <RegisterKitProvider>
    <RegisterKitContainer />
  </RegisterKitProvider>
}

export default RegisterKit

function RegisterKitContainer () {
  const { pathname } = useLocation()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const isSuccessPath = pathname.endsWith('success')
  const { data } = useRegisterKit()
  const { t } = useTranslation()

  useDocumentTitle(t('registerKit.title'))

  return <Container>
    <Stack sx={ { gap: 3 } }>
      <Stack sx={ { flexDirection: 'row', gap: 1.5, mx: 'auto' } }>
        <Typography variant='h4' component='h1' sx={ { textAlign: 'center' } }>
          { t('registerKit.title') }
        </Typography>
        <VideoTutorial />
      </Stack>
      <Stack
        sx={ {
          flexDirection: mobile ? 'column' : 'row',
          gap: mobile ? 3 : 5,
          justifyContent: mobile ? 'center' : 'flex-start',
          alignItems: mobile ? 'center' : 'flex-start',
          mx: isSuccessPath ? 'auto' : 0
        } }
      >
        { !isSuccessPath && !mobile ? <RegisterKitDesktopStepper /> : null }
        { mobile ? <RegisterKitMobileStepper /> : null }

        <Paper
          sx={ {
            width: {
              xs: '100%',
              sm: 560
            }
          } }
        >
          <Routes>
            <Route index element={ <KitCodeForm /> } />
            <Route
              path='patient-info'
              element={
                (data.kit?.is_health_plan_kit || data.kit?.meta?.is_created_by_provider || data.kit?.isProviderRegistedKit)
                  ? (
                    <HealthPlanPatientInfoForm />
                    )
                  : (
                    <PatientInfoForm />
                    )
              }
            />
            <Route path='expired' element={ <ExpiredKit /> } />
            <Route path='screening' element={ <ScreeningForm /> } />
            <Route path='payment-method' element={ <PatientPaymentMethods /> } />
            <Route
              path='sample-collection'
              element={ <SampleCollectionForm /> }
            />
            <Route path='review' element={ <ReviewForm /> } />
            <Route path='success' element={ <RegisterConfirm /> } />
          </Routes>
        </Paper>
      </Stack>
    </Stack>
  </Container>
}

function HealthPlanPatientInfoForm () {
  const navigate = useNavigate()
  const { data, saveKitAction } = useRegisterKit()
  const { t } = useTranslation()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Stack
      component='form'
      onSubmit={ evt => {
        evt.preventDefault()
        const hasScreeningQuestions = data.kit && checkScreeningQuestions(
          data.kit.kitTypeConfig!, data.site
        )
        saveKitAction('PatientSelection')
        if (hasScreeningQuestions) {
          navigate('../screening')
          return
        }
        navigate('../sample-collection')
      } }
      sx={ { gap: 2 } }
    >
      <PatientInfoFormProgram patient={ data?.patientInfoForm?.patient || null } />
      <RegisterKitNavigation
        back={
          <Button
            fullWidth={ mobile }
            disabled
            sx={ {
              textDecoration: mobile ? 'underline' : 'none',
              '&:hover': { textDecoration: mobile ? 'underline' : 'none' },
              '&:active': { textDecoration: mobile ? 'underline' : 'none' }
            } }
          >
            { t('multiFormNavigation.back') }
          </Button>
        }
        next={
          <LoadingButton
            fullWidth={ mobile }
            type="submit"
          >
            { t('multiFormNavigation.next') }
          </LoadingButton>
        }
      />
    </Stack>
  )
}

export function VideoTutorial ({ stepOverride = false, autoOpen = false, btnText = '' }: { stepOverride?: boolean, autoOpen?: boolean, btnText?: string }) {
  const { step, data } = useRegisterKit()

  const vimeoID = data.site?.video_tutorial_config?.[data.kit?.kitTypeConfig?.prefix || '']?.registerKit?.vimeoID
  const isTutorialEnabled = data.site?.video_tutorial_config?.[data.kit?.kitTypeConfig?.prefix || '']?.registerKit?.isTutorialEnabled

  if ((!stepOverride && step.active === 0) || !isTutorialEnabled) return null
  if (!vimeoID) return null

  return <VideoTutorialButton vimeoID={ vimeoID } autoOpen={ autoOpen } btnText={ btnText } />
}

// export function ProductLink ({ overrideLink = null }) {
//   let productLink = null
//   if (!overrideLink) {
//     const { data } = useRegisterKit()
//     productLink = data.site?.product_link_config?.[data.kit?.kitTypeConfig?.prefix]?.productLink
//   } else {
//     productLink = overrideLink
//   }

//   if (!productLink) return null

//   return (
//     <Stack>
//       <Typography variant="body1">
//           <strong>If you have another 10 seconds...</strong>
//       </Typography>
//       <Stack gap={1} direction={'row'}
//           sx={{
//             alignItems: 'center',
//             justifyContent: 'space-between'
//           }}>
//         <Typography variant="body1">
//           Leave a review for our product!
//         </Typography>
//         <Button
//           variant='contained'
//           target="_blank"
//           href={productLink}
//         >
//           <StarIcon/> Leave a review
//         </Button>
//       </Stack>
//     </Stack>
//   )
// }

function VideoTutorialButton ({ vimeoID, autoOpen = false, btnText = '' }: { vimeoID: string, autoOpen?: boolean, btnText?: string }) {
  const [open, setOpen] = useState(autoOpen)
  const [videoTooltipOpen, setVideoTooltipOpen] = useState(false)
  const { t } = useTranslation()
  const { saveKitAction } = useRegisterKit()

  const handleDrawerAction = (action: boolean) => {
    analyticEventTracker(`(E) Video Tutorial ${action ? 'Opened' : 'Closed'}`, {
      category: 'Register kit'
    })
    const pageInfo = window.document.title || 'N/A'
    saveKitAction('videoTutorialAction', { info: `Patient video tutorial ${action ? 'opened' : 'closed'}`, pageInfo })
    setOpen(action)
  }

  useEffect(() => {
    // Set a timer to open the tooltip after 1000 ms
    const openTimer = window.setTimeout(() => {
      setVideoTooltipOpen(true)
    }, 1000)

    // Set a timer to close the tooltip after 6000 ms
    const closeTimer = window.setTimeout(() => {
      setVideoTooltipOpen(false)
    }, 6000)

    return () => {
      clearTimeout(openTimer)
      clearTimeout(closeTimer)
    }
  }, [])

  return <>
    <Tooltip
      title={ t('registerKit.videoTutorial.TooltipMessage') }
      open={ videoTooltipOpen }
      onOpen={ () => setVideoTooltipOpen(true) }
      onClose={ () => setVideoTooltipOpen(false) }
      placement="bottom-end"
      arrow
    >
      <Button startIcon={ <OndemandVideoRoundedIcon /> } onClick={ () => handleDrawerAction(true) }
        sx={ {
          color: '#000',
          width: { textDecoration: 'underline' },
          p: 0,
          ':hover': {
            bgcolor: 'transparent'
          }
        } }>
        { btnText }
      </Button>
    </Tooltip>
    <SwipeableDrawer open={ open } onClose={ () => handleDrawerAction(false) } onOpen={ () => {} }>
      <Stack sx={ { gap: 3 } }>
        <Typography variant='h4' component='h1'>{ t('registerKit.videoTutorial.title') }</Typography>
        <Typography>{ t('registerKit.videoTutorial.description') }</Typography>
        <Alert severity='info'>
          { t('registerKit.videoTutorial.info') }
        </Alert>
        <AppVideoPlayer videoId={ vimeoID } playerHeight={ '360px' } />
      </Stack>
    </SwipeableDrawer>
  </>
}

export function RegisterKitNavigation ({ back, next }: { back: ReactNode, next: ReactNode }) {
  const { step } = useRegisterKit()
  const { t } = useTranslation()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Stack
      sx={ {
        flexDirection: mobile ? 'column-reverse' : 'row',
        justifyContent: mobile ? 'center' : 'space-between',
        alignItems: mobile ? 'stretch' : 'center',
        gap: 1
      } }
    >
      { back }
      {
        mobile
          ? null
          : (<Typography
            variant='subtitle2'
            component='h3'
            sx={ theme => ({
              [theme.breakpoints.down('sm')]: {
                marginLeft: '-18px'
              }
            }) }
          >
            { t('multiFormNavigation.currentStepIndicator', { current: step.active + 1, total: step.total }) }
          </Typography>)
      }
      { next }
    </Stack>
  )
}

// Utils
const findPatientById = (patients: PatientI[], patientId: number) => patients.find(patient => patient.idMapper.findIndex((mapper) => +mapper.patient_id === +patientId) !== -1)

const handleExpirationNavigation = (
  expirationDate: string | undefined,
  route: string,
  navigate: NavigateFunction
) => {
  if (expirationDate) {
    const currentDate = new Date()
    const expiry = new Date(expirationDate)
    expiry.setDate(expiry.getDate() - 7)
    const isExpired = currentDate > expiry
    if (isExpired) {
      navigate(route, { replace: true })
      throw new Error('Kit expired')
    }
  }
}

const processKitById = async (
  kitId: string,
  patients: PatientI[],
  validateSiteCode: (siteCode: string) => Promise<{ data: SiteWithRetailI } | undefined>
) => {
  if (Number.parseInt(kitId)) {
    const { kit } = await api.kit.getKit(kitId)
    const siteResponse = kit?.site?.code ? await validateSiteCode(kit.site.code) : null
    const siteData = siteResponse ? await api.site.getSite(siteResponse?.data?.id as number) : null
    const payment = siteData ? sitePaymentMethod(siteData, kit.kitTypeConfig) : false

    if (siteResponse) {
      siteResponse.data.retail = payment === 'prepaid'
    }

    const patient = findPatientById(patients, kit.patient_id)

    return { kit, siteResponse, payment, patient }
  } else {
    const kitCode = kitId
    const response = await api.kit.check(kitCode, false)

    if (response) {
      const payment = sitePaymentMethod(response.site, response.kitTypeConfig)

      return {
        kit: response,
        siteResponse: null,
        payment,
        patient: findPatientById(patients, response?.patient_id || NaN)
      }
    }

    return { kit: null, siteResponse: null, payment: false, patient: null }
  }
}

// interfaces
interface RegisterKitContextI {
  data: RegisterKitDataI
  updateData: Dispatch<SetStateAction<RegisterKitDataI>>,
  saveKitAction: (pageInfo: string, additionalInfo?: object) => void
  step: {
    active: number
    total: number
    isPaymentStepRequired: boolean
    isScreeningQuestionsStepRequired: boolean
  }
}

export interface RegisterKitDataI {
  kitCodeForm: {
    kitcode: string
    sitecode: string | null
    isKitFulfilled: boolean
  }
  patientInfoForm: {
    patient?: PatientI | null
    supervision?: Supervision | undefined
  }
  screeningForm: {
    answers: Answers
  }
  insuranceDetailsForm: {
    insuranceTnC: boolean
    continuedWithoutInsurance: boolean
  }
  sampleCollectionInfoForm: {
    intendToCollect: null
    collectedDate: string
    collectedTime: string
    dobCheckbox: boolean
    nameCheckbox: boolean
    collectiondateCheckbox: boolean
  }
  reviewForm: {
    reviewTnCTwo: boolean
    reviewTnCDisclaimer: boolean
    fullName: string
  }
  kit: Kit | null
  payment: boolean | string
  insurance: {
    isEligible: boolean
    why: Record<string, string>
  }
  site: SiteI | null | undefined
}
