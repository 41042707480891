import React, { Dispatch, forwardRef, ReactEventHandler, ReactNode, SetStateAction, useState } from 'react'
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  Typography,
  useMediaQuery,
  Container as MuiContainer,
  Stepper as MuiStepper,
  RadioGroup as MuiRadioGroup,
  Tooltip,
  styled,
  tooltipClasses,
  Stack,
  ButtonBase,
  IconButton,
  SwipeableDrawer as MuiSwipeableDrawer,
  Dialog,
  Link,
  Chip,
  useTheme,
  SxProps,
  StepperProps,
  RadioGroupProps,
  TooltipProps,
  SwipeableDrawerProps,
  AccordionProps,
  AccordionSummaryProps,
  Alert,
  AccordionDetails
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import theme from 'theme'
import CloseIcon from '@mui/icons-material/Close'
import { api } from 'Core'
import DownloadIcon from '@mui/icons-material/Download'
import useDocumentTitle from 'hooks/useDocumentTitle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded'
import { convertToUSFormat } from 'utils/utilFuntions'
import { useTranslation } from 'react-i18next'
import { FormikProps } from 'formik'
import { Kit } from '../types/db/Kit'
import { Pharmacy as PharmacyI } from '../types/db/Consult'
import { KitFromLimsForResult } from './TestResult'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { KitFromLimsI } from '../types/KitFromLimsI'
import useAuth0 from 'hooks/useAuth0'
import NpsHeader from 'pages/NpsReview/NpsHeader'
import { pdf } from '@react-pdf/renderer'
import ResultsReportPDF from './ResultsReportPDF'
import { useWhiteLabelConfig } from '../utils/white-label/WhiteLabelConfig'
import { Navigate, Outlet } from 'react-router'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import BiotechIcon from '@mui/icons-material/Biotech'
import DOMPurify from 'dompurify'

export function FormWrap ({ children }: { children: JSX.Element }) {
  return (
    <Box
      sx={ {
        border: { xs: 'none', md: '2px solid' },
        borderColor: { xs: 'transparent', md: 'grey.300' },
        borderRadius: 5,
        p: {
          xs: 0,
          md: 3
        }
      } }
    >
      { children }
    </Box>
  )
}

export function FormCard ({
  children,
  title,
  caption,
  activeStep,
  totalSteps,
  handleNext,
  handleBack,
  hideButtons,
  loading,
  formik,
  ...props
}: FormCardProps) {
  const theme = useTheme()
  // @ts-ignore
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      sx={ {
        border: !mobile ? '1px solid #666666' : null,
        borderRadius: 5,
        backgroundColor: 'common.white',
        p: mobile ? 0 : 3,
        maxWidth: 700,
        margin: mobile ? 'auto' : null,
        // mx: mobile ? 1 : 'auto',
        fontSize: 14
        // textAlign: 'center'
      } }
      component='form'
      onSubmit={ formik.handleSubmit }
      noValidate
      autoComplete='off'
      { ...props }
    >
      <Typography variant='h2' mb={ 1 }>
        { title }
      </Typography>
      <Typography variant='subtitle1' component='h3' mb={ 1 }>
        { caption }
      </Typography>
      {/* {!hideStepper && <CustomizedStepper current={activeStep} />} */ }
      { children }
      { !hideButtons && !mobile && (
        <Box
          my={ 2 }
          sx={ {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          } }
        >
          <Button onClick={ handleBack } disabled={ activeStep === 0 }>
            Back
          </Button>
          <Typography variant='subtitle2' component='h3'>
            { formik.values.isPaid || formik.values.kitType === 'COVID01'
              ? activeStep > 3
                ? `Step ${activeStep} of ${totalSteps}`
                : `Step ${activeStep + 1} of ${totalSteps}`
              : `Step ${activeStep + 1} of ${totalSteps}` }
          </Typography>
          <LoadingButton
            loading={ formik.isSubmitting || loading }
            onClick={ handleNext }
            type='submit'
            variant='contained'
          >
            { activeStep === 5 ? 'Submit' : 'Next' }
          </LoadingButton>
        </Box>
      ) }
    </Box>
  )
}

export function TextButton ({ children, sx = {}, ...props }: TextButtonProps) {
  return (
    <Button
      disableTouchRipple
      sx={ {
        display: 'inline-block',
        color: 'primary.main',
        p: 0,
        '&, &:hover': {
          textDecoration: 'underline',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          background: 'none'
          // mb: '3px'
        },
        ...sx
      } }
      { ...props }
    >
      { children }
    </Button>
  )
}

export function Container (
  { children, sx = {}, fullWidth = false, ...props }: ContainerProps
) {
  return (
    <MuiContainer
      maxWidth={ fullWidth ? false : 'lg' }
      sx={ {
        p: { xs: '1.5rem .5rem', lg: fullWidth ? '40px 20px' : '40px 0' },
        minWidth: fullWidth ? 768 : 'initial',
        ...sx
      } }
      { ...props }
    >
      { children }
    </MuiContainer>
  )
}
// Children is a JSX element
export const Paper = forwardRef((props: Readonly<PaperProps>, ref: React.Ref<unknown>) => {
  const { children, sx = {}, ...rest } = props
  return (
    <Box
      { ...rest }
      ref={ ref }
      sx={ {
        padding: { xs: 2, md: 4 },
        backgroundColor: theme.palette.common.white,
        borderRadius: 2,
        display: children ? 'block' : 'none',
        ...sx
      } }
    >
      { children }
    </Box>
  )
})

export function Stepper (
  { children, sx = {}, ...props }: StepperProps) {
  return (
    <MuiStepper
      sx={ {
        '& .MuiStepIcon-root': {
          '&.Mui-active, &.Mui-completed': {
            color: 'success.main'
          }
        },
        '& .MuiStepLabel-label': {
          '&.Mui-active, &.Mui-completed': {
            color: 'success.main',
            fontWeight: 'bold'
          }
        },
        '& .MuiStepConnector-line': {
          color: 'black'
        },
        ...sx
      } }
      { ...props }
    >
      { children }
    </MuiStepper>
  )
}

export function RadioGroup (
  { children, sx = {}, ...props }: RadioGroupProps) {
  return (
    <MuiRadioGroup sx={ { gap: 1, ...sx } } { ...props }>
      { children }
    </MuiRadioGroup>
  )
}

export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip { ...props } arrow classes={ { popper: className } } />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 4
  }
}))

export function Notification ({ children, sx = {}, ...props }: NotificationProps) {
  return (
    <Stack
      sx={ {
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 3,
        px: 4,
        py: 5,
        borderRadius: 2,
        backgroundColor: '#E2EBE2',
        textAlign: { xs: 'center', sm: 'left' },
        ...sx
      } }
      role='alert'
      { ...props }
    >
      { children }
    </Stack>
  )
}

export function PageHeading ({
  children,
  sx = {},
  documentTitle = '',
  ...props
}: PageHeadingProps) {
  useDocumentTitle(documentTitle)
  return (
    <Typography variant='h4' component='h1' sx={ { p: { md: '0px 32px' }, textAlign: { xs: 'center', sm: 'left' }, ...sx } } { ...props }>
      { children }
    </Typography>
  )
}

export function CloseButton ({ sx = {}, ...props }) {
  return (
    <IconButton
      sx={ { position: 'absolute', top: 10, right: 10, ...sx } }
      { ...props }
    >
      <CloseIcon />
    </IconButton>
  )
}

export function SwipeableDrawer ({
  children,
  ref = undefined,
  sx = {},
  open,
  onClose,
  onOpen = () => {},
  width = 700
}: SwipeableDrawerProps & { width?: number }) {
  return (
    <MuiSwipeableDrawer
      ref={ ref }
      anchor='bottom'
      open={ open }
      onClose={ onClose }
      onOpen={ onOpen }
      sx={ {
        '& .MuiPaper-root': {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          height: '100%',
          maxHeight: { xs: '100%', md: '90%' },
          // minHeight: '500px',
          width: `min(100%, ${width}px)`,
          margin: 'auto'
        },
        ...sx
      } }
    >
      <Stack sx={ { position: 'relative' } }>
        <Puller onClose={ onClose } />
        { open ? <Box sx={ { p: { xs: 1, sm: 2, md: 4 } } }>{ children }</Box> : null }
      </Stack>
    </MuiSwipeableDrawer>
  )
}

function Puller ({ onClose }: { onClose: ReactEventHandler }) {
  return (
    <Stack
      direction='row'
      sx={ {
        alignItems: 'center',
        // padding: 1,
        borderTopLeftRadius: 8,
        borderTopRightRaduis: 8,
        justifyContent: 'center'
      } }
    >
      <CloseButton onClick={ onClose } />
    </Stack>
  )
}

export function DownloadResults ({ kit, admin }: DownloadResultsProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const whiteLabelConfig = useWhiteLabelConfig()

  const downloadReport = async () => {
    try {
      setIsLoading(true)
      let response

      if (admin) {
        response = await api.admin.newDownloadResult((kit as KitFromLimsI)?.sample_name || (kit as Kit)?.code)
      } else {
        response = await api.kit.downloadResult(kit?.id as string)
      }

      // Ensure response and lisEndPoint are valid
      if (!response || !response.lisEndPoint) throw new Error('Type not found in response')

      let pdfBlob

      // Handle response based on the lisEndPoint
      if (response.lisEndPoint === 'SHK LIMSabc') {
        if (!response.buffer) throw new Error('No buffer found in response')

        // Convert base64 string back to a Blob
        const byteCharacters = atob(response.buffer)
        const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0))
        const byteArray = new Uint8Array(byteNumbers)
        pdfBlob = new Blob([byteArray], { type: 'application/pdf' })
      } else {
        if (!response.data) throw new Error('No data found in response')

        const { kit, analytes, kitTypeConfig, patient, samples, site } = response.data

        // Generate PDF using your ResultsReportPDF component
        const asPdf = pdf(
          <ResultsReportPDF
            kit={ kit }
            analytes={ analytes }
            kitTypeConfig={ kitTypeConfig }
            patient={ patient }
            samples={ samples }
            site={ site }
            whiteLabelConfig={ whiteLabelConfig }
          />
        )

        pdfBlob = await asPdf.toBlob()
      }

      // Create a URL for the Blob and open it in a new tab
      const url = window.URL.createObjectURL(pdfBlob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${(kit as KitFromLimsI)?.sample_name || (kit as Kit)?.code} Lab Report.pdf`
      link.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.log(error)

      dispatch(showSnackBar({
        show: true,
        message: t('account.alert.error'),
        severity: 'error'
      }))
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <LoadingButton
      sx={ { textDecoration: 'underline' } }
      onClick={ downloadReport }
      loading={ isLoading }
      startIcon={ <DownloadIcon /> }
      disabled={ isLoading }
    >
      { t('testResult.test.download.button') }
    </LoadingButton>
  </>
  )
}

export function PaperWithCloseButton ({ onClose, children, sx = {}, top = 35, right = 20 }: PaperWithCloseButtonProps) {
  return (
    <Paper sx={ { position: 'relative', background: '#F7F7F7', ...sx } }>
      <IconButton
        sx={ { position: 'absolute', top, right } }
        onClick={ onClose }
      >
        <CloseIcon />
      </IconButton>
      { children }
    </Paper>
  )
}

export function Modal ({ open, onClose, children, showCloseButton = true, title, ...props }: ModalProps) {
  const closeButtonSx: SxProps = {
    position: 'relative',
    top: 0,
    right: 0
  }

  return (
    <Dialog open={ open } onClose={ onClose } fullWidth { ...props }>
      <Paper>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={ {
            mb: 2
          } }
        >
          {
            title && (<Typography variant='h5' component='h1'>{ title }</Typography>)
          }
          { showCloseButton && (
            <CloseButton onClick={ onClose } sx={ title ? closeButtonSx : undefined } />
          ) }
        </Stack>
        { children }

      </Paper>
    </Dialog>
  )
}

export function MenuItemDescription ({ title, description, ...props }: MenuItemDescriptionProps) {
  return (
    <Stack { ...props }>
      <Typography>{ title }</Typography>
      <Typography sx={ { color: '#00000099', fontSize: 12 } }>
        { description }
      </Typography>
    </Stack>
  )
}

export function Accordion (
  { children, sx = {}, ...props }: AccordionProps) {
  return (
    <MuiAccordion
      sx={ {
        boxShadow: 'none',
        backgroundColor: '#fff',
        '&.MuiAccordion-root:before': {
          display: 'none'
        },
        ...sx
      } }
      { ...props }
    >
      { children }
    </MuiAccordion>
  )
}

export function AccordionSummary ({
  children,
  editable = false,
  onEditClick = () => {},
  ...props
}: AccordionSummaryProps & { editable?: boolean, onEditClick?: ReactEventHandler }) {
  return (
    <MuiAccordionSummary expandIcon={ <ExpandMoreIcon /> } { ...props }>
      <Stack
        sx={ {
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        } }
      >
        { children }

        { editable
          ? (<IconButton
            onClick={ e => {
              e.stopPropagation()
              onEditClick && onEditClick(e)
            } }
          >
            <EditIcon />
          </IconButton>)
          : null }
      </Stack>
    </MuiAccordionSummary>
  )
}

export function Definition ({ title, children, ...props }: DefinitionProps) {
  return (
    <Typography sx={ { fontSize: 'inherit' } } { ...props }>
      <Typography sx={ { fontWeight: 700, fontSize: 'inherit' } } component='span'>
        { title }
      </Typography>{ ' ' }
      { children }
    </Typography>
  )
}

export function CardPaymentDetails ({ squarePaymentToken }: CardPaymentDetailsProps) {
  const { card, billing } = squarePaymentToken.details
  return (
    <Stack sx-={ { gap: 1 } }>
      <Definition title='Card:'>
        { card.brand } ends with { card.last4 }
      </Definition>
      <Definition title='Expires at:'>
        { card.expMonth }/{ card.expYear }
      </Definition>
      <Definition title='Postal code:'>{ billing.postalCode }</Definition>
    </Stack>
  )
}

export function TestIcon ({ kit }: { kit: Kit }) {
  return (
    <Box>
      <Box
        sx={ {
          height: 40,
          width: 40,
          borderWidth: 6,
          borderStyle: 'solid',
          borderColor: '#333333',
          borderRadius: '50%',
          background: kit.kitTypeConfig?.color
        } }
      />
    </Box>
  )
}

export function Pharmacy ({ pharmacy }: { pharmacy: PharmacyI }) {
  return (
    <Paper sx={ { backgroundColor: 'grey.100' } }>
      <Stack sx={ { gap: 14 / 8 } }>
        <Typography variant='h6'>{ pharmacy.name }</Typography>
        <Stack sx={ { gap: 12 / 8 } }>
          <Typography
            variant='body2'
            sx={ {
              color: 'rgba(0,0,0,0.6)',
              display: 'flex',
              alignItems: 'center',
              gap: 1.5
            } }
          >
            <PlaceRoundedIcon />
            { pharmacy.line1 }, { pharmacy.line2 ? pharmacy.line2 + ',' : null }{ ' ' }
            { pharmacy.city } { pharmacy.state } { pharmacy.zip }
          </Typography>
          { pharmacy.phone_number
            ? <Link
              variant='body2'
              sx={ {
                color: 'rgba(0,0,0,0.6)',
                display: 'flex',
                alignItems: 'center',
                gap: 1.5
              } }
              href={ `tel:+1 ${convertToUSFormat(pharmacy.phone_number)}` }
            >
              <PhoneRoundedIcon />
              +1 { convertToUSFormat(pharmacy.phone_number) }
            </Link>
            : null }
        </Stack>
      </Stack>
    </Paper>
  )
}

export function CardWrap ({ children, greyBackground = false, sx = {}, onClick = () => {} }: CardWrapProps) {
  return <Stack
    tabIndex={ 0 }
    onClick={ () => onClick() }
    sx={ (theme) => ({
      alignItems: { sm: 'center' },
      flexDirection: {
        sm: 'row',
        xs: 'column'
      },
      flexWrap: 'wrap',
      gap: { xs: 2, sm: 1, md: 0.5 },
      '&, &:first-of-type, &:last-of-type': {
        borderRadius: 2
      },
      px: 2,
      py: 3,
      backgroundColor: greyBackground
        ? 'grey.100'
        : theme.palette.common.white,
      ...sx
    }) }
  >{ children }</Stack>
}

export function TabWrapper ({ children, sx = {}, onClick = () => {}, tabIndex = -1 }: TabWrapperProps) {
  return <ButtonBase
    tabIndex={ tabIndex }
    onClick={ (e) => onClick(e) }
    sx={ {
      ...sx
    } }
  >
    { children }
  </ButtonBase>
}

export function KitBadge ({ kit, patientString = '', moreThanOnePatient = true }: { kit: Kit, patientString?: string, moreThanOnePatient?: boolean }) {
  // Section 1: Kit Type, Kit Code, Patient Name
  const dispatch = useDispatch()
  const isSpecialTypeKit = kit.is_health_plan_kit || kit.isProgramKit
  const hasCollectionDate = Boolean(kit?.collected_at)

  let textToRender = kit?.code || ''
  if (moreThanOnePatient) {
    if (!kit?.code) {
      textToRender += patientString
    } else {
      textToRender += patientString ? ' | ' + (!hasCollectionDate ? patientString : 'Test Date: ' + moment(kit?.collected_at).format('MM/DD/YY')) : ''
    }
  } else {
    textToRender += isSpecialTypeKit ? '' : (hasCollectionDate ? ' | Test Date: ' + moment(kit?.collected_at).format('MM/DD/YY') : '')
  }
  return (
    <Stack
      sx={ {
        alignItems: 'start',
        width: { sm: 290 }
      } }
    >

      <Typography variant='subtitle1' component='h3' fontWeight={ 700 }>
        { kit.kitTypeConfig ? kit.kitTypeConfig.name : null }
      </Typography>
      <Stack flexDirection={ 'row' } >
        <Typography onClick={ (e) => {
          const textToCopy = textToRender
          navigator.clipboard.writeText(textToCopy)
          dispatch(showSnackBar({
            show: true,
            message: 'Details copied to clipboard!',
            severity: 'success'
          }))
          e.stopPropagation()
        } } sx={ { color: theme.palette.primary.light, fontSize: 12 } }>
          { textToRender }
        </Typography>
      </Stack>
    </Stack>
  )
}

const defaultDurationOptions = [
  { label: '3M', value: 3 },
  { label: '6M', value: 6 },
  { label: 'All', value: 12 }
]

export function Durations ({ durationOptions = defaultDurationOptions, value, onChange }: DurationsProps) {
  return <RadioGroup
    name='duration'
    aria-labelledby='duration'
    sx={ { flexDirection: 'row', alignItems: 'center', gap: 1 } }
  >
    { durationOptions.map(currentDuration => {
      const checked = value.value === currentDuration.value
      return (
        <Chip
          component={ Button }
          key={ currentDuration.label }
          variant={ checked ? 'filled' : 'outlined' }
          label={ currentDuration.label }
          sx={ { cursor: 'pointer' } }
          onClick={ () => onChange(currentDuration) }
        />
      )
    }) }
  </RadioGroup>
}

export function SHKPoweredByLogo () {
  return (
    <Typography variant='h4' component='h1' sx={ { textAlign: 'center' } }>
      <Stack direction={ 'row' } justifyContent={ 'center' }>
        <Stack
          component='img'
          src={ '/assets/poweredByText.svg' }
          alt={ 'logo Powered By SHK' }
          sx={ { m: '5px' } }
        />
        <Stack
          component='img'
          src={ '/assets/logoPoweredBy.svg' }
          alt={ 'logo Powered By SHK' }
          sx={ { m: '5px' } }
        />
      </Stack>
    </Typography>
  )
}

export function PoweredByShkLogo () {
  return (
    <Typography variant='h4' component='h1' sx={ { textAlign: 'center' } }>
      <Stack direction={ 'row' } justifyContent={ 'center' }>
        <Stack
          component='img'
          src={ '/assets/poweredByText.svg' }
          alt={ 'logo Powered By SHK' }
          sx={ { m: '5px' } }
        />
        <Stack
          component='img'
          src={ '/assets/logoPoweredBy.svg' }
          alt={ 'logo Powered By SHK' }
          sx={ { m: '5px' } }
        />
      </Stack>
    </Typography>
  )
}

export function PageNotFound () {
  const { t } = useTranslation()
  const { logout } = useAuth0()

  const navigateUser = () => {
    logout('Page not found', { actionBy: 'User Action', reason: 'Inappropriate permissions/ Invalid Url' })
  }

  return (
    <Stack >
      <NpsHeader />
      <Stack
        sx={ { gap: 3, width: '100%', maxWidth: 550, m: 'auto', marginTop: '10px' } }
        component='form'
        autoComplete='off'
      >
        <Typography variant='h4' component='h1' sx={ { textAlign: 'center' } }>{ t('notFound.title') }</Typography>
        <Alert severity='warning' sx={ {
          alignItems: 'center'
        } }>
          { t('notFound.warningText') }
        </Alert>
        <Link
          sx={ { cursor: 'pointer' } }
          onClick={ () => navigateUser() }
        >
          { t('notFound.goBackText') }
        </Link>
      </Stack>
    </Stack>
  )
}

export const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip { ...props } classes={ { popper: className } } />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none'
  }
})

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ condition, redirectTo }) => {
  return condition ? <Outlet /> : <Navigate to={ redirectTo } />
}

export const ExpandGroupComponent = ({ datas, headerText }: ExpandGroupComponentProps) => {
  return (
    <Stack gap={ 2 }>
      <Typography variant="h6" component="h6">
        { headerText }
      </Typography>
      <Stack gap={ 1 }>
        { datas.map((data) => (
          <Accordion
            key={ data.summary }
            sx={ {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            } }
          >
            <AccordionSummary>
              <Typography variant="body1" mt={ 1 } dangerouslySetInnerHTML={ { __html: data?.summary || '' } } />
            </AccordionSummary>
            <AccordionDetails>
              <ReactMarkdown remarkPlugins={ [remarkGfm] } >
                { data?.detail || '' }
              </ReactMarkdown>
            </AccordionDetails>
          </Accordion>
        )) }
      </Stack>
    </Stack>
  )
}

export const SkuDisplayCard = ({ key, title, image, body, type = 'default', bullet = true }: SkuDisplayCardProps) => {
  return (
    <Stack key={ key } display="flex" alignItems="center" gap={ 2 }
      sx={ {
        background: '#F7F7F7',
        padding: 3,
        borderRadius: 1,
        flexDirection: {
          xs: 'column',
          md: 'row'
        }
      } }
    >
      { image || type === 'checkbox' ? null : <BiotechIcon sx={ { bgcolor: '#333', color: '#fff', padding: '0 2px', height: '28px', width: '28px' } } /> }
      <Stack>
        <Typography variant='h6'>{ title }</Typography>
        { bullet
          ? (
            <ul>
              <Stack padding={ 1 }>
                { body?.map((data, xid: number) => (
                  <li key={ xid }><Typography variant='body1' dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(data || '') } }></Typography></li>
                ))
                }
              </Stack>
            </ul>
            )
          : (
            <Stack padding={ 1 }>
              { body?.map((data, xid: number) => (
                <Typography key={ xid } variant='body1' dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(data || '') } }></Typography>
              ))
              }
            </Stack>
            )
        }
      </Stack>
      { image
        ? (
          <Box
            sx={ { alignSelf: 'center' } }
            width='100%'
            component='img'
            maxWidth={ '170px' }
            src={ image || '' }
            alt={ 'Sku Bag' }
          />
          )
        : null
      }
    </Stack>
  )
}

// Interfaces

interface SkuDisplayCardProps {
  key: number;
  title: string;
  image?: string;
  body: string[];
  type?: string;
  bullet?: boolean;
}

interface ExpandGroupComponentProps {
  headerText: string;
  datas: {
    summary: string
    detail: string
  }[];
}

interface ProtectedRouteProps {
  condition: boolean;
  redirectTo: string;
}
interface FormCardProps {
  children: React.ReactNode
  title: string
  caption: string
  activeStep: number
  totalSteps: number
  handleNext: () => void
  handleBack: () => void
  hideButtons: boolean
  loading: boolean
  formik: FormikProps<any>
}

interface TextButtonProps {
  children: React.ReactNode
  component?: React.ElementType
  disabled?: boolean
  onClick: () => void
  sx?: SxProps
}

interface ContainerProps {
  children: React.ReactNode
  sx?: SxProps
  fullWidth?: boolean
}

interface PaperProps {
  children: React.ReactNode
  sx?: SxProps
  component?: React.ElementType
  height?: number | string
}

interface PageHeadingProps {
  children: React.ReactNode
  sx?: SxProps
  documentTitle: string
}

interface PaperWithCloseButtonProps {
  onClose: ReactEventHandler
  children: ReactNode
  sx?: SxProps
  top?: number
  right?: number
}

interface ModalProps {
  open: boolean
  onClose: Dispatch<SetStateAction<boolean>> | (() => void)
  children: ReactNode
  showCloseButton?: boolean
  title?: string
  sx?: SxProps
}

interface MenuItemDescriptionProps {
  title: string
  description: string
}

interface DefinitionProps {
  title: string
  children: ReactNode
  sx?: SxProps,
  component?: React.ElementType | string
}

interface CardPaymentDetailsProps {
  squarePaymentToken: {
    details: {
      card: {
        brand: string
        last4: string
        expMonth: string
        expYear: string
      }
      billing: {
        postalCode: string
      }
    }
  }
}

interface CardWrapProps {
  children: ReactNode
  greyBackground?: boolean
  sx?: any,
  onClick?: () => void
}

interface TabWrapperProps {
  children: React.ReactNode
  sx?: SxProps;
  tabIndex?: number;
  onClick?: (e: any) => void;
}

interface DurationsProps {
  durationOptions?: { label: string, value: number }[]
  value: { label: string, value: number }
  onChange: (value: { label: string, value: number }) => void
}

interface NotificationProps {
  children: React.ReactNode
  sx?: SxProps
}

interface DownloadResultsProps {
  kit: Kit | KitFromLimsForResult | KitFromLimsI | undefined
  admin?: boolean | undefined
}
