import React, { useState } from 'react'
import moment from 'moment'
import { OpenInNew } from '@mui/icons-material'
import { Link, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Modal } from 'components'
import { api } from 'Core'
import jwtUtil from 'Core/utils/jwtUtil'
import { getSubDomain } from 'utils/routerUtils'
import useAuth0 from 'hooks/useAuth0'
import { useTranslation } from 'react-i18next'

type LegalInfo = {
  value: {
    effectiveDate: number
  }
}

const TnCModal = () => {
  const { t } = useTranslation()

  const { data: legalInfo } = useQuery<LegalInfo>(['legalInfo'], async () => await api.content.getByKey('legalInfo'), { refetchOnWindowFocus: false })
  const { logout, user } = useAuth0()
  const [open, setOpen] = useState(true)
  const mutation = useMutation(() => api.auth.updateTncData(),
    {
      onSuccess: () => {
        setOpen(false)
      }
    }
  )

  const logoutMutation = useMutation(logout)

  const handleSubmit = () => { mutation.mutate() }

  const handleLogout = () => {
    // @ts-ignore
    logoutMutation.mutate('Terms and Conditions Modal', { actionBy: 'User Action', reason: 'User did not comply with terms and conditions' })
  }

  if (getSubDomain(window.location.hostname) === 'app' && legalInfo && user) {
    const todaysDate = moment()
    const effectiveDate = moment.unix(legalInfo.value.effectiveDate)

    if (todaysDate.isAfter(effectiveDate)) {
      const userlegalData = jwtUtil.getLegalInfo()
      const userTncDate = userlegalData.tncDate ? moment.unix(userlegalData.tncDate) : null

      if (!userTncDate) {
        api.auth.updateTncData()
      }

      if (userTncDate && userTncDate.isBefore(effectiveDate)) {
        return (
          <Modal
            open={ open }
            onClose={ () => {} }
            showCloseButton={ false }
          >
            <Stack gap={ 2 }>
              <Typography variant='h4'>{ t('dashboard.termsAndConditionsUpdate.title') }</Typography>
              <Stack gap={ 1 }>
                <Typography>{ t('dashboard.termsAndConditionsUpdate.subtitle') }</Typography>
                <Stack>
                  <ul>
                    <li>
                      <Link
                        href='https://www.simplehealthkit.com/terms/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        { `${t('login.termsAndCondition')} ` }
                        <OpenInNew fontSize='inherit' color='inherit' />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href='https://www.simplehealthkit.com/privacy-policy/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        { `${t('footer.privacyPolicy')} ` }
                        <OpenInNew fontSize='inherit' color='inherit' />
                      </Link>
                    </li>
                  </ul>
                </Stack>
                <Typography>
                  { t('dashboard.termsAndConditionsUpdate.description', { effectiveDate: effectiveDate.format('DD MMM YYYY') }) }
                </Typography>
              </Stack>
              <Stack gap={ 2 }>
                <LoadingButton
                  fullWidth
                  loading={ mutation.isLoading }
                  variant='contained'
                  onClick={ handleSubmit }
                >
                  { t('dashboard.termsAndConditionsUpdate.accept') }
                </LoadingButton>
                <LoadingButton
                  fullWidth
                  variant='outlined'
                  onClick={ handleLogout }
                  loading={ logoutMutation.isLoading }
                >
                  { t('header.account.mobile.logout') }
                </LoadingButton>
              </Stack>
              <Typography variant='body2'>
                { `${t('dashboard.termsAndConditionsUpdate.help')} ` }
                <Link href='mailto:help@simplehealthkit.com'>
                  help@simplehealthkit.com
                </Link>
                .
              </Typography>
            </Stack>
          </Modal>
        )
      }
    }
  }

  return null
}

export default TnCModal
