import React from 'react'
import { AccountCircleRounded, Logout } from '@mui/icons-material'
import { visibilityStates } from '../utils'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import CachedIcon from '@mui/icons-material/Cached';
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useTranslation } from 'react-i18next'
import { UserPermissionsI } from '..'
import { useWhiteLabelConfig } from '../../../utils/white-label/WhiteLabelConfig'
import { getUserDomains } from 'pages/Profile/ChoosePortal'
import { isShkDomain } from 'utils/utilFuntions'

export const useAccountSettings = () => {
  const { t } = useTranslation()
  const whiteLabelConfig = useWhiteLabelConfig()
  const appDomain = whiteLabelConfig?.variables?.appDomain || process.env.REACT_APP_DOMAIN
  const userDomains = getUserDomains()
  const isShk = isShkDomain()

  const accountActions: AccountAction[] = [
    {
      title: t('header.account.settings'),
      mobileTitle: t('header.account.mobile.settings'),
      icon: <AccountCircleRounded fontSize='small' />,
      mobileIcon: <PersonIcon />,
      navigateTo: '/account',
      visibleOn: {
        page: [visibilityStates.page.portalPage],
        role: [],
        features: []
      }
    },
    {
      title: 'Account',
      mobileTitle: 'Account',
      icon: <AccountCircleRounded fontSize='small' />,
      mobileIcon: <AccountCircleRounded fontSize='small' />,
      navigateTo: '/account',
      visibleOn: {
        page: [
          visibilityStates.page.adminPage,
          visibilityStates.page.managePage,
          visibilityStates.page.providerPage,
          visibilityStates.page.healthPortal
        ],
        role: [],
        features: []
      }
    },
    {
      title: 'Go to App',
      mobileTitle: 'App',
      icon: <NavigateNextIcon fontSize='small' />,
      mobileIcon: <NavigateNextIcon fontSize='small' />,
      navigateTo: '/',
      onClick: () => { window.open(`//app.${appDomain}`, 'shkportalapp') },
      visibleOn: {
        page: [...Object.values(visibilityStates.page)].filter(page => page === visibilityStates.page.accountPage),
        role: [],
        features: []
      }
    },
    {
      title: t('homepage.switchPortal'),
      mobileTitle: t('homepage.switchPortal'),
      icon: <CachedIcon fontSize='small' />,
      mobileIcon: <CachedIcon />,
      navigateTo: '/choose-portal',
      visibleOn: {
        page: Object.values(visibilityStates.page),
        role: [],
        features: [],
        isHidden: !userDomains.length || !isShk
      }
    },
    {
      title: t('header.account.logout'),
      mobileTitle: t('header.account.mobile.logout'),
      icon: <Logout fontSize='small' />,
      mobileIcon: <LogoutIcon />,
      navigateTo: '#',
      visibleOn: {
        page: Object.values(visibilityStates.page),
        role: [],
        features: []
      }
    }
  ]

  return accountActions
}

export interface AccountAction {
  title: string
  mobileTitle: string
  icon: JSX.Element
  mobileIcon: JSX.Element
  navigateTo: string
  onClick?: () => void
  visibleOn: UserPermissionsI
}
