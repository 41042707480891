import { Stack, Tab, Tabs, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction, SyntheticEvent } from 'react'

const LanguageTabs = ({ title, children, tabValue = 0, setTabValue }: Props) => {
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Stack>
      { title && (<Typography variant='h6'>{ title }</Typography>) }
      <Stack gap={ 2 }>
        <Tabs
          value={ tabValue }
          onChange={ handleChange }
          aria-label="Tabs to select the translation language"
          sx={ {
            '& button': {
              textTransform: 'none'
            }
          } }
        >
          <Tab label="English" { ...a11yProps(0) } />
          <Tab label="Spanish" { ...a11yProps(1) } />
        </Tabs>

        { children }
      </Stack>
    </Stack >
  )
}

function a11yProps (index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

interface Props {
  title?: string
  children?: React.ReactNode
  tabValue: number
  setTabValue: Dispatch<SetStateAction<number>>
}

export default LanguageTabs
