import React, { useEffect, useState } from 'react'
import { getSchemaValidation } from '../RegisterPatient/utils'
import MedicalData from '../RegisterPatient/components/MedicalData'
import { showSnackBar } from 'state/actions'
import { LoadingButton } from '@mui/lab'
import useAuth0 from 'hooks/useAuth0'
import { api } from 'Core'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography
} from '@mui/material'
import {
  convertToUSFormat,
  formatDate,
  limsFormatDate
} from 'utils/utilFuntions'
import PaymentInfo from 'components/PatientComponents/PaymentInfo'
import useFormik from 'hooks/useFormik'
import MultiStepNavigation from 'components/MultiStepNavigation'
import { insuranceNames } from 'components/PatientComponents/data/insuranceNames'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  ethnicity as ethnicityMapper,
  gender as genderMapper,
  race as raceMapper,
  relation as relationMapper
} from 'components/PatientComponents/data'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { PatientI } from '../../types/db/PatientI'
import { SiteI } from '../../types/db/SiteI'
import { Kit } from '../../types/db/Kit'
import { useGetSite } from '../../hooks/query/useGetSite'
import { PaymentTypesI } from '../../utils/insurance'
import { queryKeys } from '../../Core/utils/queryKeys'
import { useLocation } from 'react-router'

const insuranceTypes = [
  'commercial',
  'medicare',
  'medicaid',
  'insured',
  'insurance'
]

function EditPatient ({
  patient,
  siteCode = '',
  onEdit = () => {},
  isAdmin,
  step = 0,
  onFormChange,
  kit
}: Props) {
  const location = useLocation()
  const { user } = useAuth0()
  const { data: site } = useGetSite(patient?.client_site_id ? +patient?.client_site_id : undefined)
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(step)
  const [paymentType, setPaymentType] = useState<keyof PaymentTypesI>(patient?.insurance?.insurance_type || 'prepaid')
  const [loading, setLoading] = useState(true)

  const [copyAddress, setCopyAddress] = useState(patient?.insurance?.insured_address?.delivery_line_1 === patient?.address?.delivery_line_1)
  const [validation2, setValidationSchema2] = useState<'dl' | 'ssn' | 'insured' | null>(isAdmin ? 'dl' : null)

  const { data: isInsuranceEnabled, refetch } = useQuery({
    queryKey: [queryKeys.sites.insurance, siteCode],
    queryFn: () => api.kit.getInsurance(siteCode, String(kit?.kitTypeConfigId || kit?.kitTypeConfig?.id)),
    enabled: !!siteCode && (!!kit?.kitTypeConfigId || !!kit?.kitTypeConfig?.id),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    initialData: { insurance: false }
  })

  useEffect(() => {
    if (!siteCode || !kit) return
    refetch()
  }, [siteCode])

  useEffect(() => {
    if (!kit) return

    if (isInsuranceEnabled.insurance) {
      setPaymentType('insured')
      setValidationSchema2('insured')
    } else {
      setPaymentType('prepaid')
      setValidationSchema2(null)
    }
  }, [siteCode, isInsuranceEnabled])

  const getSite = (siteId: string) => {
    const userSites =
      (window && window.sessionStorage.getItem(user?.sub + '-sites')) || ''

    const sitesData = JSON.parse(userSites)

    if (sitesData?.data) {
      const sites: SiteI[] = sitesData.data
      const site = sites.find((site) => +site.id === +siteId)
      return site
    }
  }

  // const { patientMinAge, supervisionMinAge } = getMinAge(kit, site)
  // const canUseWithSupervision = getCanUseWithSupervision(kit, site)

  let validationSchema = null
  const schemas = getSchemaValidation()

  if (activeStep === 1) {
    if (paymentType === 'insured') {
      validationSchema = schemas[2].insured.validationSchema
    } else if (paymentType === 'uninsured') {
      if (validation2 === 'ssn') {
        validationSchema = schemas[2].uninsured.ssn.validationSchema
      } else if (validation2 === 'dl') {
        validationSchema = schemas[2].uninsured.dl.validationSchema
      }
    }
  } else if (activeStep === 0) {
    const customField = site?.register_patient?.custom_field
    if (customField?.required) {
      const customValidation = yup.object({
        [customField?.value]: yup
          .string()
          .required('profile.editPatient.validation.labelRequired')
      })
      validationSchema = schemas[1].validationSchema
      validationSchema = validationSchema.concat(customValidation)
    } else {
      validationSchema = schemas[1].validationSchema
    }
  }

  const queryClient = useQueryClient()

  const customField = site?.register_patient?.custom_field

  const formik = useFormik({
    initialValues: {
      token: '',
      registeredType: '',
      patientId: patient?.id,
      [patient?.custom_field?.label || 'customLabel']: patient?.custom_field?.value || '',
      [customField?.value || 'customValue']: patient?.custom_field?.value || '',
      insuranceType: patient?.insurance?.type_of_registration,
      locationCode: siteCode || getSite(patient?.client_site_id || '')?.code || '',
      firstName: patient?.first_name || '',
      lastName: patient?.last_name || '',
      sexAssignedAtBirth: patient?.sex_assigned_at_birth || '',
      medicalAddress: {
        addressLine1: patient?.address?.delivery_line_1 || '',
        city: patient?.address?.city_name || '',
        state: patient?.address?.state_abbreviation || '',
        zipCode: patient?.address?.zipcode || '',
        plus4Code: patient?.address?.plus4_code || '',
        county: patient?.address?.county || '',
        country: patient?.address?.country || 'US',
        fips: ''
      },
      gender: patient?.gender || '',
      dob: formatDate(patient?.dob || '') || '',
      ethnicity: patient?.ethnicity || '',
      email: patient?.email || '',
      confirmedMail: patient?.email || '',
      phone: convertToUSFormat(patient?.phone || '') || '',
      race: patient?.race || '',
      answers: null,
      insured: {
        primaryInsuranceName:
          patient?.insurance?.insurance_name === 'CLIENT BILL'
            ? ''
            : patient?.insurance?.insurance_name || '',
        policyNumber:
          patient?.insurance?.policy_number === 'CLIENT BILL'
            ? ''
            : patient?.insurance?.policy_number,
        groupNumber: patient?.insurance?.group_number || '',
        patientRelationshipToInsured: patient?.insurance?.relationship || '',
        insuredFirstName: patient?.insurance?.insured_first_name || '',
        insuredLastName: patient?.insurance?.insured_last_name || '',
        insuredDOB: formatDate(patient?.insurance?.insured_dob || '') || '',
        insuredGender: patient?.insurance?.insured_gender || '',
        insuranceDisclaimer: false,
        billTypeId: patient?.insurance?.bill_id || ''
      },
      uninsured: {
        socialSecurityNo: patient?.insurance?.social_security_number || '',
        driverLicense: patient?.insurance?.driver_license || ''
      },
      insuredAddress: {
        addressLine1: patient?.insurance?.insured_address?.delivery_line_1 || '',
        city: patient?.insurance?.insured_address?.city_name || '',
        state: patient?.insurance?.insured_address?.state_abbreviation || '',
        zipCode: patient?.insurance?.insured_address?.zipcode || '',
        plus4Code: patient?.insurance?.insured_address?.plus4_code || '',
        county: patient?.insurance?.insured_address?.county || '',
        country: patient?.insurance?.insured_address?.country || '',
        fips: ''
      },
      notes: patient?.notes || '',
      isInsured:
        patient?.insurance?.type_of_registration ||
        (insuranceTypes.includes(patient?.insurance?.insurance_type?.toLowerCase() || '')
          ? 'insured'
          : 'uninsured'),
      guardianFirstName: patient?.guardian_first_name || '',
      guardianLastName: patient?.guardian_last_name || '',
      guardianDob: patient?.guardian_dob ? formatDate(patient?.guardian_dob) : ''
    },
    onSubmit: async (formData) => {
      try {
        if (activeStep === 0 && paymentType === 'insured') {
          setActiveStep(1)
          return
        }

        const insurances = insuranceNames
        const insuranceSelected = insurances.find(
          (ins) => ins.label === formData?.insured?.primaryInsuranceName
        )

        const patientData = {
          ...formData,
          guardian_first_name: formData.guardianFirstName,
          guardian_last_name: formData.guardianLastName,
          guardian_dob: formData.guardianDob
            ? limsFormatDate(formData.guardianDob)
            : '',
          dob: limsFormatDate(formData.dob),
          insured: {
            ...formData.insured,
            insuredDOB: formData.insured.insuredDOB
              ? limsFormatDate(formData.insured.insuredDOB)
              : '',
            primaryInsuranceId: insuranceSelected?.id,
            billTypeId: insuranceSelected?.bill_type_id
          },
          paymentType: formData.isInsured
        }

        const gender = genderMapper.find(
          (gender) => gender.name === formData.gender
        )
        const ethnicity = ethnicityMapper.find(
          (ethnicity) => ethnicity.name === formData.ethnicity
        )
        const race = raceMapper.find((race) => race.name === formData.race)
        const patientRelationshipToInsured = relationMapper.find(
          (relation) =>
            relation.name === formData.insured.patientRelationshipToInsured
        )
        const insuredGender = genderMapper.find(
          (gender) => gender.name === formData.insured.insuredGender
        )

        const adminPatientData = {
          medicalData: {
            address: {
              addressLine1: formData.medicalAddress.addressLine1,
              city: formData.medicalAddress.city,
              zipCode: formData.medicalAddress.zipCode,
              state: formData.medicalAddress.state,
              plus4Code: formData.medicalAddress.plus4Code,
              county: formData.medicalAddress.county
            },
            dob: limsFormatDate(formData.dob),
            firstName: formData.firstName,
            lastName: formData.lastName,
            sexAssignedAtBirth: formData.sexAssignedAtBirth,
            genderId: gender?.id || '',
            ethnicityId: ethnicity?.id || '',
            phone: formData.phone,
            raceId: race?.id || '',
            guardianDetails: {
              guardianFirstName: formData.guardianFirstName,
              guardianlLastName: formData.guardianLastName,
              guardianDob: formData.guardianDob
            }
          },
          insuranceData: {
            address: {
              addressLine1: formData.insuredAddress.addressLine1,
              city: formData.insuredAddress.city,
              zipCode: formData.insuredAddress.zipCode,
              state: formData.insuredAddress.state,
              plus4Code: formData.insuredAddress.plus4Code,
              county: formData.insuredAddress.county
            },
            primaryInsuranceId: insuranceSelected?.id,
            policyNumber: formData.insured.policyNumber,
            dob: limsFormatDate(formData.insured.insuredDOB),
            patientRelationshipToInsuredId:
              patientRelationshipToInsured?.id || '',
            lastName: formData.insured.insuredLastName,
            groupNumber: formData.insured.groupNumber,
            firstName: formData.insured.insuredFirstName,
            genderId: insuredGender?.id || '',
            driverLicense: formData.uninsured.driverLicense,
            socialSecurityNo: formData.uninsured.socialSecurityNo,
            registrationType: formData.isInsured
          },
          valuesToFindThePatientInTheDB: {
            firstName: patient?.first_name,
            lastName: patient?.last_name,
            dob: formatDate(patient?.dob || '')
          }
        }

        if (isAdmin) {
          if (formData?.[patient?.custom_field?.label as string]) {
            adminPatientData.medicalData[patient?.custom_field?.label as keyof typeof adminPatientData.medicalData] =
              formData?.[patient?.custom_field?.label as string]
          }
          await api.admin.updatePatientDetails(adminPatientData)
        } else {
          await api.patient?.updatePatient({
            data: {
              ...patientData,
              lims_id: patient?.lims_id,
              client_site_id: patient?.client_site_id,
              idMapper: patient?.idMapper
            }
          })
        }

        dispatch(
          showSnackBar({
            show: true,
            message: t('profile.editPatient.snackbar.success'),
            severity: 'success'
          })
        )

        await queryClient.invalidateQueries(['patients'])

        onEdit && onEdit()
      } catch (error) {
        console.error(error)
        dispatch(
          showSnackBar({
            show: true,
            message: t('profile.editPatient.snackbar.error'),
            severity: 'error'
          })
        )
      }
    },
    validationSchema
  })

  useEffect(() => {
    if (formik.values.patientId !== 'new' && paymentType === 'insured') {
      if (formik.values.insuranceType === 'client_bill') {
        setPaymentType('prepaid')
      } else if (formik.values.insuranceType === 'cash') {
        setPaymentType('cash')
      } else if (formik.values.insuranceType === 'insured') {
        setPaymentType('insured')
      } else if (formik.values.insuranceType === 'forgottenInsurance') {
        setPaymentType('forgottenInsurance')
      } else {
        setPaymentType('uninsured')
      }
    }
  }, [formik.values.patientId, formik.values.insuranceType])

  const dispatch = useDispatch()

  const init = async () => {
    try {
      const { values } = formik
      setLoading(true)
      if (patient?.custom_field) {
        formik.setFieldValue('customFieldValue', patient?.custom_field?.value)
      }
      if (values.insured.patientRelationshipToInsured === 'Self') {
        setCopyAddress(true)
      } else {
        setCopyAddress(
          patient?.insurance?.insured_address?.delivery_line_1 ===
          patient?.address?.delivery_line_1
        )
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    onFormChange && onFormChange()
  }, [activeStep])

  if (loading) {
    return (
      <Box
        sx={ {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh'
        } }
      >
        <CircularProgress sx={ { margin: 'auto' } } />
      </Box>
    )
  }

  // If the user is an admin and the location includes tests/create, every field should be disabled except the sexAssignedAtBirth field
  const isFieldDisabled = isAdmin && location.pathname.includes('tests/create')

  return (
    <Container sx={ { pt: 1, pb: 2 } }>
      <Stack
        gap={ 2 }
        component="form"
        onSubmit={ formik.handleSubmit }
        height="100%"
      >
        <Typography variant="h4" component="h1">
          { t('profile.editPatient.title') }
        </Typography>
        { activeStep === 0 && (
          <MedicalData
            formik={ formik }
            editMode
            site={ site }
            disableAllFields={ isFieldDisabled }
            isAdmin={ isAdmin }
          />
        ) }
        { activeStep === 1 && (
          <Stack>
            <Typography variant="h5" component="h2" mr="auto" mt={ 1 }>
              { t('profile.editPatient.insuranceDetails') }
            </Typography>
            <PaymentInfo
              formik={ formik }
              paymentType={ paymentType }
              setPaymentType={ setPaymentType }
              copyAddress={ copyAddress }
              setCopyAddress={ setCopyAddress }
              setValidationSchema2={ setValidationSchema2 }
              disableAllFields={ isFieldDisabled }
              isAdmin={ isAdmin }
              hideCheckBox={ false }
            />
          </Stack>
        ) }
        <MultiStepNavigation
          back={
            <Button
              type="button"
              disabled={ activeStep === 0 }
              onClick={ () => setActiveStep(0) }
            >
              { t('profile.editPatient.button.back') }
            </Button>
          }
          next={
            <LoadingButton
              variant="contained"
              type="submit"
              loading={ formik.isSubmitting }
            >
              { activeStep === 1 || (activeStep === 0 && !isInsuranceEnabled.insurance)
                ? t('profile.editPatient.button.update')
                : t('profile.editPatient.button.next') }
            </LoadingButton>
          }
          totalSteps={ isInsuranceEnabled.insurance ? 2 : 1 }
          currentStep={ activeStep + 1 }
        />
      </Stack>
    </Container>
  )
}

interface Props {
  patient: PatientI | undefined
  siteCode?: string | undefined
  onEdit?: () => void
  isAdmin: boolean
  step?: number
  onFormChange?: () => void | undefined
  kit?: Kit | undefined
}

export default EditPatient
