import auth0 from 'auth0-js'
import { WhiteLabelConfig } from '../types/db/SiteI'

export const auth = (runtimeEnv: WhiteLabelConfig['variables']) => {
  return new auth0.WebAuth({
    domain: runtimeEnv?.auth0Domain || process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientID: runtimeEnv?.auth0ClientId || process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    scope: 'openid email profile read:current_user',
    audience: `${runtimeEnv?.auth0Tenant || process.env.REACT_APP_AUTH0_TENANT}/api/v2/`
  })
}

export const isAppPortal = () => {
  const baseUrl = `app.${process.env.REACT_APP_DOMAIN}`
  // Check if the current hostname matches the base URL for the app portal
  return window.location.host === baseUrl
}
