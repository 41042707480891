import { AppBar, Box } from '@mui/material'
import AuthenticationModal from 'components/AuthenticationModal'
import Grid from '@mui/material/Unstable_Grid2'
import LogoutDialog from 'components/Header/components/LogoutDialog'
import React, { useState } from 'react'
import useAuth0 from 'hooks/useAuth0'
import cookieUtils from 'Core/utils/cookieUtils'
import moment from 'moment'
import AuthenticationBtn from './AuthenticationBtn'
import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import { useLocation, useParams } from 'react-router'
import { isValidImageUrl } from 'utils/isValidImageUrl'
import { LanguageSelect } from 'components/Header'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import { isShkDomain } from 'utils/utilFuntions'
import { useSearchParams } from 'react-router-dom'

const HeaderFreeTestsProgram = ({ hideAuthBth = false }) => {
  const { isLoading, user } = useAuth0()
  const whiteLabelConfig = useWhiteLabelConfig()

  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const params = useParams()
  const slug = params.slug
  const endPoint = params['*']

  const { data: programContent, isLoading: isProgramLoading } = useQuery(['program-content', slug], () => api.program.getProgramConfig(slug as string), {
    refetchOnWindowFocus: false
  })

  const [openAuthenticationModal, setOpenAuthenticationModal] = useState(false)
  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] = useState(false)

  const handleClose = () => setOpenAuthenticationModal(false)
  const handleOpen = () => {
    const urlEndpoint = endPoint === 'your-situation' ? endPoint : 'your-info'
    if (urlEndpoint === 'your-info') { window.sessionStorage.setItem('yourInfoLoad', 'true') }
    cookieUtils.setCookie('redirectTo', `${window.location.origin}/program/${slug}/${urlEndpoint}`, moment().add(1, 'day').utc())

    if (!isLoading && !user) {
      setSearchParams({ ...Object.fromEntries(searchParams), redirectTo: location.pathname })
      setOpenAuthenticationModal(true)
    }
    if (!isLoading && user) {
      setSearchParams({ ...Object.fromEntries(searchParams), redirectTo: location.pathname })
      setIsLogoutConfirmationOpen(true)
    }
  }

  const getLogo = () => {
    if (isProgramLoading) {
      return ''
    } else if (isShkDomain() && programContent?.logoUrl && isValidImageUrl(programContent?.logoUrl)) {
      return programContent?.logoUrl
    } else if (!isShkDomain()) {
      return whiteLabelConfig?.logoUrl || ''
    }

    return '/assets/reskinning/shk-logo.svg'
  }

  return (
    <AppBar
      position='static'
      style={{
        backgroundColor: whiteLabelConfig?.theme?.palette?.background?.default || '#ffffff',
        padding: '.5rem',
        height: 'clamp(5.25rem, 14vh, 5.8rem)',
        boxShadow:
          '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
      }}
    >
      <Grid
        container
        spacing={1}
        alignItems='center'
        maxWidth='75rem'
        width='100%'
        height='100%'
        margin='0 auto'
      >
        <Grid xs={2.5}></Grid>

        <Grid
          xs={7}
          height='100%'
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Box
            component='img'
            src={getLogo()}
            sx={{ height: '100%', maxHeight: '3rem', width: '100%', objectFit: 'contain' }}
            alt='Logo'
          />
        </Grid>

           <Grid xs={2.5} sx={{ textAlign: 'right' }}>
        {!hideAuthBth && endPoint !== 'review'
          ? <AuthenticationBtn handleOpen={handleOpen} />
          : null
          }
          <LanguageSelect />
          </Grid>

      </Grid>
      <AuthenticationModal handleClose={handleClose} open={openAuthenticationModal} />
      <LogoutDialog
        id='user-logout'
        keepMounted
        isLogoutConfirmationOpen={isLogoutConfirmationOpen}
        setIsLogoutConfirmationOpen={setIsLogoutConfirmationOpen}
      />
    </AppBar>
  )
}

export default HeaderFreeTestsProgram
