import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import useAuth0 from './useAuth0'

function useEmailVerificationPolling ({ onVerify = undefined, type = 'portal', token = null }: Props = {}) {
  const { getUserInfo, getAccessTokenSilently } = useAuth0()
  return useQuery(['email-verified-polling'],
    () => {
      if (type === 'portal') return getUserInfo()
      else return api.patient.getLimsEmailVerifiedDetails(token as string)
    },
    {
      refetchInterval: (userInfo) => {
        const isVerified = userInfo?.email_verified
        if (!isVerified) {
          return 10 * 1000
        }
        return false
      },
      onSuccess: (userInfo) => {
        const isVerified = userInfo?.email_verified
        if (isVerified) {
          getAccessTokenSilently(true).then(() => {
            onVerify && onVerify(userInfo)
          })
        }
      }
    })
}

interface Props {
  onVerify?: (userInfo: any) => void | undefined
  type?: 'portal' | 'lims'
  token?: string | null
}

export default useEmailVerificationPolling
