import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'

const SameDayRegistrationAlert = () => {
  const { t } = useTranslation()

  return <Alert severity='info' sx={ { alignItems: 'center' } }>
    { t('registerKit.patientDetails.sameDayKitRegister') }
  </Alert>
}
export default SameDayRegistrationAlert
