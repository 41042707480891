import {
  Typography,
  Stack,
  RadioGroup,
  useRadioGroup,
  Box,
  TextField
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { Paper } from 'components'
import debounce from 'lodash/debounce'
import React, { FormEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FeedbackFormI } from '../types/FeedbackFormI'
import { isShkDomain } from '../utils/utilFuntions'

function FeedbackForm ({
  onChange,
  title,
  totalPoints = 5,
  lowestLabel = '',
  highestLabel = '',
  hidePoints = false,
  hideDescription = false,
  hideDescriptionHelp = false,
  feature = '',
  backgroundColor = '#F7F7F7'
}: Props) {
  const { t } = useTranslation()
  const debouncedHandleFormChange = useCallback(debounce(onChange, 300), [
    onChange
  ])

  const handleFormChange = (evt: FormEvent<HTMLFormElement>) => {
    const formData = new window.FormData(evt.currentTarget)

    const values = Object.fromEntries(formData.entries())

    debouncedHandleFormChange({ ...values, feature } as FeedbackFormI)
  }

  const ratingButtons = []

  for (let i = 1; i <= totalPoints; i++) {
    ratingButtons.push(
      <RatingButton key={ i } value={ String(i) }>{ i }</RatingButton>
    )
  }

  const handleContactUsClick = () => {
    if (isShkDomain()) {
      (window as any).Beacon('toggle')
    } else {
      window.dispatchEvent(new Event('isWhiteLabelHelpOpen'))
    }
  }

  return (
    <Paper sx={ { backgroundColor } }>
      <Stack
        component='form'
        onChange={ handleFormChange }
        autoComplete='off'
        sx={ { gap: 3, alignItems: 'center' } }
      >
        <Typography component='h3' sx={ { fontWeight: 700 } }>
          { title }
        </Typography>
        {
          !hidePoints && (
            <Box sx={ {
              display: 'grid',
              gridTemplateColumns: { xs: '4rem 1fr 4rem', sm: '6rem 1fr 6rem' },
              alignItems: 'center',
              gap: { xs: '.5rem', sm: '1rem' }
            } }
            >
              <Typography
                variant='body2'
                sx={ {
                  fontWeight: 700,
                  color: '#00000099',
                  gridRow: { xs: '2/3', sm: '1/2' },
                  gridColumn: { xs: '1/2' },
                  textAlign: { xs: 'left', sm: 'right' }
                } }
              >
                { lowestLabel || t('feedbackForm.lowestLabel') }
              </Typography>
              <RadioGroup name='rating' sx={ { flexDirection: 'row', flexWrap: 'nowrap', gridColumn: { xs: '1/4', sm: '2/3' } } }>
                { ratingButtons }
              </RadioGroup>
              <Typography
                variant='body2'
                sx={ {
                  fontWeight: 700,
                  color: '#00000099',
                  gridRow: { xs: '2/3', sm: '1/2' },
                  gridColumn: { xs: '3/4' },
                  textAlign: { xs: 'right', sm: 'left' }
                } }
              >
                { highestLabel || t('feedbackForm.highestLabel') }
              </Typography>
            </Box>

          )
        }
        {
          !hideDescription && (
            <Stack gap={ 1 } sx={{ width: '100%' }}>
              <TextField
                label={ t('feedbackForm.descriptionLabel') }
                aria-label='experience'
                fullWidth
                multiline
                placeholder={ t('feedbackForm.descriptionLabel') }
                rows={ 3 }
                name='description'
              />
              {!hideDescriptionHelp && <Typography variant='caption' sx={ { color: '#00000099' } }>
                { t('feedbackForm.descriptionHelper') + ' ' }
                <Typography
                  onClick={ handleContactUsClick }
                  component='span'
                  variant='caption'
                  sx={ {
                    fontWeight: 700,
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  } }
                >
                  { t('feedbackForm.descriptionHelperBtn') }
                </Typography>
              </Typography>}
            </Stack>
          )
        }
      </Stack>
    </Paper>
  )
}

function RatingButton ({ children, value }: RatingButtonProps) {
  const radioGroup = useRadioGroup()
  const checked =
    !!radioGroup?.value && String(radioGroup.value) === String(value)

  if (!radioGroup) return null
  return (
    <Stack
      component='label'
      sx={ {
        backgroundColor: checked ? 'success.main' : '#00000099',
        color: '#ffffff',
        borderRadius: '50%',
        height: 32,
        width: 32,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
      } }
    >
      <Box
        name={ radioGroup.name }
        component='input'
        type='radio'
        checked={ checked }
        onChange={ evt => {
          radioGroup.onChange(evt, value)
        } }
        sx={ visuallyHidden }
        value={ value }
      />
      { children }
    </Stack>
  )
}

export interface Props {
  onChange: (evt: FeedbackFormI) => void
  title: string
  totalPoints?: number
  lowestLabel?: string
  highestLabel?: string
  hidePoints?: boolean
  hideDescription?: boolean
  hideDescriptionHelp?: boolean
  feature?: string
  backgroundColor?: string
}

export interface RatingButtonProps {
  children: React.ReactNode
  value: string
}

export default FeedbackForm
