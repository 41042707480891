import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Checkbox, FormControlLabel, IconButton, Link, Modal, Radio, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Container, Paper, RadioGroup } from 'components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getSubdomain } from 'utils/utilFuntions'
import { api } from 'Core'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { useQuery } from '@tanstack/react-query'
import Loader from 'components/Loader'
import { MuiOtpInput } from 'mui-one-time-password-input'
import useAuth0 from 'hooks/useAuth0'
import { getCurrentLanguage } from 'i18n'
import { analyticEventTracker } from 'utils/analytics'
import { LoadingButton } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close'
import DOMPurify from 'dompurify'
import theme from 'theme'
import useFingerprint from 'utils/useFingerprint'

const MfaPage = () => {
  const { t, i18n } = useTranslation()
  const { login } = useAuth0()
  const { token: uriToken } = useParams()
  const { state } = useLocation()
  const [otp, setOtp] = useState('')
  const [method, setMethod] = useState<null | string>(state?.mfa_method || 'sms')
  const [defaultMethod, setDefault] = useState(true)
  const [remember, setRemember] = useState(true)
  const [otpSent, setOtpSent] = useState(state?.otpSend)
  const [loading, setLoading] = useState(false)
  const [invalidOtp, setInvalidOtp] = useState(false)
  const [token, setToken] = useState(uriToken)
  const [modalOpen, setModalOpen] = useState(false)
  const [counter, setCounter] = useState(otpSent ? 30 : 0)
  const [showResendTooltip, setShowResendTooltip] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const fingerprint = useFingerprint()

  const { data: tokenData, isLoading, isError } = useQuery(['otp-token-check', token], () => api.portalAuth.checkOtpToken(token as string))

  async function sendOtp (type = 'sms') {
    setLoading(true)
    if (counter !== 0) { return }
    const data = await api.portalAuth.send2FaOtp({
      type: method || type,
      email: state.email,
      password: state.password
    })
    if (data?.success) {
      setToken(data?.token)
      setOtpSent(true)
      setCounter(30)
    } else {
      throw Error('Something went wrong!')
    }
    setLoading(false)
  }

  const verifyOtp = async () => {
    try {
      const response = await api.portalAuth.checkOtp(token as string, { code: otp, verifyEmail: !state?.emailVerified })
      if (response?.success) {
        const subdomain = getSubdomain()
        // log user in
        await login(state.email, state.password, '', getCurrentLanguage(i18n), {
          token: token || '',
          otp
        })
        if (!state?.mfa_method && state.emailVerified) {
          await api.portalAuth.activateMfa({
            action: 'update',
            code: otp,
            ...(defaultMethod && { method })
          }, token as string)
        }
        if (remember) {
          await api.portalAuth.setRememberDevice({ fingerprint: fingerprint || '' })
          // cookieUtils.setCookie(`remember_device_${state.email}`, resp.data, moment().add(30, 'days').utc());
        }
        // Register the successful login in analytics
        analyticEventTracker(`Login to ${subdomain}`, { category: 'Authentication' })
      } else {
        if (response?.error === 'Not valid otp') {
          setInvalidOtp(true)
        }
      }
      setLoading(false)
    } catch (e) {
      dispatch(showSnackBar({
        show: true,
        // @ts-ignore
        message: error?.error,
        severity: 'error'
      }))
      setLoading(false)
    }
  }

  useEffect(() => {
    const counterInterval = setInterval(() => {
      setCounter((counter) => {
        if (counter === 0) {
          clearInterval(counterInterval)
          return counter
        }
        return counter - 1
      })
    }, 1000)

    return () => {
      clearInterval(counterInterval)
    }
  }, [token])

  // useEffect(() => {
  //   if(state && !state.emailVerified)
  //     setSearchParams ({ redirectTo: `/mfa-config/setup` })
  // }, [state])

  useEffect(() => {
    if (otp.length === 6) handleChange()
  }, [otp])

  const handleChange = async () => {
    if (otp.length === 6 && state) {
      setLoading(true)
      try {
        await verifyOtp()
      } catch (e: any) {
        dispatch(showSnackBar({
          show: true,
          message: e?.error,
          severity: 'error'
        }))
        setLoading(false)
      }
    } else {
      setInvalidOtp(true)
    }
  }

  const toggleTooltip = () => {
    setShowResendTooltip(prev => counter !== 0 ? !prev : false)
  }

  if (isLoading) {
    return <Loader />
  }

  if (isError || !state || !state.email || !state.password || !tokenData.success) {
    navigate('/')
    dispatch(
      showSnackBar({
        show: true,
        severity: 'error',
        message: t('login.heading.emailVerifyError1')
      })
    )
    return (
      <Loader />
    )
  }

  return (
    <Container>
      <Stack
        sx={ { gap: 3, width: '100%', maxWidth: 550, m: 'auto' } }
        component='form'
        autoComplete='off'
      >
        <Typography variant='h4' component='h1' sx={ { textAlign: 'center' } }>{ t(state?.emailVerified ? 'authenticationinfo.setupTitle' : 'authenticationinfo.setupTitleEmail') }</Typography>
        {
          otpSent
            ? (<Paper sx={ { width: '100%', maxWidth: 550, m: 'auto' } }>
              <Stack sx={ { gap: 3 } }>
                <Stack sx={ { gap: 3 } }>
                  <Typography variant='h5' component='h2'>{ t('login.mfaconfirm.header1update') }</Typography>
                  <Typography variant='body1' mt={ 1 }>
                    <Trans
                      // @ts-ignore
                      i18nKey={ state?.emailVerified ? 'login.heading.emailBody' : 'login.heading.emailBody1' }
                      components={
                        {
                          email: <Typography sx={ { display: 'inline' } } fontWeight='bold' variant='body1'>{ state.email }</Typography>,
                          updateLink: <Link
                            // component={RouterLink}
                            sx={ {
                              fontSize: 14,
                              fontWeight: 500,
                              marginLeft: 2,
                              cursor: 'pointer'
                            } }
                            onClick={ (e) => {
                              e.preventDefault()
                              navigate(-1)
                            } }
                          // to='/'
                          >
                            { t('account.button.update') }
                          </Link>
                        }
                      }
                    >
                      We ve sent a 6-digit code to test@email.com. Please enter the code as soon as possible.
                    </Trans>
                  </Typography>
                  {/* @ts-ignore */ }
                  <MuiOtpInput aria-disabled={ loading } value={ otp } length={ 6 } onChange={ (e) => setOtp(e.toUpperCase()) } />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='default'
                        id='default'
                        color='primary'
                        checked={ remember }
                        onChange={ () => setRemember(prev => !prev) }
                      />
                    }
                    label={ t('login.heading.checkbox2') }
                  />
                  { loading
                    ? <Typography variant='body1' fontSize={ '12px' }>{ t('login.heading.emailVerifyInfoText1') }</Typography>
                    : null }
                  { invalidOtp && !loading
                    ? <Alert severity='error'>{ t('login.heading.emailVerifyError2') }</Alert>
                    : null }
                  {
                    !loading
                      ? (<Typography variant='body1' fontSize={ '12px' } color={ '#00000099' }>
                        <Trans
                          // @ts-ignore
                          i18nKey={
                            !state?.emailVerified
                              ? 'login.mfaconfirm.emailVerifyInfoEmailOnly'
                              : method === 'call'
                                ? ' '
                                : method === 'email'
                                  ? 'login.mfaconfirm.emailVerifyInfoEmail'
                                  : 'login.mfaconfirm.emailVerifyInfoText' } components={ {
                                    textaction: <Link
                                      color={ counter && method === 'sms' ? '#00000061' : '#00000099' }
                                      sx={ { cursor: 'pointer', textDecorationColor: counter && method === 'sms' ? '#00000030' : '#00000060' } }
                                      onClick={ async () => {
                                        if (counter !== 0 && method === 'sms') { return }
                                        const data = await api.portalAuth.send2FaOtp({
                                          type: 'sms',
                                          password: state?.password,
                                          email: state?.email
                                        })
                                        if (data?.success) {
                                          setToken(data?.token)
                                          setMethod('sms')
                                          setCounter(30)
                                        } else {
                                          throw Error('Something went wrong!')
                                        }
                                      }
                                      }
                                    />,
                                    callaction: <Link
                                      color={ counter && method === 'call' ? '#00000061' : '#00000099' }
                                      sx={ { cursor: 'pointer', textDecorationColor: counter && method === 'call' ? '#00000030' : '#00000060' } }
                                      onClick={ async () => {
                                        if (counter !== 0 && method === 'call') { return }
                                        const data = await api.portalAuth.send2FaOtp({
                                          type: 'call',
                                          password: state?.password,
                                          email: state?.email
                                        })
                                        if (data?.success) {
                                          setToken(data?.token)
                                          setMethod('call')
                                          setCounter(30)
                                        } else {
                                          throw Error('Something went wrong!')
                                        }
                                      }
                                      }
                                    />,
                                    emailaction: <Link
                                      color={ counter && (method === 'email' || !state?.emailVerified) ? '#00000061' : '#00000099' }
                                      sx={ { cursor: 'pointer', textDecorationColor: counter && (method === 'email' || !state?.emailVerified) ? '#00000030' : '#00000060' } }
                                      onClick={ async () => {
                                        if (counter !== 0 && (method === 'email' || !state?.emailVerified)) { return }
                                        const data = await api.portalAuth.send2FaOtp({
                                          type: 'email',
                                          password: state?.password,
                                          email: state?.email
                                        })
                                        if (data?.success) {
                                          setToken(data?.token)
                                          setMethod('email')
                                          setCounter(30)
                                        } else {
                                          throw Error('Something went wrong!')
                                        }
                                      }
                                      }
                                    />,
                                    // @ts-ignore
                                    tooltip: <Tooltip
                                      { ...(isMobile ? { open: showResendTooltip, onClick: toggleTooltip } : {}) }
                                      title={ counter ? t('login.mfaconfirm.resendCounterTooltip', { counter }) : '' } />
                                  } } />
                      </Typography>)
                      : null
                  }
                  <LoadingButton variant='contained'
                    loading={ loading }
                    fullWidth
                    onClick={ () => handleChange() }
                  >{ t('login.heading.button2') }</LoadingButton>
                </Stack>
                <Link
                  sx={ { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' } }
                  onClick={ () => {
                    setModalOpen(true)
                  } }
                ><Typography variant='body1' fontSize={ '12px' }>{ t('login.heading.link1') }</Typography></Link>
              </Stack>
            </Paper>)
            : (<Paper sx={ { width: '100%', maxWidth: 550, m: 'auto' } }>
              <Stack sx={ { gap: 3 } }>
                <Stack sx={ { gap: 3 } }>
                  <Typography variant='h5' component='h2'>{ t('login.heading.emailVerify') }</Typography>
                  <Typography variant='h5' component='h2'>{ t('login.heading.emailBody') }</Typography>
                  <RadioGroup
                    name='method'
                    onChange={ (evt, value) => {
                      setMethod(value)
                    } }
                    sx={ { gap: 2 } }
                    value={ method }
                  >
                    <FormControlLabel
                      control={ <Radio /> }
                      label={ t('login.heading.radio1', { phone: `xxxxxx${state?.phone?.substring(6)}` }) }
                      value='sms'
                    />
                    <FormControlLabel
                      control={ <Radio /> }
                      label={ t('login.heading.radio2', { email: `${state?.email?.substring(0, 5)}xxxxxx` }) }
                      value='email'
                    />
                  </RadioGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='default'
                        id='default'
                        color='primary'
                        checked={ defaultMethod }
                        onChange={ () => setDefault(prev => !prev) }
                      />
                    }
                    label={ t('login.heading.checkbox1') }
                  />
                  <LoadingButton variant='contained'
                    loading={ loading }
                    fullWidth
                    onClick={ async () => {
                      await sendOtp()
                    } }
                  >{ t('login.heading.button1') }</LoadingButton>
                </Stack>
              </Stack>
            </Paper>)
        }
      </Stack>
      <AlertModalComponent open={ modalOpen } setOpen={ setModalOpen } />
    </Container>
  )
}

const AlertModalComponent = ({
  open,
  setOpen
}: any) => {
  const { t } = useTranslation()
  return (
    <Modal
      open={ open }
      onClose={ () => setOpen(false) }
      aria-labelledby="modal-modal-subject"
      aria-describedby="modal-modal-description"
      sx={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }
    >
      <Box
        sx={ {
          background: '#FFF',
          display: 'flex',
          flexFlow: 'column',
          gap: '1rem',
          maxHeight: '90%',
          overflowY: 'auto',
          maxWidth: '40rem',
          padding: '2rem',
          position: 'relative',
          width: '100%'
        } }
      >
        <Stack
          sx={ { gap: '1rem', width: '100%' } }
        >
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant="h4">{ t('mfa.alertbox.title') }</Typography>
            <IconButton onClick={ () => setOpen(false) } >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography variant="body1" dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(t('mfa.alertbox.body')) } }></Typography>
          <Link href="mailto:cs@simplehealthkit.com">
            <Button variant="contained" fullWidth>
              { t('mfa.alertbox.button') }
            </Button>
          </Link>
        </Stack>
      </Box>
    </Modal>
  )
}

export default MfaPage
