import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { checkScreeningQuestions } from 'utils/kit'
import { formatDate } from 'utils/utilFuntions'
import { InsuranceDetails } from 'components/PatientComponents/PaymentInfo/InsuranceDetails'
import { insuranceValidationSchema } from 'utils/validationSchemas'
import { LoadingButton } from '@mui/lab'
import { Address, PatientI } from '../../types/db/PatientI'
import { Prompt } from 'components/Prompt'
import { RegisterKitNavigation, useRegisterKit } from '.'
import { showSnackBar } from 'state/actions'
import { TextButton } from 'components'
import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import * as yup from 'yup'
import CloseIcon from '@mui/icons-material/Close'
import InsuranceForm from './InsuranceForm'
import React, { useEffect, useRef, useState } from 'react'
import useFormik from 'hooks/useFormik'
import useStateCallback from 'hooks/useStateCallback'
import { useTranslation } from 'react-i18next'
import { analyticEventTracker } from 'utils/analytics'

const insuranceTypes = [
  'commercial',
  'medicare',
  'medicaid',
  'insured',
  'insurance'
]

function checkIsPatientInsured (patient: PatientI) {
  if (!patient) return false
  return insuranceTypes.includes(patient?.insurance?.insurance_type)
}

const validationSchema = yup.object().shape({
  insurance: insuranceValidationSchema
})

const InsuranceDetailsForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const { data, updateData, saveKitAction } = useRegisterKit()
  const [patient, setPatient] = useState(data?.patientInfoForm?.patient)
  const [isPatientInsured, setIsPatientInsured] = useState(patient ? checkIsPatientInsured(patient) : false)
  const [insurance, setInsurance] = useState(patient?.insurance)
  const [showPromptOnExit, setShowPromptOnExit] = useStateCallback(true)

  const insuranceAddress: Address = insurance?.insured_address || {} as Address

  useEffect(() => {
    if (data?.patientInfoForm) {
      setPatient(data.patientInfoForm.patient)
    }
  }, [data])

  useEffect(() => {
    if (patient) {
      const check = checkIsPatientInsured(patient)
      setIsPatientInsured(check)
      setInsurance(patient?.insurance)
      formik.setFieldValue('insurance', {
        insuranceName: patient?.insurance?.insurance_name || null,
        policyNumber: patient?.insurance?.policy_number || '',
        groupNumber: patient?.insurance?.group_number || '',
        relationship: patient?.insurance?.relationship || null,
        insuredFirstName: patient?.insurance?.insured_first_name || '',
        insuredLastName: patient?.insurance?.insured_last_name || '',
        insuredDob: formatDate(patient?.insurance?.insured_dob) || '',
        insuredGender: patient?.insurance?.insured_gender || null,
        warningCheckbox: false,
        primaryInsuredId: '',
        insuredAddress: {
          addressLine1: patient?.insurance?.insured_address?.delivery_line_1 || '',
          city: patient?.insurance?.insured_address?.city_name || '',
          state: patient?.insurance?.insured_address?.state_abbreviation || '',
          zipCode: patient?.insurance?.insured_address?.zipcode || '',
          plus4Code: patient?.insurance?.insured_address?.plus4_code || '',
          county: patient?.insurance?.insured_address?.county || '',
          country: 'US',
          fips: ''
        }
      })
    }
  }, [patient])

  const formik: FormikType = useFormik({
    initialValues: {
      ...data.insuranceDetailsForm,
      hasInsurance: 'no',
      insurance: {
        insuranceName: insurance?.insurance_name || null,
        policyNumber: insurance?.policy_number || '',
        groupNumber: insurance?.group_number || '',
        relationship: insurance?.relationship || null,
        insuredFirstName: insurance?.insured_first_name || '',
        insuredLastName: insurance?.insured_last_name || '',
        insuredDob: formatDate(insurance?.insured_dob || '') || '',
        insuredGender: insurance?.insured_gender || null,
        warningCheckbox: false,
        primaryInsuredId: '',
        insuredAddress: {
          addressLine1: insuranceAddress?.delivery_line_1 || '',
          city: insuranceAddress?.city_name || '',
          state: insuranceAddress?.state_abbreviation || '',
          zipCode: insuranceAddress?.zipcode || '',
          plus4Code: insuranceAddress?.plus4_code || '',
          county: insuranceAddress?.county || '',
          country: 'US',
          fips: ''
        }
      }
    },
    validationSchema,
    onSubmit: async formData => {
      updateData(currentData => ({
        ...currentData,
        insuranceDetailsForm: {
          ...currentData.insuranceDetailsForm,
          ...formData,
          continuedWithoutInsurance: false
        }
      }))
      analyticEventTracker(`(E) Next Button Clicked on Insurance Details Form`, {
        category: 'Register kit'
      })
      saveKitAction('Insurance')
      setShowPromptOnExit(false, () => navigate('../sample-collection'))
    }
  })

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showPromptOnExit)

  const [editDrawer, setEditDrawer] = useState(false)
  const [showWhy, setShowWhy] = useState(false)

  // TODO -> configure this IPRIDE2022 on db level?
  const disabledDueToInsurance = !data.insurance.isEligible

  const whyInsuranceNotEligible = (
    <Dialog open={ showWhy } onClose={ () => setShowWhy(false) }>
      <DialogContent>
        <Stack sx={ { gap: 1, alignItems: 'center' } }>
          { Object.values(data.insurance.why).map((why, idx) => (
            <Stack key={ idx } sx={ { flexDirection: 'row' } }>
              <Typography>{ `${why}` }</Typography>
            </Stack>
          )) }
          <Button
            variant='contained'
            onClick={ () => {
              analyticEventTracker(`(E) Insurance not eligible drawer closed`, {
                category: 'Register kit'
              })
              setShowWhy(false)
            } }
          >
            { t('common.gotIt') }
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )

  const navigation = (
    <RegisterKitNavigation
      back={
        <Button
          fullWidth={ mobile }
          onClick={ () => {
            analyticEventTracker(`(E) Back Button Clicked on Insurance Details Form`, {
              category: 'Register kit'
            })
            setShowPromptOnExit(false, () =>
              checkScreeningQuestions(data?.kit?.kitTypeConfig, data?.site)
                ? navigate('../screening')
                : navigate('../patient-info')
            )
          }
          }
        >
          { t('multiFormNavigation.back') }
        </Button>
      }
      next={
        <LoadingButton
          fullWidth={ mobile }
          disabled={
            disabledDueToInsurance ||
            (!isPatientInsured && Boolean(formik.values.hasInsurance)) // TODO -> add condition for cash in another issue
          }
          loading={ formik.isSubmitting }
          type='submit'
          variant='contained'
        >
          { t('multiFormNavigation.next') }
        </LoadingButton>
      }
    />
  )

  if (disabledDueToInsurance) {
    return (
      <Stack sx={ { gap: 2 } }>
        <Stack component='form' sx={ { gap: 1 } } autoComplete='off'>
          <Typography variant='body1'>
            { t('registerKit.insuranceDetailsForm.testNotEligibleForInsurance') }
          </Typography>
          <Typography>
            { t('registerKit.insuranceDetailsForm.contactAdminForCashPayment') }
          </Typography>
        </Stack>
        { navigation }
        { whyInsuranceNotEligible }
      </Stack>
    )
  }

  const isTnCCheckboxError = formik.isError('insurance.warningCheckbox')

  return (
    <Stack component='form' sx={ { gap: 3 } } onSubmit={ formik.handleSubmit } autoComplete='off'>
      <Prompt
        open={ showPrompt && showPromptOnExit }
        onClose={ cancelNavigation }
        confirmNavigation={ confirmNavigation }
      />
      <Typography variant='h5' component='h2'>
        { t('registerKit.insuranceDetailsForm.title') }
      </Typography>

      { data.insurance.isEligible
        ? (
          <Typography>
            { isPatientInsured
              ? t('registerKit.insuranceDetailsForm.insuredSubtitle')
              : t('registerKit.insuranceDetailsForm.uninsuredSubtitle') }
          </Typography>
        )
        : null }

      { data.insurance.isEligible
        ? (
          <Stack>
            { isPatientInsured
              ? (
                <Stack gap={ 2 }>
                  <InsuranceDetails patient={ patient } />
                  { isPatientInsured
                    ? (
                      <TextButton onClick={ () => {
                        setEditDrawer(true)
                        analyticEventTracker(`(E) Insurance edit drawer opened`, {
                          category: 'Register kit'
                        })
                      } }>
                        { t('registerKit.insuranceDetailsForm.editInsuranceDetailsBtn') }
                      </TextButton>
                    )
                    : null }

                  <FormControl error={ formik.isError('insurance.warningCheckbox') }>
                    <FormControlLabel
                      sx={ {
                        fontSize: 14,
                        textAlign: 'left',
                        alignItems: 'center'
                      } }
                      control={
                        <Checkbox
                          checked={ formik.values.insurance.warningCheckbox }
                          name='insurance.warningCheckbox'
                          onChange={ formik.handleChange }
                          sx={ {
                            color: formik.isError('insurance.warningCheckbox')
                              ? '#EB0017'
                              : '#212121'
                          } }
                        />
                      }
                      label={ t('registerKit.insuranceDetailsForm.confirmCheckbox') }
                    />
                    { formik.isError('insurance.warningCheckbox')
                      ? (
                        <FormHelperText>{ t(formik.getError('insurance.warningCheckbox') as string) }</FormHelperText>
                      )
                      : null }
                  </FormControl>
                </Stack>
              )
              : <InsuranceOrCash formik={ formik } /> }
          </Stack>
        )
        : (
          <Stack sx={ { gap: 2 } }>
            <Stack component='form' sx={ { gap: 1 } } autoComplete='off'>
              <Typography variant='body1'>
                { t('registerKit.insuranceForm.testNotEligibleForInsurance') }
              </Typography>
              <Typography>
                { t('registerKit.insuranceForm.contactAdminForCashPayment') }
              </Typography>
            </Stack>
            { whyInsuranceNotEligible }
          </Stack>
        ) }
      <InsuranceFormSwipe
        open={ editDrawer }
        onClose={ () => setEditDrawer(false) }
      />
      { !isTnCCheckboxError && formik.isError('insurance') ? <FormHelperText error>{ t('registerKit.insuranceDetailsForm.confirmCheckboxError') }</FormHelperText> : null }
      { navigation }
      { whyInsuranceNotEligible }
    </Stack>
  )
}

export default InsuranceDetailsForm

function InsuranceFormSwipe ({ open, onClose }: { open: boolean, onClose: () => void }) {
  const { t } = useTranslation()
  const { data, updateData } = useRegisterKit()

  const dispatch = useDispatch()

  const isPatientInsured = data.patientInfoForm.patient ? checkIsPatientInsured(data.patientInfoForm.patient) : false

  return (
    <SwipeableDrawer
      anchor='bottom'
      open={ open }
      onClose={ onClose }
      onOpen={ () => {} }
      sx={ {
        '& .MuiPaper-root': {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          height: '100%',
          maxHeight: { xs: '100%', md: '90%' },
          width: 'min(100%, 700px)',
          m: 'auto'
        }
      } }
    >
      <Stack sx={ { position: 'relative' } }>
        <Puller onClose={ onClose } />
        <Stack sx={ { p: { xs: 1, sm: 2, md: 4 }, gap: 3 } }>
          {
            open && (
              <>
                <Typography variant='h4' component='h1'>
                  { isPatientInsured ? t('registerKit.insuranceForm.updateInsuranceHeading') : t('registerKit.insuranceForm.addInsuranceHeading') }
                </Typography>
                {
                  data?.patientInfoForm?.patient &&
                    data?.site
                    ? <InsuranceForm
                      patient={ data.patientInfoForm.patient }
                      siteId={ data.site.id }
                      siteCode={ data.site.code }
                      submitHandler={ ({ formik }) => (
                        <LoadingButton
                          loading={ formik.isSubmitting }
                          type='submit'
                          variant='contained'
                          sx={ { width: { xs: '100%', sm: 'auto' }, ml: 'auto' } }
                        >
                          { t('multiFormNavigation.submit') }
                        </LoadingButton>
                      ) }
                      onSubmit={ response => {
                        updateData(currentData => ({
                          ...currentData,
                          patientInfoForm: { patient: response.patient }
                        }))
                        dispatch(
                          showSnackBar({
                            show: true,
                            message: t('registerKit.insuranceForm.updatedSnackbar'),
                            severity: 'success'
                          })
                        )
                        onClose()
                      } }
                    />
                    : null
                }
              </>
            )
          }
        </Stack>
      </Stack>
    </SwipeableDrawer>
  )
}

function Puller ({ onClose }: { onClose: () => void }) {
  return (
    <Stack
      direction='row'
      sx={ {
        alignItems: 'center',
        padding: 2,
        borderTopLeftRadius: 8,
        borderTopRightRaduis: 8,
        justifyContent: 'center'
      } }
    >
      <Box
        sx={ {
          borderRadius: 2,
          height: 6,
          width: 30,
          backgroundColor: 'gray'
        } }
      />
      <IconButton
        onClick={ () => {
          analyticEventTracker(`(E) Close Button Clicked on Drawer on Insurance Info Form`, {
            category: 'Register kit'
          })
          if (onClose)
            onClose()
        }
        }
        sx={ { position: 'absolute', right: '10px' } }
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}

function InsuranceOrCash ({ formik }: { formik: FormikType }) {
  const { t } = useTranslation()
  const { data, updateData } = useRegisterKit()
  const dispatch = useDispatch()
  const btnRef = useRef<HTMLButtonElement>(null)

  return (
    <Stack gap={ 2 }>
      <Stack>
        <RadioGroup
          aria-labelledby='insurance-radio'
          name='hasInsurance'
          value={ formik.values.hasInsurance }
          onChange={ (_evt, option) => {
            formik.setFieldValue('hasInsurance', option)
          } }
        >
          <FormControl sx={ { gap: 1 } } error={ Boolean(formik.errors.hasInsurance) }>
            <Typography component='label' htmlFor='site-code'>
              { t('registerKit.insuranceForm.doYouHaveInsurance') }
            </Typography>
            <FormControlLabel control={ <Radio /> } label={ t('common.yes') } value='yes' />
            <FormControlLabel control={ <Radio ref={ btnRef } /> } label={ t('common.no') } value='no' />
          </FormControl>
        </RadioGroup>
      </Stack>
      <Stack my={ 2 }>
        {
          formik.values.hasInsurance === 'yes' &&
          data?.site &&
          data?.patientInfoForm?.patient &&
          (
            <Stack>
              <InsuranceForm
                siteId={ data?.site?.id }
                patient={ data?.patientInfoForm?.patient }
                siteCode={ data?.site?.code }
                submitHandler={ ({ formik }) => (
                  <Stack
                    direction={ { xs: 'column', sm: 'row' } }
                    gap={ 1 }
                  >
                    <LoadingButton
                      loading={ formik.isSubmitting }
                      onClick={ () => formik.handleSubmit() }
                      variant='contained'
                      sx={ { flex: 1, width: '100%' } }
                    >
                      { t('common.save') }
                    </LoadingButton>
                    <Button
                      sx={ { flex: 1, width: '100%' } }
                      variant='outlined'
                      onClick={ () => btnRef?.current?.click() }
                    >
                      { t('registerKit.insuranceDetailsForm.discardChanges') }
                    </Button>
                  </Stack>
                ) }
                onSubmit={ response => {
                  updateData((currentData) => ({
                    ...currentData,
                    patientInfoForm: { patient: response.patient }
                  }))
                  formik.setFieldValue('hasInsurance', null)
                  dispatch(
                    showSnackBar({
                      show: true,
                      message: t('registerKit.insuranceForm.addedSnackbar'),
                      severity: 'success'
                    })
                  )
                } }
              />
            </Stack>
          )
        }
        {
          // TODO is site cash based on another issue
          formik.values.hasInsurance === 'no' && (
            <Typography>
              { t('registerKit.insuranceDetailsForm.contactAdminIfNotInsured') }
            </Typography>
          )
        }
      </Stack>
    </Stack>
  )
}

// Interfaces
export interface InsuranceDetailsFormI {
  hasInsurance: 'yes' | 'no' | null
  insurance: {
    insuranceProvider: string
    insuranceNumber: string
    insuranceExpiry: string
  }
}

type FormikType = ReturnType<typeof useFormik>
