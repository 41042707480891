export const queryKeys = {
  sites: {
    all: 'ALL_SITES',
    accessSites: 'ACCESS_SITES',
    site: 'SITE',
    insurance: 'INSURANCE'
  },
  analytics: {
    programInsights: 'PROGRAM_INSIGHTS',
    populationHealth: 'POPULATION_HEALTH',
    operations: 'OPERATIONS',
    programInsightsDownload: 'PROGRAM_INSIGHTS_DOWNLOAD',
    populationHealthDownload: 'POPULATION_HEALTH_DOWNLOAD',
    healthPlanDownloadRawData: 'HEALTH_PLAN_DOWNLOAD_RAW_DATA'
  },
  results: {
    instructionsToUpload: 'INSTRUCTIONS_TO_UPLOAD'
  },
  ftp: {
    content: 'FTP_CONTENT'
  },
  healthPlan: {
    resultsDeliveryReport: 'RESULTS_DELIVERY_REPORT'
  },
  i18nextContent: {
    all: 'I18NEXT_CONTENT_GET_ALL',
    one: 'I18NEXT_CONTENT_GET_ONE',
    multiple: 'I18NEXT_CONTENT_GET_MULTIPLE'
  },
  analyteConfigs: {
    one: 'ANALYTE_CONFIG_GET_ONE',
    all: 'ANALYTE_CONFIG_GET_ALL'
  },
  sftpSettings: {
    all: 'MAILER_SETTINGS_GET_ALL',
    one: 'MAILER_SETTINGS_GET_ONE',
    create: 'MAILER_SETTINGS_CREATE',
    update: 'MAILER_SETTINGS_UPDATE',
    delete: 'MAILER_SETTINGS_DELETE'
  },
  ssoSettings: {
    all: 'SSO_SETTINGS_GET_ALL',
    one: 'SSO_SETTINGS_GET_ONE',
    create: 'SSO_SETTINGS_CREATE',
    update: 'SSO_SETTINGS_UPDATE',
    delete: 'SSO_SETTINGS_DELETE'
  },
  customAnalytics: {
    all: 'CUSTOM_ANALYTICS_GET_ALL',
    one: 'CUSTOM_ANALYTICS_GET_ONE',
    create: 'CUSTOM_ANALYTICS_CREATE',
    update: 'CUSTOM_ANALYTICS_UPDATE',
    delete: 'CUSTOM_ANALYTICS_DELETE'
  },
  skus: {
    all: 'SKUS_GET_ALL',
    one: 'SKUS_GET_ONE'
  },
  mailAndFax: {
    mailsOnHold: 'MAILS_ON_HOLD',
    faxesOnHold: 'FAXES_ON_HOLD'
  },
  apiAuditLogs: {
    all: 'API_AUDIT_LOGS_GET_ALL'
  },
  patient: {
    all: 'PATIENT_GET_ALL',
    one: 'PATIENT_GET_ONE'
  },
  portalUsers: {
    usersSubscriptionStatus: 'USERS_SUBSCRIPTION_STATUS',
    verifyNotificationsSubscription: 'VERIFY_NOTIFICATIONS_SUBSCRIPTION',
    getPortalUser: 'GET_PORTAL_USERS'
  },
  kit: {
    getKitDataToMail: 'GET_KIT_DATA_TO_MAIL',
    getKitDataToFax: 'GET_KIT_DATA_TO_FAX'
  }
}
