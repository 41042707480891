import * as yup from 'yup'
import moment from 'moment'
import { CustomField, SiteI } from '../../types/db/SiteI'
import { Kit } from '../../types/db/Kit'

export const getSchemaValidation = () => {
  return {
    0: {
      validationSchema: yup.object().shape({
        isPartOfOrganization: yup
          .string()
          .required('registerPatient.validation.pleaseSelect')
          .nullable(),
        locationCode: yup.string().when('isPartOfOrganization', {
          is: 'Yes',
          then: yup.string().required('registerPatient.validation.enterSiteCode')
        })
      })
    },
    1: {
      validationSchema: yup.object({
        firstName: yup
          .string()
          .trim('registerPatient.validation.removeWhitespaces ')
          .strict(true)
          .min(2, 'registerPatient.validation.firstNameLength')
          .required('registerPatient.validation.firstName'),
        lastName: yup
          .string()
          .trim('registerPatient.validation.removeWhitespaces ')
          .strict(true)
          .min(2, 'registerPatient.validation.lastNameLength')
          .required('registerPatient.validation.lastName'),
        medicalAddress: yup.object({
          addressLine1: yup
            .string()
            .trim('registerPatient.validation.removeWhitespaces ')
            .strict(true)
            .required('registerPatient.validation.addressLine1IsRequired'),
          city: yup
            .string()
            .trim('registerPatient.validation.removeWhitespaces ')
            .strict(true)
            .required('registerPatient.validation.city'),
          state: yup
            .string()
            .trim('registerPatient.validation.removeWhitespaces ')
            .strict(true)
            .required('registerPatient.validation.state'),
          zipCode: yup
            .string()
            .trim('registerPatient.validation.removeWhitespaces ')
            .strict(true)
            .required('registerPatient.validation.zipCode'),
          country: yup
            .string()
            .trim('registerPatient.validation.removeWhitespaces ')
            .strict(true)
            .required('registerPatient.validation.country')
        }),
        gender: yup.string().required('registerPatient.validation.gender'),
        sexAssignedAtBirth: yup.string().required('registerPatient.validation.sexAssignedAtBirthIsRequired'),
        dob: yup
          .string()
          .min(10, 'registerPatient.validation.invalidDate')
          .required('registerPatient.validation.dob')
          .test(
            'DOB',
            'registerPatient.validation.dateOfBirthValidation',
            (value: string | undefined) => {
              if (!value) return false

              const momentFormat = `${value.substring(6)}-${value.substring(
                0,
                2
              )}-${value.substring(3, 5)}`
              const isDateValid = moment(momentFormat).isValid()
              if (isDateValid) {
                const maxAge = moment().diff(moment('1900-01-01'), 'years')
                return (
                  moment().diff(moment(momentFormat), 'years') >= 0 &&
                  moment().diff(moment(momentFormat), 'years') < maxAge
                )
              } else {
                return false
              }
            }
          ),
        ethnicity: yup.string().required('registerPatient.validation.ethnicity').nullable(),
        // email: yup.string().email('Enter a valid email').required('Email is required'),
        // confirmedMail: yup.string().email('Enter a valid email').oneOf([yup.ref('email'), null], 'emails must match').required('Confirm Email is required'),
        phone: yup
          .string()
          .length(14, 'registerPatient.validation.phoneFieldMinimumLength')
          .required('registerPatient.validation.phone'),
        race: yup.string().required('registerPatient.validation.race')
      })
    },
    2: {
      insured: {
        validationSchema: yup.object({
          insured: yup.object({
            insuranceDisclaimer: yup
              .boolean()
              .oneOf([true], 'registerPatient.validation.acceptConfirmation'),
            primaryInsuranceName: yup
              .string()
              .trim('registerPatient.validation.removeWhitespaces ')
              .strict(true)
              .required('registerPatient.validation.insuranceName'),
            policyNumber: yup
              .string()
              .trim('registerPatient.validation.removeWhitespaces ')
              .strict(true)
              .min(2, 'registerPatient.validation.policyLength')
              .required('registerPatient.validation.policy'),
            patientRelationshipToInsured: yup
              .string()
              .required('registerPatient.validation.patientRelation'),
            insuredFirstName: yup
              .string()
              .trim('registerPatient.validation.removeWhitespaces ')
              .strict(true)
              .required('registerPatient.validation.insuredFirstName'),
            insuredLastName: yup
              .string()
              .trim('registerPatient.validation.removeWhitespaces ')
              .strict(true)
              .required('registerPatient.validation.insuredLastName'),
            //   insuredDOB: yup
            //     .date()
            //     // .transform((value, rawValue) => {
            //     //   const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate()
            //     //   return correctDate
            //     // })
            //     // .min(moment(new Date(1900, 1, 1)).toDate(), 'The Date Of Birth must be valid and in the format DD/MM/YYYY, should not be in the future and should not be before 1900')
            //     .min(new Date(1, 1, 1900), 'The Date Of Birth must be valid and in the format DD/MM/YYYY, should not be in the future and should not be before 1900')
            //     .max(new Date(), 'The Date Of Birth must be valid and in the format DD/MM/YYYY, should not be in the future and should not be before 1900')
            //     .required('Date of birth is required')
            //     // .strg(10, 'Date is invalid')
            //     .typeError('Please enter the correct date format (mm-dd-yyyy)'),
            insuredDOB: yup
              .string()
              .min(10, 'registerPatient.validation.invalidDate')
              .test(
                'DOB',
                'registerPatient.validation.dateOfBirthValidation',
                (value: string | undefined) => {
                  if (!value) return false
                  const momentFormat = `${value.substring(6)}-${value.substring(
                    0,
                    2
                  )}-${value.substring(3, 5)}`
                  const isDateValid = moment(momentFormat).isValid()
                  if (isDateValid) {
                    const maxAge = moment().diff(moment('1900-01-01'), 'years')
                    return (
                      moment().diff(moment(momentFormat), 'years') >= 0 &&
                      moment().diff(moment(momentFormat), 'years') < maxAge
                    )
                  } else {
                    return false
                  }
                }
              ),
            insuredGender: yup.string().required('registerPatient.validation.gender')
          }),
          insuredAddress: yup.object({
            addressLine1: yup
              .string()
              .trim('registerPatient.validation.removeWhitespaces ')
              .strict(true)
              .required('registerPatient.validation.addressLine1IsRequired'),
            city: yup
              .string()
              .trim('registerPatient.validation.removeWhitespaces ')
              .strict(true)
              .required('registerPatient.validation.city'),
            state: yup.string().required('registerPatient.validation.state'),
            zipCode: yup
              .string()
              .trim('registerPatient.validation.removeWhitespaces ')
              .strict(true)
              .required('registerPatient.validation.zipCode'),
            country: yup.string().required('registerPatient.validation.country')
          }),

          isInsured: yup
            .string()
            .nullable()
            .required('registerPatient.validation.required')
        })
      },
      uninsured: {
        ssn: {
          validationSchema: yup.object({
            uninsured: yup.object({
              socialSecurityNo: yup
                .string()
                .min(11, 'registerPatient.validation.socialSecurityMinLength')
                .required('registerPatient.validation.ssn')
            })
          })
        },
        dl: {
          validationSchema: yup.object({
            uninsured: yup.object({
              driverLicense: yup.string().required('registerPatient.validation.driverLicense ')
            })
          })
        }
      }
    }
  }
}

 
export const getRegisterPatientSchema = ({
  activeStep,
  isAdmin,
  paymentType,
  validation2,
  customField
}: Props) => {
  let validationSchema = null
  const schemas = getSchemaValidation()

  if (isAdmin) {
    switch (activeStep) {
      case 1:
        validationSchema = schemas[1].validationSchema
        return validationSchema
      case 2:
        if (paymentType === 'insured') { validationSchema = schemas[2].insured.validationSchema }

        if (paymentType === 'uninsured') {
          if (validation2 === 'ssn') { validationSchema = schemas[2].uninsured.ssn.validationSchema }
          if (validation2 === 'dl') { validationSchema = schemas[2].uninsured.dl.validationSchema }
        }
        return validationSchema
      default:
        return undefined
    }
  } else {
    switch (activeStep) {
      case 0:
        validationSchema = schemas[0].validationSchema
        return validationSchema
      case 1:
        validationSchema = schemas[1].validationSchema

        if (customField?.enabled) {
          const customValidation = yup.object({
            [customField?.value]: yup.string().when('customField', {
              is: () => customField?.required,
              then: yup.string().required('registerPatient.validation.requiredField'),
              otherwise: yup.string()
            })
          })

          validationSchema = validationSchema.concat(customValidation)
        }

        return validationSchema
      case 2:
        if (paymentType === 'insured') {
          validationSchema = schemas[2].insured.validationSchema
        }

        if (paymentType === 'uninsured') {
          if (validation2 === 'ssn') {
            validationSchema = schemas[2].uninsured.ssn.validationSchema
          }
          if (validation2 === 'dl') {
            validationSchema = schemas[2].uninsured.dl.validationSchema
          }
        }
        return validationSchema
      default:
        return undefined
    }
  }
}

interface Props {
  activeStep: number
  isAdmin: boolean
  paymentType: string
  validation2: 'dl' | 'ssn' | 'insured' | null
  customField: CustomField | undefined
}

export const getMinAge = (kit: Partial<Kit> | undefined, site: Partial<SiteI> | undefined) => {
  const prefix = kit?.kitTypeConfig?.prefix
  const ageConfig = prefix ? site?.age_config?.[prefix] : undefined

  return {
    patientMinAge: ageConfig?.patientMinAge || 18,
    supervisionMinAge: ageConfig?.supervisionMinAge || 0
  }
}

export const getCanUseWithSupervision = (kit: Partial<Kit> | undefined, site: Partial<SiteI> | undefined) => {
  const prefix = kit?.kitTypeConfig?.prefix
  const ageConfig = prefix ? site?.age_config?.[prefix] : undefined

  return ageConfig?.canUseWithSupervision === 'yes' || false
}
