import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Button,
  Box,
  Alert,
  Stack,
  useMediaQuery,
  Typography
} from '@mui/material'
import { AccountSettings } from './components/AccountSettings'
import { api } from 'Core'
import { checkPortalApp } from 'utils/affiliateUtilFunctions'
import { Container } from 'components'
import { DrawerMenu } from './components/DrawerMenu'
import { getSubdomain } from 'utils/utilFuntions'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { NavLinks } from './components/NavLinks'
import { subdomainType } from 'utils/routerUtils'
import { toggleMockUser } from 'state/actions'
import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { visibilityStates } from './utils'
import cookieUtils from 'Core/utils/cookieUtils'
import HelpBeaconMobile from './components/HelpBeaconMobile'
import jwtUtil from 'Core/utils/jwtUtil'
import LogoutDialog from 'components/Header/components/LogoutDialog'
import MobileMenuPatient from './components/MobileMenuPatient'
import OtherAppsButton from './components/OtherAppsButton'
import PatientInbox from './PatientInbox'
import SkipToMainContent from 'components/SkipToMain'
import theme from 'theme'
import { LanguageRounded } from '@mui/icons-material'
import { LanguageSelectionModal } from 'pages/Profile/components/LanguageSelection'
import { useTranslation } from 'react-i18next'
import { LANGUAGES, getCurrentLanguage, isI18nEnabled } from 'i18n'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import useAuth0 from 'hooks/useAuth0'
import NotificationModal from './PatientInbox/NotificationModal'

function Header () {
  const whiteLabelConfig = useWhiteLabelConfig()
  const { user } = useAuth0()
  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] =
    useState(false)
  const [hasConsults, setHasConsults] = useState(false)
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const subdomain = getSubdomain()
  const shouldUseWhiteLabel = [subdomainType.app].includes(subdomain as typeof subdomainType.app)
  const [mockUser, setMockUser] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (cookieUtils.getCookie('override_email').length > 0) {
      setMockUser(cookieUtils.getCookie('override_email'))
    }
  }, [])

  const hasConsultsRequest = async () => {
    if (user && checkPortalApp()) {
      const response = await api.consult
        .getConsults('1', '1')
        .then(res => res)
        .catch(error => {
          if (error.status !== 401 || error.message !== 'Unauthorized') { console.log(error.message) }
        })
      setHasConsults((response?.consults?.length || 0) > 0)
    }
  }

  const handleMockExit = () => {
    cookieUtils.deleteCookie('override_email')
    window.sessionStorage.removeItem('consults')
    dispatch(toggleMockUser())
    document.location.href = '/'
  }

  const [userPermissions, setUserPermissions] = useState<UserPermissionsI>({
    page: [],
    role: [],
    features: []
  })

  useEffect(() => {
    hasConsultsRequest()
    let pageVisibility
    switch (subdomain) {
      case subdomainType.admin:
        pageVisibility = visibilityStates.page.adminPage
        break
      case subdomainType.manage:
        pageVisibility = visibilityStates.page.managePage
        break
      case subdomainType.provider:
        pageVisibility = visibilityStates.page.providerPage
        break
      case subdomainType.account:
        pageVisibility = visibilityStates.page.accountPage
        break
      case 'ninjalab':
        pageVisibility = visibilityStates.page.labportalPage
        break
      case subdomainType.health:
        pageVisibility = visibilityStates.page.healthPortal
        break
      case subdomainType.customeradmin:
        pageVisibility = visibilityStates.page.customerAdminPage
        break
      default:
        pageVisibility = visibilityStates.page.portalPage
        break
    }

    setUserPermissions({
      page: [pageVisibility],
      role: [...jwtUtil.getRoles()],
      features: [hasConsults ? visibilityStates.features.consultSection : '']
    })
  }, [user, hasConsults])

  const [searchParams] = useSearchParams()
  const location = useLocation()
  const healthPlanSiteId = searchParams.get('siteId')
  const notificationId = searchParams.get('notificationId')
  const isHealthPlanVerifyRoute =
    location.pathname.includes('member') &&
    (location.pathname.includes('verify') ||
      location.pathname.includes('signup'))

  const healthPlanBasicInfoQuery = useQuery(
    ['health-plan-basic-info', healthPlanSiteId],
    () => api.healthPlan.getBasicInfo(healthPlanSiteId as string),
    { enabled: Boolean(healthPlanSiteId) }
  )

  const getLogo = () => {
    if (isHealthPlanVerifyRoute && healthPlanBasicInfoQuery.data) {
      return healthPlanBasicInfoQuery.data.logoUrl
    }
    if (shouldUseWhiteLabel && whiteLabelConfig?.logoUrl) {
      return whiteLabelConfig?.logoUrl
    }

    return '/assets/reskinning/shk-logo.svg'
  }

  const i18nEnabled = isI18nEnabled(whiteLabelConfig)

  return (
    <>

      { notificationId && <NotificationModal /> }

      <AppBar
        position='static'
        style={ {
          backgroundColor: shouldUseWhiteLabel ? whiteLabelConfig?.theme?.customComponents?.header?.bgColor : '#fff',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
        } }
      >
        <SkipToMainContent />
        { mockUser && (
          <Alert
            variant='filled'
            action={
              <Button color='inherit' size='small' onClick={ handleMockExit }>
                EXIT
              </Button>
            }
            severity='error'
          >
            All API calls are being overridden. User: { mockUser }
          </Alert>
        ) }
        <Container
          sx={ {
            p: { xs: '0', md: '0 32px' },
            height: '90px',
            maxHeight: '90px'
          } }
        >
          <HelpBeaconMobile
            displayed={ Boolean(user) && subdomain === subdomainType.app && mobile }
          />
          <Stack
            sx={ {
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '90px',
              maxHeight: '90px',
              p: { xs: '0 8px', lg: 0 }
            } }
          >
            {/* This centralize the logo on mobile view */ }
            { (user && subdomain !== subdomainType.app) ||
              (i18nEnabled && !user)
              ? (
                <Box
                  sx={ {
                    width: i18nEnabled && !user ? 24 : 40,
                    display: {
                      md: i18nEnabled && !user ? 'block' : 'none',
                      xs: i18nEnabled ? 'none' : 'block'
                    }
                  } }
                />
                )
              : null }

            <Stack
              sx={ {
                flexDirection: 'row',
                gap: 1,
                position: 'relative',
                m:
                  !user || (subdomain === subdomainType.app && mobile)
                    ? 'auto'
                    : 0
              } }
            >
              <Link to='/'>
                <Box
                  component='img'
                  src={ getLogo() }
                  alt='Home'
                  height={ shouldUseWhiteLabel ? `${whiteLabelConfig?.logoDimensions?.height || 60}px` : '50px' }
                  maxHeight='60px'
                  width='auto'
                  maxWidth='clamp(180px, 35vw, 300px)'
                  mr={ 1 }
                />
              </Link>

              <NavLinks userPermissions={ userPermissions } isMobile={ mobile } />
              { user &&
                subdomain === 'account' &&
                jwtUtil.getRoles().length !== 0 && <OtherAppsButton /> }
            </Stack>

            {
              // Mobile menu for the entire app except the patient portal
              mobile && user && subdomain !== subdomainType.app
                ? (
                  <DrawerMenu userPermissions={ userPermissions } />
                  )
                : null
            }

            { !mobile && user
              ? (
                <Stack
                  direction='row'
                  alignItems='center'
                  gap={ 2 }
                  sx={ { display: { xs: 'none', md: 'flex' } } }
                >
                  { user && checkPortalApp() && subdomain !== subdomainType.app
                    ? (
                      <Typography variant='h6'>FOR DEMO PURPOSES ONLY</Typography>
                      )
                    : null }
                  {/* { user && getUserDomains().length
                    ? (
                      <Tooltip title={t('homepage.switchPortalToolTip')} placement='bottom' arrow>
                        <Typography sx={{ fontSize: '15px', fontWeight: '500', color: '#333', cursor: 'pointer' }} onClick={()=> navigate('/choose-portal')}>{t('homepage.switchPortal')}</Typography>
                      </Tooltip>
                      )
                    : null } */}
                  { user && checkPortalApp() ? <PatientInbox /> : null }
                  <AccountSettings userPermissions={ userPermissions } />
                </Stack>
                )
              : null }
            { i18nEnabled && !user ? <LanguageSelect /> : null }
          </Stack>
          {/* Mobile menu for the patient portal */ }
          { mobile && user && subdomain === subdomainType.app
            ? (
              <MobileMenuPatient
                setIsLogoutConfirmationOpen={ setIsLogoutConfirmationOpen }
                hasConsults={ hasConsults }
              />
              )
            : null }
        </Container>

        { isLogoutConfirmationOpen
          ? (
            <LogoutDialog
              id='user-logout'
              keepMounted
              isLogoutConfirmationOpen={ isLogoutConfirmationOpen }
              setIsLogoutConfirmationOpen={ setIsLogoutConfirmationOpen }
            />
            )
          : null }
      </AppBar>
    </>
  )
}

export interface UserPermissionsI {
  page: string[]
  role: string[]
  features: string[],
  isHidden?: boolean
}

export default React.memo(Header)

export function LanguageSelect () {
  const whiteLabelConfig = useWhiteLabelConfig()
  const [open, setOpen] = useState(false)
  const { i18n } = useTranslation()
  return (
    <>
      <Button
        sx={ {
          textDecoration: 'underline',
          color: whiteLabelConfig?.theme?.customComponents?.header?.textColor || '#222',
          p: 0
        } }
        startIcon={ <LanguageRounded /> }
        onClick={ () => setOpen(true) }
      >
        { LANGUAGES[getCurrentLanguage(i18n)]?.slice(0, 2) ||
          LANGUAGES[getCurrentLanguage(i18n)] }
      </Button>
      { open
        ? (
          <LanguageSelectionModal
            open
            onClose={ () => setOpen(false) }
            isNotAuthenticated
          />
          )
        : null }
    </>
  )
}
