import React, { useEffect, useState } from 'react'
import { Alert, Link, Stack, Typography, Button, Tooltip, useMediaQuery } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Container, Paper } from 'components'
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { formatPhoneNumber, isShkDomain } from 'utils/utilFuntions'
import { api } from 'Core'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { useQuery } from '@tanstack/react-query'
import Loader from 'components/Loader'
import { MuiOtpInput } from 'mui-one-time-password-input'
import useAuth0 from 'hooks/useAuth0'
import useMockedUser from 'hooks/useMockedUser'
import { LoadingButton } from '@mui/lab'
import theme from 'theme'
import { isAppPortal } from 'utils/auth0Service'

const MfaConfirm = () => {
  const { t } = useTranslation()
  const { getAccessTokenSilently } = useAuth0()
  const { token: uriToken } = useParams()
  const { state } = useLocation()
  const [otp, setOtp] = useState('')
  const [loading, setLoading] = useState(false)
  const [invalidOtp, setInvalidOtp] = useState(false)
  const [removeAlert, setRemoveAlert] = useState(false)
  const [token, setToken] = useState(uriToken)
  const [counter, setCounter] = useState(30)
  const [method, setMethod] = useState('sms')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user, email } = useMockedUser()
  const [showResendTooltip, setShowResendTooltip] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const mfaConfig = user?.user_metadata?.mfaConfig
  const isPatientPortal = isAppPortal()
  const isShk = isShkDomain()

  const { data: tokenData, isLoading, isError } = useQuery(['otp-token-check', token], () => api.portalAuth.checkOtpToken(token as string))

  const activateMfaFunction = async () => {
    const activateMfa = await api.portalAuth.activateMfa({
      action: state?.action,
      code: otp
    }, token as string)

    if (activateMfa?.success) {
      navigate(isShk && isPatientPortal ? '/choose-portal' : '/')
      getAccessTokenSilently(true)
      dispatch(
        showSnackBar({
          severity: 'success',
          message: t('authenticationinfo.mfa.updateSuccess'),
          show: true
        })
      )
    }
  }

  const verifyOtp = async () => {
    try {
      const response = await api.portalAuth.checkOtp(token as string, { code: otp })
      if (response?.success) {
        if (state?.action === 'remove') {
          setRemoveAlert(true)
        } else if (state?.action === 'update') {
          return navigate('../setup', { state: { action: 'update' } })
        } else {
          await activateMfaFunction()
        }
      } else {
        if (response?.error === 'Not valid otp') {
          setInvalidOtp(true)
        }
      }
      setLoading(false)
    } catch (e) {
      dispatch(showSnackBar({
        show: true,
        // @ts-ignore
        message: error?.error,
        severity: 'error'
      }))
      setLoading(false)
    }
  }

  const handleChange = async () => {
    if (otp.length === 6 && state) {
      setLoading(true)
      try {
        await verifyOtp()
      } catch (e: any) {
        dispatch(showSnackBar({
          show: true,
          message: e?.error,
          severity: 'error'
        }))
        setLoading(false)
      }
    } else {
      setInvalidOtp(true)
    }
  }

  useEffect(() => {
    const counterInterval = setInterval(() => {
      setCounter((counter) => {
        if (counter === 0) {
          clearInterval(counterInterval)
          return counter
        }
        return counter - 1
      })
    }, 1000)

    return () => {
      clearInterval(counterInterval)
    }
  }, [token])

  const toggleTooltip = () => {
    setShowResendTooltip(prev => counter !== 0 ? !prev : false)
  }

  if (isLoading) {
    return <Loader />
  }

  if (isError || !tokenData.success || !state || !state.phone || !state.action) {
    navigate('/account')
    dispatch(
      showSnackBar({
        show: true,
        severity: 'error',
        message: t('login.heading.emailVerifyError1')
      })
    )
    return (
      <Loader />
    )
  }

  const handleUpdateNumber = async () => {
    navigate('../setup', { state: { phone: mfaConfig?.phone, action: 'update' } })
  }

  return (
    <Container>
      <Stack
        sx={ { gap: 3, width: '100%', maxWidth: 550, m: 'auto' } }
        component='form'
        autoComplete='off'
      >
        <Typography variant='h4' component='h1' sx={ { textAlign: 'center' } }>
          { removeAlert ? t('authenticationinfo.mfa.titleAlert') : t(`authenticationinfo.mfa.title${state.action}`) }
        </Typography>
        { removeAlert
          ? (<Paper sx={ { width: '100%', maxWidth: 550, m: 'auto' } }>
            <Stack sx={ { gap: 3 } }>
              <Stack sx={ { gap: 3 } }>
                <Typography variant='h5' component='h2'>{ t(`login.mfaconfirm.header1${state.action}`) }</Typography>
                <Typography variant='body1' mt={ 1 }>{ t('login.mfaconfirm.body1removeAlert') }</Typography>

                <Button variant='outlined' component={ RouterLink } to={ '/account' } fullWidth>{ t('authenticationinfo.remove.button1') }</Button>
                <LoadingButton color='error' loading={ loading } variant='contained' onClick={ () => {
                  setLoading(true)
                  activateMfaFunction()
                } } fullWidth>{ t('authenticationinfo.remove.button2') }</LoadingButton>
              </Stack>
            </Stack>
          </Paper>)
          : (<Paper sx={ { width: '100%', maxWidth: 550, m: 'auto' } }>
            <Stack sx={ { gap: 3 } }>
              <Stack sx={ { gap: 3 } }>
                <Typography variant='h5' component='h2'>{ t(`login.mfaconfirm.header1${state.action}`) }</Typography>
                { state.action === 'add'
                  ? (<Typography variant='body1' mt={ 1 }>
                    <Trans
                      // @ts-ignore
                      i18nKey='login.mfaconfirm.body1'
                      components={
                        {
                          phone: <Stack flexDirection={ 'row' }>
                            <Typography fontWeight='bold' variant='body1'>{ formatPhoneNumber(state?.phone) }</Typography>
                            <Link
                              component={ Button }
                              sx={ {
                                fontSize: 14,
                                fontWeight: 500,
                                marginLeft: 2
                              } }
                              onClick={ handleUpdateNumber }
                            >
                              { t('account.button.update') }
                            </Link>
                          </Stack>
                        }
                      }
                    >
                      We ve sent a 6-digit code to test@email.com. Please enter the code as soon as possible.
                    </Trans>
                  </Typography>)
                  : <Typography variant='body1' mt={ 1 }>{ t('login.mfaconfirm.body1remove') }</Typography> }

                <Typography variant='body1' mt={ 1 }>{ t('login.mfaconfirm.bodyhint1') }</Typography>
                {/* @ts-ignore */}
                <MuiOtpInput aria-disabled={ loading } value={ otp } length={ 6 } onChange={ (e: string) => setOtp(e.toUpperCase()) } />
                { loading
                  ? <Typography variant='body1' fontSize={ '12px' }>{ t('login.heading.emailVerifyInfoText1') }</Typography>
                  : null }
                { invalidOtp && !loading
                  ? <Alert severity='error'>{ t('login.heading.emailVerifyError2') }</Alert>
                  : null }
                { !loading
                  ? (<Typography variant='body1' fontSize={ '12px' }>
                    <Trans
                      values={ { time: counter !== 0 ? `(in ${counter} seconds)` : '' } }
                      // @ts-ignore
                      i18nKey={ method === 'call' ? 'login.mfaconfirm.emailVerifyInfoResendCall' : 'login.mfaconfirm.emailVerifyInfoResendText' } components={ {
                        textaction: <Link
                          color={ counter && method === 'sms' ? '#00000061' : '#00000099' }
                          sx={ { cursor: 'pointer', textDecorationColor: counter && method === 'sms' ? '#00000030' : '#00000060' } }
                          onClick={ async () => {
                            if (counter !== 0) { return }
                            const data = await api.portalAuth.send2FaOtp({
                              type: 'sms',
                              phone: state?.phone,
                              email
                            })
                            if (data?.success) {
                              setToken(data?.token)
                              setMethod('sms')
                              setCounter(30)
                            } else {
                              throw Error('Something went wrong!')
                            }
                          } }
                        />,
                        callaction: <Link
                          color={ counter && method === 'call' ? '#00000061' : '#00000099' }
                          sx={ { cursor: 'pointer', textDecorationColor: counter && method === 'call' ? '#00000030' : '#00000060' } }
                          onClick={ async () => {
                            const data = await api.portalAuth.send2FaOtp({
                              type: 'call',
                              phone: state?.phone,
                              email
                            })
                            if (data?.success) {
                              setToken(data?.token)
                              setMethod('call')
                              setCounter(30)
                            } else {
                              throw Error('Something went wrong!')
                            }
                          } }
                        />,
                        // @ts-ignore
                        tooltip: <Tooltip
                          { ...(isMobile ? { open: showResendTooltip, onClick: toggleTooltip } : {}) }
                          title={ counter ? t('login.mfaconfirm.resendCounterTooltip', { counter }) : '' } />

                      } } />
                  </Typography>)
                  : null }
                <LoadingButton variant='contained'
                  loading={ loading }
                  fullWidth
                  onClick={ () => handleChange() }
                >{ t('login.heading.button2') }</LoadingButton>
              </Stack>
            </Stack>
          </Paper>
            ) }

      </Stack>
    </Container>
  )
}

export default MfaConfirm
