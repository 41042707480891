import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { Container, Paper } from 'components'
import FeedbackForm from 'components/FeedbackForm'
import React, { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import _ from 'lodash'
import { LoadingButton } from '@mui/lab'
import { useMutation, useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import Loader from 'components/Loader'
import { useFormik } from 'formik'
import * as yup from 'yup'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { useWhiteLabelConfig } from '../../utils/white-label/WhiteLabelConfig'
import { FeedbackFormI } from '../../types/FeedbackFormI'

const ConsultFeedback = () => {
  const [searchParams] = useSearchParams()
  const { token } = useParams()
  const consultQueryFromToken = useQuery([`consult-${token}`], () => api.consult.getConsultFromFeedbackToken(token as string), {
    refetchOnWindowFocus: false
  })
  if (consultQueryFromToken.isLoading) return <Loader />

  if (!consultQueryFromToken.data) return <FeedbackInvalid />

  if (searchParams.get('answer') === 'y') {
    return <PrescriptionPickedUp />
  }
  if (searchParams.get('answer') === 'ny') {
    return <PrescriptionPickedUp />
  }
  if (searchParams.get('answer') === 'n') {
    return <PrescriptionNotPickedUp />
  }

  return <FeedbackInvalid />
}
export default ConsultFeedback

function PrescriptionPickedUp () {
  const [feedbacks, setFeedbacks] = useState<FeedbackFormI>({} as FeedbackFormI)
  const { token } = useParams()
  const [searchParams] = useSearchParams()
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const [errors, setErrors] = useState('')
  const dispatch = useDispatch()
  const { mutate, isLoading } = useMutation(
    (token: string) => api.feedback.createAfterConsultFeedbacks(token, { ...feedbacks, meta: searchParams.get('answer') }),
    {
      onSuccess: () => {
        setFeedbackSubmitted(true)
      },
      onError: () => {
        dispatch(showSnackBar({
          show: true,
          message: 'Something went wrong',
          severity: 'error'
        }))
      }
    }
  )

  const handleFeedbackChange = (data: FeedbackFormI) => {
    const newFeedback = _.cloneDeep(feedbacks)

    if (data.feature in newFeedback) {
      newFeedback[data.feature] = { ...newFeedback[data.feature], ...data }
    } else {
      newFeedback[data.feature] = data
    }

    setFeedbacks(newFeedback)
  }

  if (feedbackSubmitted) return <FeedbackSubmitted />

  const submit = (data: FeedbackFormI) => {
    const isValid = Object.values(data).filter(x => x.rating).length === 3

    if (isValid) {
      setErrors('')
      // @ts-ignore
      mutate(token)
    } else {
      setErrors('Please Rate all the fields')
    }
  }

  return (
    <Container>
      <Stack gap={5} sx={{ alignItems: 'center' }}>
        <Stack>
          <Paper>
            <Typography variant='body1' sx={{ fontWeight: '700' }}>Kudos for staying on top of your health!</Typography>
            <Box
              component='img'
              alt='Thumbs Up'
              src='/assets/thumbsup.svg'
              sx={{
                width: { xs: '100%', sm: 264 },
                objectFit: 'cover'
              }}
            />
          </Paper>
        </Stack>
        <Stack>
          <Paper>
            <Typography variant='h5' sx={{ fontWeight: '700', textAlign: 'center' }}>How was your experience?</Typography>
            <Stack>
              <FeedbackForm
                title='1. How was your overall telehealth experience?'
                lowestLabel='Very Unsatisfied'
                highestLabel='Very Satisfied'
                hideDescription
                onChange={handleFeedbackChange}
                feature='overall-teleheath-experience'
                backgroundColor='#FFF'
              />
            </Stack>

            <FeedbackForm
              title='2. How was your experience with the provider?'
              lowestLabel='Very Unsatisfied'
              highestLabel='Very Satisfied'
              hideDescription
              feature='experience-with-provider'
              onChange={handleFeedbackChange}
              backgroundColor='#FFF'
            />

            <FeedbackForm
              title='3. How likely will you recommend Simple HealthKit to your friends or family?'
              lowestLabel='Not Likely'
              highestLabel='Very Likely'
              hideDescription
              feature='recommend-shk-to-others'
              onChange={handleFeedbackChange}
              backgroundColor='#FFF'
              totalPoints={10}
            />

            <FeedbackForm
              title='4. Anything else you’d like us to know? '
              onChange={handleFeedbackChange}
              totalPoints={10}
              hidePoints
              feature='recommend-shk-to-others'
              backgroundColor='#FFF'
            />

            {errors && (
              <Typography color='error.main' textAlign='center' my={3}>{errors}</Typography>
            )}
            <Stack alignItems='center'>
              <LoadingButton
                loading={isLoading}
                onClick={() => {
                  submit(feedbacks)
                }}
                variant='contained'
              >
                Submit Feedback
              </LoadingButton>

            </Stack>

          </Paper>
        </Stack>

      </Stack>
    </Container>
  )
}

const validationSchema = yup.object().shape({
  feedback: yup.string().required('Please select a feedback option')
})

function PrescriptionNotPickedUp () {
  const { token } = useParams()
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      feedback: '',
      other: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const data: any = {}
        if (values.feedback === 'other') {
          data['obstacle-faced-in-telehealth-flow'] = {
            description: `other-${values.other}`,
            feature: 'obstacle-faced-in-telehealth-flow'
          }
        } else {
          data['obstacle-faced-in-telehealth-flow'] = {
            description: values.feedback,
            feature: 'obstacle-faced-in-telehealth-flow'
          }
        }
        await api.feedback.createAfterConsultFeedbacks(token as string, { ...data, meta: searchParams.get('answer') })

        setFeedbackSubmitted(true)
      } catch (err) {
        dispatch(showSnackBar({
          show: true,
          message: 'Something went wrong',
          severity: 'error'
        }))
      }
    }
  })

  if (feedbackSubmitted) return <FeedbackSubmitted />

  return (
    <Container>
      <Stack gap={5} sx={{ alignItems: 'center' }}>
        <Typography variant='h4'>We are here to help</Typography>

        <Stack sx={{ gap: 2 }}>
          <Typography>Please share any obstacles that you may be facing?</Typography>
          <RadioGroup
            name='feedback'
            onChange={formik.handleChange}
            sx={{ gap: 2 }}
          >
            <FormControlLabel
              control={<Radio />}
              label='I haven’t got the time to pick up'
              value='I haven’t got the time to pick up'
            />
            <FormControlLabel
              control={<Radio />}
              label='I have gone to seen my provider'
              value='I have gone to seen my provider'
            />

            <FormControlLabel
              control={<Radio />}
              label='The pharmacy is too far'
              value='The pharmacy is too far'
            />

            <FormControlLabel
              control={<Radio />}
              label='Other '
              value='other'
            />
          </RadioGroup>

          {formik.errors.feedback && (
            <Typography color='error.main'>{formik.errors.feedback}</Typography>
          )}

          {
            formik.values.feedback === 'other' && (
              <TextField
                name='other'
                onChange={formik.handleChange}
                label='Please mention your reason here...'
                rows={3}
                multiline
              />
            )
          }

          <Stack alignItems='center'>
            <LoadingButton
              loading={formik.isSubmitting}
              onClick={() => {
                formik.handleSubmit()
              }}
              variant='contained'
            >
              Submit Feedback
            </LoadingButton>

          </Stack>
        </Stack>
      </Stack>
    </Container>
  )
}

function FeedbackSubmitted () {
  const whiteLabelConfig = useWhiteLabelConfig()
  const appUrl = whiteLabelConfig?.variables?.appUrl || process.env.REACT_APP_URL || ''

  return (
    <Container>
      <Paper>
        <Stack gap={5} sx={{ alignItems: 'center' }}>
          <Typography variant='h5'>Thank you for your feedback</Typography>
          <Stack>
            <Box
              src='/assets/clapshand.svg'
              alt='Claps Hand'
              component='img'
              sx={{
                width: { xs: '100%', sm: 264 },
                objectFit: 'cover'
              }}
            />
          </Stack>
          <Typography>Thank you for your feedback! Your insights are valuable and will help us improve our service and achieve health equity for everyone. </Typography>
          <Stack gap={2} sx={{ alignItems: 'center' }}>
            <Button variant='outlined' onClick={() => { window.location.href = appUrl }}>Go to Dashboard</Button>
            <Button variant='outlined' onClick={() => window.history.back()}>Go Back</Button>
          </Stack>
        </Stack>
      </Paper>
    </Container>
  )
}

function FeedbackInvalid () {
  const whiteLabelConfig = useWhiteLabelConfig()
  const appUrl = whiteLabelConfig?.variables?.appUrl || process.env.REACT_APP_URL || ''

  return (
    <Container>
      <Paper>
        <Stack gap={5} sx={{ alignItems: 'center' }}>
          <Typography variant='h5'>This URL has expired</Typography>
          <LinkOffIcon sx={{ fontSize: '54px' }} />
          <Stack gap={2} sx={{ alignItems: 'center' }}>
            <Button variant='outlined' onClick={() => { window.location.href = appUrl }}>Go to Dashboard</Button>

            <Button variant='outlined' onClick={() => window.history.back()}>Go Back</Button>
          </Stack>
        </Stack>
      </Paper>
    </Container>
  )
}
